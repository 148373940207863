import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

const TermService: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          <IonTitle>ข้อตกลงและเงื่อนไขการใช้บริการ</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonCard className="term-container" style={{ marginBottom: 0 }}>
            <IonCardContent>
              <p style={{ fontSize: 18, textAlign: "center" }}>
                เงื่อนไขการใช้บริการ
              </p>
              <p style={{ paddingTop: 10 }}>
                การตกลงสมัครเข้าใช้บริการ ถือว่า
                ผู้ใช้บริการยอมรับว่าได้อ่านและทำความเข้าใจเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัวอย่างละเอียดถี่ถ้วนเป็นอย่างดีทั้งหมดก่อนแล้ว
                โดยผู้ใช้บริการยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัวทุกประการ
                ซึ่งเงื่อนไขการใช้บริการฉบับนี้มีผลผูกพันผู้ใช้บริการตามกฎหมาย
                <br />
                <br />
                1.&nbsp;&nbsp; เงื่อนไขการใช้บริการของ KGO Token Wallet
                ฉบับนี้มีวัตถุประสงค์เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมายอันพึงต้องปฏิบัติตาม
                <br />
                2.&nbsp;&nbsp; คำจำกัดความ <br />
                2.1&nbsp;&nbsp; “ผู้ให้บริการ” หมายถึง InfinitLand โดย Knowledge
                Governance Token ในชื่อของ “KGO Token Wallet”
                หรือชื่ออื่นใดอันหมายถึงบริการนี้
                และให้หมายรวมถึงการงานใช้ส่วนใดส่วนหนึ่งหรือทั้งหมด ทั้ง
                โดยตรงผ่านเว็บไซต์และหรือผ่านตัวแทนในรูปแบบต่างๆ รวมทั้งตัวแทน
                และผู้เกี่ยวข้องที่ได้รับมอบหมายด้วย <br />
                2.2&nbsp;&nbsp; “ผู้ใช้บริการ” หมายถึง
                บุคคลใดก็ตามที่เข้าถึงการใช้งาน เข้าใช้งาน ไม่ว่าจะเป็นผลิตภัณฑ์
                ซอฟต์แวร์ บริการ หรือเว็บไซต์ หรือแอพพลิเคชั่น หรือรูปแบบอื่นใด
                ของผู้ให้บริการก็ตาม <br />
                2.3&nbsp;&nbsp; “เงื่อนไขการใช้บริการ” หมายถึง ข้อตกลง ข้อกำหนด
                ข้อสัญญา หรือชื่ออื่นใดที่มีลักษณะแบบเดียวกัน
                เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมาย <br />
                2.4&nbsp;&nbsp; “การให้บริการ” หมายถึง
                การใช้แต้มสะสมในรูปแบบดิจิทัล ผ่าน KGO Token Wallet
                เพื่อใช้เป็นส่วนลดของสินค้าหรือใช้สิทธิประโยชน์
                ใช้บริการกับทางผู้ให้บริการที่ร่วมรายการกับทาง InfinitLand
                ที่ได้มีการจัดกิจกรรมกับทางผู้ให้บริการในรูปแบบส่งเสริมการขาย
                กิจกรรมทางสังคม สิ่งแวดล้อม การเรียนรู้ ในชีวิตประจำวัน <br />
                3.&nbsp;&nbsp;
                การตัดสินใจในการสมัครใช้บริการเพื่อรับแต้มสะสมดิจิทัล
                และใช้เป็นส่วนลด
                ของการใช้บริการกับร้านค้าหรือหน่วยงานที่ร่วมรายการ
                และร่วมกิจกรรมกับผู้ให้บริการ ถือเป็นการตัดสินใจของใช้บริการเอง
                ผู้ให้บริการไม่ได้ดำเนินการในฐานะเป็นที่ปรึกษาหรือดูแลผลประโยชน์ให้กับผู้ใช้บริการ
                โดยก่อนการสมัครใช้บริการ ผู้ใช้บริการขอรับรองว่า
                ได้ศึกษาขั้นตอนของการสมัครและการใช้งานแต้มสะสมดิจิทัล
                และการใช้งานเป็นส่วนลด เป็นอย่างดีแล้ว
                ซึ่งจะไม่มีความเสี่ยงในการใช้งานที่จะทำให้ผู้ใช้บริการเกิดความเสียหายใดๆ
                และการตัดสินใจใช้แต้มส่วนลดที่ได้รับ
                ถือเป็นการตัดสินใจของผู้ใช้บริการ
                ซึ่งไม่มีประเด็นที่ผู้ให้บริการออกแต้มสะสมดิจิทัลนี้
                นี้ต้องรับผิดขอบต่อการใช้งานที่เกิดขึ้นจากการใช้ดังกล่าว <br />
                4.&nbsp;&nbsp; ผู้ใช้บริการรับทราบเงื่อนไขในการใช้งาน KGO
                ซึ่งจะไม่นำ KGO ไปแลกเป็นสินค้า บริการ หรือเงินสด โดยเด็ดขาด
                หากฝ่าฝืนผู้ให้บริการจะระงับบัญชีที่ฝ่าฝืนทันที <br />
                5.&nbsp;&nbsp; การใช้บริการของผู้ให้บริการนั้น
                ผู้ใช้บริการเข้าใจและยอมรับข้อตกลง เงื่อนไขการใช้บริการ
                โดยผู้ให้บริการจะไม่รับผิดชอบต่อความเสียหายที่เกิดจากการใช้งานทั้งสิ้น{" "}
                <br />
                6.&nbsp;&nbsp; การสมัครเข้าใช้บริการ การยอมรับข้อตกลง
                เงื่อนไขการใช้บริการ และนโยบายความเป็นส่วนตัวต่างๆ
                ถือว่าผู้ใช้บริการยอมรับว่า ได้อ่านและเข้าใจ
                พร้อมทั้งตกลงยินยอมตามข้อตกลง เงื่อนไขการใช้บริการ
                นโยบายความเป็นส่วนตัวและหรือข้อบังคับอื่นใดแล้วแต่กรณีเป็นอย่างดีแล้ว{" "}
                <br />
                7.&nbsp;&nbsp;
                ผู้ให้บริการไม่อนุญาตให้นำเข้าและหรือส่งออกซึ่งข้อมูลใดๆ
                และ/หรือเอกสาร และ/หรือสิ่งแปลกปลอมใดๆ
                เข้ามาในระบบที่อาจจะทำให้เกิดความเสียหายแก่อุปกรณ์ ระบบ
                ฮาร์ดแวร์ หรือซอฟท์แวร์ อันเป็นความผิดตามที่กฎหมายบัญญัติไว้{" "}
                <br />
                8.&nbsp;&nbsp;
                ผู้ใช้บริการต้องไม่พยายามหรือกระทำการใดๆที่เป็นอันตรายต่อความปลอดภัยของระบบคอมพิวเตอร์หรือระบบเน็ตเวิร์ค
                ซึ่งหมายรวมถึงการกระทำความผิดใดๆตามที่กฎหมายกำหนด <br />
                9.&nbsp;&nbsp; ระบบจะไม่มีการเก็บข้อมูลส่วนบุคคล
                รวมถึงรหัสผ่านของผู้ใช้บริการ
                โดยผู้ใช้บริการจะเป็นผู้รับผิดชอบความเสียหายที่เกิดขึ้นจากการใช้งานเองทั้งหมด{" "}
                <br />
                10.&nbsp;&nbsp;
                ผู้ให้บริการมีสิทธิเด็ดขาดแต่เพียงฝ่ายเดียวที่จะสามารถเปลี่ยนแปลง
                ข้อตกลง เงื่อนไขการใช้บริการ นโยบายความเป็นส่วนตัว ข้อกำหนด
                สัญญา และ/หรือชื่ออื่นใดอันมีความหมายเช่นเดียวกัน
                ในการให้บริการได้โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
                และไม่มีความรับผิดใดๆ ต่อคู่สัญญาและ/หรือบุคคล
                และ/หรือนิติบุคคลภายนอกทั้งสิ้น <br />
                11.&nbsp;&nbsp; ผู้ใช้บริการเข้าใจและยอมรับข้อตกลง
                และเงื่อนไขต่างๆของผู้ให้บริการที่ได้มีการเปลี่ยนแปลงแล้ว
                หากผู้ใช้บริการเข้าใช้บริการหลังมีการเปลี่ยนแปลง <br />
              </p>
            </IonCardContent>
          </IonCard>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonButton
                  expand={"block"}
                  className={"kgo-btn"}
                  routerLink="/login"
                >
                  ยอมรับเงื่อนไข
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermService;
