import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonBackButton,
} from "@ionic/react";
import React from "react";
import { arrowBackOutline, logoFacebook, logoTwitter } from "ionicons/icons";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import IconLine from "../assets/line-icon.png";
import { RouteComponentProps } from "react-router";
import { isPlatform } from "@ionic/react";

const FAQ: React.FC<RouteComponentProps> = ({ history }) => {
  function goToFacebook() {
    if (isPlatform("ios")) {
      window.open("fb://profile?id=105196832059252");
    } else if (isPlatform("android")) {
      window.open("fb://page/105196832059252");
    } else {
      window.open("https://www.facebook.com/KGOtoken", "_blank");
    }
  }
  function goToTwitter() {
    window.open("https://twitter.com/MetaverseSiam", "_blank");
  }
  function goToLine() {
    window.open("https://line.me/R/ti/p/@920qmmzo", "_blank");
  }

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>คำถามที่พบบ่อย</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <div
        style={{
          background: "white",
          height: "100%",
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "center",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/icon/KGO-Logo.png"}
          style={{ height: 263, marginTop: 20 }}
        />
        <div
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            <img src={dollar} style={{ width: 24, verticalAlign: "sub" }} /> KGO
            ของร้าน คือ?{" "}
          </div>
          <div>
            KGO ของร้านคือ เหรียญ KGO ที่ <span>ร้านค้า</span>{" "}
            สามารถส่งให้แก่ลูกค้า ตามโปรโมชั่นของ Pay Voucher
            ที่ทางร้านสร้างขึ้นเพื่อให้ลูกค้ารับเหรียญจากทางร้าน
          </div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            <img src={dollar} style={{ width: 24, verticalAlign: "sub" }} /> KGO
            สะสมคือ?
          </div>
          <div>
            KGO สะสมคือ เหรียญ KGO ที่ร้านค้าได้รับมาจากผู้ใช้ และ
            สามารถนำไปใช้แลกของรางวัลและเล่นเกมลุ้นรางวัลพิเศษ
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default FAQ;
