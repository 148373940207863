import axios from "axios";
import setting from "../setting.json";
export let urlHeader =
  process.env.NODE_ENV == "development" ? setting.urlDev : setting.urlHeader;

const axiosInstance = axios.create({
  baseURL: urlHeader,
});

export class ApiService {
  accessToken: string | null = "";

  constructor() {
    this.accessToken = localStorage.getItem("accessToken");
    axiosInstance.interceptors.request.use(function (config) {
      let accessToken = localStorage.getItem("accessToken");
      // @ts-ignore
      config.headers.Authorization = "Bearer " + accessToken;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        console.error("interceptors err", err);
        if (err.constructor.name == "Cancel") {
          throw new Error("Request Cancel");
        }
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          if (
            err.response != null &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            originalReq._retry = true;

            reject("token expire.");
            localStorage.clear();
            window.location.href = "/";

            let res = axios
              .post("/refresh", {
                refreshToken: this.refreshToken,
              })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                this.accessToken = res.token;
                localStorage.setItem("accessToken", res.token);
                return axios(originalReq);
              })
              .catch((e) => {
                console.error(e);
                if (
                  window.location.href.indexOf("er-order") == -1 &&
                  window.location.href.indexOf("pdf-view") == -1
                ) {
                  reject("token expire.");
                  localStorage.clear();
                  window.location.href = "/";
                }
              });
            resolve(res);
          } else if (err.response.status === 403) {
            window.location.href = "/register";
          }
          reject(err.response);
        });
      }
    );
  }

  signOut() {
    this.accessToken = null;
    localStorage.clear();
    window.location.href = "/";
  }

  async getOtp(phoneNumber: string): Promise<any> {
    const url = `biz-auth/otp/${phoneNumber}`;
    let result = null;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res.data) {
          result = res.data;
        }
      })
      .catch((error) => {
        console.error("getOtp", error);
        result = { success: false, message: "error", data: {} };
      });
    return result;
  }

  async checkOtp(data: { phoneNumber: string; otp: string; ref: string }) {
    const url = `biz-auth/otp/`;
    let result = null;
    await axiosInstance
      .post(url, data)
      .then((res) => {
        if (res.data) {
          result = res.data;
        }
      })
      .catch((error) => {
        console.error("checkOtp", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getBalance() {
    const url = `wallet-business/profile`;
    let result = null;
    await axiosInstance
      .get(url)
      .then((res) => {
        console.log("res", res);
        result = res.data;
      })
      .catch((error) => {
        console.error("getBalance", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getTransaction(
    address: string,
    limit: number,
    skip: number,
    subAddress = ""
  ) {
    let result = null;
    await axiosInstance
      .get(
        "wallet/transaction/" +
          address +
          "?limit=" +
          limit +
          "&skip=" +
          skip +
          "&subAddress=" +
          subAddress
      )
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTransaction", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async sendCoin(phoneNumber: string, amount: string, page: string) {
    let result = null;
    let url = "/wallet/transfer-phonenumber";
    if (page === "store") {
      url = "/store/transfer-phonenumber";
    }
    await axiosInstance
      .post(url, {
        toPhoneNumber: phoneNumber,
        amount: amount,
      })
      .then((res) => {
        console.log("res", res);
        result = res.data;
      })
      .catch((error) => {
        console.error("sendCoin", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async saveVoucherList(data: object) {
    let result = null;
    await axiosInstance
      .post("/store/voucher-list", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("saveVoucherList", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async uploadQrCode(data: object) {
    let result = null;
    await axiosInstance
      .post("/upload-qrcode", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("registerStore", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getProfile(filter: string) {
    let result = null;
    await axiosInstance
      .post("/profile", { name: filter })
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProfile", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }

  async getListingProfile() {
    let result = null;
    await axiosInstance
      .get("/wallet-business/get-listing-profile")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getListingProfile", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }

  async updateProfile(data: any) {
    let result = null;
    await axiosInstance
      .patch("/profile", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProfile", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getProvince() {
    let result = null;
    await axiosInstance
      .get("/const-data/provinces")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProvince", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getAmphur(province: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/province/" + province)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getAmphur", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getTambon(amphur: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/amphur/" + amphur)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTambon", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getZipcode(tambon: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/tambon/" + tambon)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTambon", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getBizRegister() {
    const url = `wallet-business/register-data`;
    let result = { success: false, message: "", data: null, isBan: false };
    await axiosInstance
      .get(url)
      .then((res) => {
        result.data = res.data;
        result.success = true;
      })
      .catch((error) => {
        console.error("getBizRegister", error);
        if (error.data.message == "BAN") {
          result.isBan = true;
        }
        result.success = false;
        result.message = error.data.message;
      });
    return result;
  }

  async registerBusinesses(data: object) {
    const url = `wallet-business/register`;
    let result = null;
    await axiosInstance
      .post(url, data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("registerStore", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }
  async uploadListingImage(fileUploadImage: File, index: string) {
    const data = new FormData();
    //set formData
    data.append("fileImage", fileUploadImage);
    data.append("index", index);
    const url = `wallet-business/upload-listing-image`;
    let result = null;
    await axiosInstance
      .post(url, data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("updateListingProfile", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }
  async updateListingProfile(data: object) {
    const url = `wallet-business/update-listing-profile`;
    let result = { success: false, message: "" };
    await axiosInstance
      .post(url, data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("updateListingProfile", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }

  async voucher(data: any, fileImage: any, action = "create") {
    const url = `wallet-business/voucher-template`;
    let result = null;

    const newFormData = new FormData();
    newFormData.append("createVoucherTemplate", JSON.stringify(data));
    newFormData.append("fileImage", fileImage);
    if (action == "edit" || action == "delete") {
      await axiosInstance
        .patch(url, newFormData)
        .then((res) => {
          result = res.data;
        })
        .catch((error) => {
          console.error("registerStore", error.data);
          result = { success: false, message: error.data.message };
        });
    } else {
      await axiosInstance
        .post(url, newFormData)
        .then((res) => {
          result = res.data;
        })
        .catch((error) => {
          console.error("registerStore", error.data);
          result = { success: false, message: error.data.message };
        });
    }

    return result;
  }

  async generateVoucher(data: object) {
    let result = null;
    await axiosInstance
      .post("/wallet-business/generate-voucher", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("generateVoucher", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async useVoucher(data: object) {
    let result = null;
    await axiosInstance
      .post("/wallet-business/use-voucher", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("useVoucher", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async getS3Url(file: string) {
    try {
      const response = await axiosInstance.get("/get-file?file=" + file);
      return response.data;
    } catch (e) {
      console.error(e);
      return "";
    }
  }

  async buyNft(templateId: number, amount: number, claimNow: boolean) {
    let result = { success: false, message: "error" };
    await axiosInstance
      .post("wallet-business/buy-nft", { templateId, amount, claimNow })
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("buyNftx", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async claimNft(nftTemplateID: number, amountOfItem: number) {
    let result = { success: false, message: "error" };
    await axiosInstance
      .post("wallet-business/claim-nft", { nftTemplateID, amountOfItem })
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("claimNft", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async getListNft() {
    let result = null;
    await axiosInstance
      .get("/wallet-business/get-nft")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getListNft", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }

  async getCountReferral() {
    let result = null;
    await axiosInstance
      .get("/wallet-business/get-count-referral")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getListNft", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }
}
