import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonIcon,
  IonCard,
  IonCardContent,
  IonFooter,
  IonText,
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonRouterLink,
  IonImg,
} from "@ionic/react";
import {
  person,
  addCircle,
  search,
  arrowUpCircle,
  pencil,
  arrowDownCircle,
  cart,
  arrowDown,
  arrowUp,
} from "ionicons/icons";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { VoucherModel } from "../models/Data";
// @ts-ignore
import LogoKgo from "../assets/kgo-nft.png";
import IconArrowBackOutline from "../components/icons/BackIcon";
import { fetchUserVoucherByShopId } from "../services/voucher";

const RedeemVoucher: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [voucherList, setVoucherList] = useState<Array<VoucherModel>>([]);
  const [shopId, setShopId] = useState();

  const getStoreProfile = async () => {
    if (appContext.apiService) {
      appContext.getProfile();
      // @ts-ignore
      setShopId(appContext?.profile?.businessId);
    } else {
      console.error("apiService not init!");
    }
  };
  useEffect(() => {
    getStoreProfile();
  }, [appContext.notify]);
  // @ts-ignore
  useEffect(async () => {
    if (shopId) {
      const data = await fetchUserVoucherByShopId(shopId);
      if (data) {
        setVoucherList(data);
      } else {
        console.log("error", data?.error);
      }
    }
  }, [shopId]);

  function numberWithCommas(x: any) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store ion-text-center"}>
          <IonButtons
            onClick={(e: any) => {
              history.push("/Main");
            }}
            slot="start"
            style={{ fontSize: 20, marginLeft: 10 }}
          >
            <IconArrowBackOutline />
          </IonButtons>
          <IonText>Redeem Voucher</IonText>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: 16,
            }}
          >
            <button
              onClick={() => history.push("/redeem-voucher/create")}
              style={{
                color: "white",
                backgroundColor: "orange",
                fontSize: 14,
                padding: 8,
                borderRadius: 8,
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              สร้าง Redeem Voucher
              <IonIcon size={"small"} slot="end" icon={addCircle} />
            </button>
          </div>
          <div style={{ padding: "0 16px" }}>
            <div style={{ paddingTop: 16, paddingBottom: 16 }}>
              <p style={{ fontSize: 18, fontWeight: 600 }}>
                รายการ Redeem Voucher
              </p>
              <div style={{ fontSize: 12, color: "gray" }}>
                Redeem Voucher คือ คูปองสิทธิพิเศษที่ผู้ใช้งานฝั่งลูกค้า
                สามารถเป็นสินค้าหรือบริการโดยไม่ต้องโอนเหรียญ KGO ให้
                ทางร้านโดยตรง
              </div>
            </div>
            <IonCol size="12">
              <IonItem className={"kgo-input-light form"}>
                <IonInput
                  inputmode="text"
                  style={{ fontSize: 16 }}
                  placeholder="ค้นหา"
                  onIonChange={(e) => {
                    if (e.detail.value) {
                      const temp = appContext.vouchers.filter(
                        (r) => r.name.indexOf(e.detail.value!) > -1
                      );
                      console.log("e.detail.value", e.detail.value, temp);
                      setVoucherList(temp);
                    } else {
                      setVoucherList(appContext.vouchers);
                    }
                  }}
                />
                <IonIcon size={"small"} slot="end" icon={search} />
              </IonItem>
            </IonCol>
          </div>
          <div className={"kgo-row voucher-list"}>
            {/* @ts-ignore */}
            {voucherList.filter((e) => e.isDeleted !== false && !e.deletedAt)
              .length == 0 ? (
              <h4 className="ion-text-center">
                <IonLabel>ยังไม่มีรายการ Redeem Voucher...</IonLabel>
              </h4>
            ) : (
              voucherList
                .filter((e) => e.isDeleted !== false)
                .map((r: any, i: number) => (
                  <div
                    key={i}
                    className={"kgo-row-tran"}
                    onClick={(e: any) =>
                      history.push("/redeem-voucher/edit/" + r._id)
                    }
                    style={{
                      width: "100%",
                      display: "flex",
                      border: "1pt solid rgba(0, 0, 0, 0.1)",
                      borderRadius: 12,
                      filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                    }}
                  >
                    <div
                      style={{
                        width: 20,
                        minHeight: "100%",
                        borderRadius: "12px 0 0 12px",
                        display: "inline-block",
                        marginRight: 5,
                      }}
                      className={
                        r.type == "send" ? "send-color" : "receive-color"
                      }
                    />
                    <div style={{ display: "inline-block" }}>
                      <img
                        src={r.image && r.image != "" ? r.image : LogoKgo}
                        style={{
                          height: 80,
                          minWidth: 90,
                          marginTop: 12,
                          marginBottom: 5,
                          objectFit: "cover",
                          borderRadius: 12,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexGrow: 3,
                        marginLeft: 5,
                        marginTop: 5,
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <IonText style={{ fontWeight: 500, fontSize: 20 }}>
                          {r.vName}
                        </IonText>
                        {r.isPublic ? (
                          <IonText style={{ float: "right" }}>
                            <span
                              style={{
                                background: "#FFA412",
                                fontSize: 9,
                                borderRadius: 24,
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginRight: 5,
                              }}
                            >
                              <span style={{ color: "#414143" }}>Public</span>
                            </span>
                          </IonText>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ marginTop: "auto", marginBottom: 8 }}>
                        <IonText
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginRight: 10,
                          }}
                        >
                          <IonText
                            style={{
                              fontSize: 20,
                              fontWeight: 500,
                              color: "#FF8A19",
                            }}
                          >
                            {numberWithCommas(r.price)}{" "}
                            <IonText style={{ fontSize: 12, color: "#242424" }}>
                              KGO
                            </IonText>
                          </IonText>
                        </IonText>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RedeemVoucher;
