import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLoading,
    IonCard,
    IonCardContent,
    IonText,
    useIonAlert,
    IonGrid,
    IonLabel,
    IonInput, IonTextarea, IonSelectOption, IonSelect
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {
    arrowBack, remove
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import TmpImage from "../assets/bi_image-alt.png";
import clipSVG from "../assets/clip.svg";
import Resizer from "react-image-file-resizer";

const ListingProfile: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [showLoading, setShowLoading] = useState(false);
    const getS3Url = useCallback(async (fileKey) => {
        return await appContext.apiService.getS3Url(fileKey);
    }, []);
    // const [fileUploadImage, setFileUploadImage] = useState<Array<File>>([])
    const [imageFile, setImageFile] = useState<Array<string>>(['','','','']);
    const [mainImageFile, setMainImageFile] = useState<string>('');
    const [subTitle, setSubTitle] = useState<string>('');
    const [detail, setDetail] = useState<string>('');
    const [listingProfile, setListingProfile] = useState<any>();
    // const [publicImageUrl, setImagePublicUrl] = useState<Array<string>>([]);
    const [payType, setPayType] = useState<string>('');
    const [payAccount, setPayAccount] = useState<string>('');
    const [payName, setPayName] = useState<string>('');
    const [payBankName, setPayBankName] = useState<string>('');
    const [facebook, setFacebook] = useState<string>('');
    const [lineID, setLineID] = useState<string>('');
    const [googleMap, setGoogleMap] = useState<string>('');
    const [present] = useIonAlert();

    const updateListingProfile = useCallback(async () => {
        // console.log('listingProfile',listingProfile);
        if(payType ==''){
            present({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'กรุณาเลือกประเภทการชำระเงิน',
                buttons: ['Ok',],
            }).catch()
            return false;
        }
        if(payType =='bank'){
            if(payAccount ==''){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'กรุณากรอกหมายเลขบัญชี',
                    buttons: ['Ok',],
                }).catch()
                return false;
            }
            if(payBankName ==''){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'กรุณากรอกหมายธนาคาร',
                    buttons: ['Ok',],
                }).catch()
                return false;
            }
        }
        if(payType =='promptpay'){
            if(payAccount ==''){
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'กรุณากรอกหมายเลขพร้อมเพย์',
                    buttons: ['Ok',],
                }).catch()
                return false;
            }
        }
        if(payName ==''){
            present({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'กรุณากรอกชื่อบัญชี',
                buttons: ['Ok',],
            }).catch()
            return false;
        }
        const res = await appContext.apiService.updateListingProfile({
            images: listingProfile.images,
            subTitle,
            detail,
            payType,
            payAccount,
            payName,
            payBankName,
            facebook,
            lineID,
            googleMap
        })
        if(res.success){
            await present({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'บันทึกข้อมูลเรียร้อย',
                buttons: ['Ok',],
            })
        }
    }, [listingProfile, detail, subTitle, payType, payAccount, payName , payBankName, facebook, lineID, googleMap]);
    const uploadListingImage = useCallback((index) => {}, []);

    useEffect(() => {
        if(listingProfile == null){
            appContext.apiService.getListingProfile().then((response: any) => {
                console.log('getListingProfile', response);
                if (response) {
                    setListingProfile(response.listingProfile);
                    setDetail(response.listingProfile.detail);
                    setSubTitle(response.listingProfile.subTitle);
                    setMainImageFile((response.listingProfile.images[0]) ? response.listingProfile.images[0]+'?v='+ new Date().getTime() : '');
                    setImageFile([
                        (response.listingProfile.images[1]) ? response.listingProfile.images[1]+'?v='+ new Date().getTime() : '',
                        (response.listingProfile.images[2]) ? response.listingProfile.images[2]+'?v='+ new Date().getTime() : '',
                        (response.listingProfile.images[3]) ? response.listingProfile.images[3]+'?v='+ new Date().getTime() : '',
                        (response.listingProfile.images[4]) ? response.listingProfile.images[4]+'?v='+ new Date().getTime() : ''
                        ]);
                    setPayType(response.listingProfile?.payType);
                    setPayName(response.listingProfile?.payName);
                    setPayBankName(response.listingProfile?.payBankName);
                    setPayAccount(response.listingProfile?.payAccount);
                    setFacebook(response.listingProfile?.facebook);
                    setLineID(response.listingProfile?.lineID);
                    setGoogleMap(response.listingProfile?.googleMap);
                }
            }).catch((e) => {
                console.log(e);
            });
        }
    }, [listingProfile]);


    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            return Resizer.imageFileResizer(
                file,
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
    });

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                <IonToolbar className={"kgo-bar-store"}>
                    <IonButtons slot="start">
                        <IonIcon icon={arrowBack} size="large" onClick={e => {
                            history.push('/profile');
                        }}/>
                    </IonButtons>
                    <IonTitle>ข้อมูลแสดงร้านค้า</IonTitle>
                </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent className={'bg-editProfile'}>
                <div className="app-container" >
                    <div style={{paddingLeft: 12, paddingRight:12}}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        <h2 style={{fontSize:24,fontWeight:700}}>
                                            จัดการรูปภาพ
                                        </h2>
                                    </IonText>
                                    <IonText style={{textAlign:'center',fontSize:14}}>
                                        <h4><b>รูปภาพหลัก</b><IonText style={{color:'#FF4646'}}>*</IonText> <IonText style={{color:'#7D7878',fontSize:12,marginLeft:8}}>16:9</IonText></h4>
                                    </IonText>
                                    <div className={'cover-upload-image'} style={{
                                        width:'100%',
                                        backgroundImage:`url(${mainImageFile})`,
                                        backgroundSize:'cover',
                                        objectFit:'cover',
                                        backgroundPosition:'center',
                                    }}>
                                        {mainImageFile == '' ? (
                                                <div>
                                                    <IonRow>
                                                        <IonCol size={"12"}>
                                                            <img src={TmpImage} style={{width: 55, marginTop: 15}}/><br/>
                                                            <IonText style={{fontSize:10,color:'#1A141F'}}>วางรูปภาพไว้ที่นี้</IonText><br/>
                                                            <IonText style={{fontSize:10,color:'#4B3A5A'}}>OR</IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonButton size={"small"} className={"kgo-btn2"} style={{color: "#FFFFFF"}}>
                                                        <img src={clipSVG}/>
                                                        <input id="file" hidden type="file" multiple={false}
                                                               onChange={ async (e) => {
                                                                   if (e.target.files) {
                                                                       console.log('e.target.files', e.target.files);
                                                                       const files = e.target.files;
                                                                       const temp = [];
                                                                       const filePath = [];

                                                                       for (const i in files) {
                                                                           if (!isNaN(parseInt(i))) {
                                                                               temp.push(files[i])
                                                                               filePath.push(URL.createObjectURL(files[i]))
                                                                           }
                                                                       }
                                                                       console.log('temp[0]', temp[0]);
                                                                       let fImage = await resizeFile(temp[0]);
                                                                       appContext.apiService.uploadListingImage( fImage as File, '0')
                                                                           .then((response:any) => {
                                                                               setListingProfile(response.listingProfile)
                                                                               if(response.success){
                                                                                   present({
                                                                                       cssClass: 'ionic-alert',
                                                                                       header: 'แจ้งเตือน',
                                                                                       message: 'อัพโหลดภาพสำเร็จ',
                                                                                       buttons: ['Ok',],
                                                                                   }).then()
                                                                               }
                                                                           });
                                                                       setMainImageFile(filePath[0]);
                                                                       console.log('temp', temp.length)
                                                                   }
                                                               }}/>
                                                        <label htmlFor="file" style={{marginLeft:5}}> อัพโหลดรูปภาพ</label>
                                                    </IonButton>
                                                    {
                                                        mainImageFile != '' ? (
                                                            <IonButton size={"small"} color={'danger'} onClick={
                                                                (e) => {
                                                                    let tempListingProfile = listingProfile;
                                                                    tempListingProfile.images[0] = '';
                                                                    appContext.apiService.updateListingProfile(tempListingProfile)
                                                                        .then((res)=>{
                                                                            if(res.success){
                                                                                present({
                                                                                    cssClass: 'ionic-alert',
                                                                                    header: 'แจ้งเตือน',
                                                                                    message: 'ลบภาพเรียบร้อย',
                                                                                    buttons: ['Ok',],
                                                                                }).then()
                                                                            }
                                                                        })
                                                                        .catch( e => console.error(e))
                                                                    setMainImageFile('');
                                                                }
                                                            }>
                                                                <IonIcon icon={remove} slot="start"/>
                                                                <IonLabel> ลบ</IonLabel>
                                                            </IonButton>
                                                        ) : null
                                                    }
                                                </div>
                                            ) : null
                                        }
                                        {mainImageFile != '' ? (
                                            <div style={{marginTop:170}}>
                                                <IonButton size={"small"} className={"kgo-btn2"} style={{color: "#FFFFFF"}}>
                                                    <img src={clipSVG}/>
                                                    <input id="file" hidden type="file" multiple={false}
                                                           onChange={async (e) => {
                                                               if (e.target.files) {
                                                                   console.log('e.target.files', e.target.files);
                                                                   const files = e.target.files;
                                                                   const temp = [];
                                                                   const filePath = [];

                                                                   for (const i in files) {
                                                                       if (!isNaN(parseInt(i))) {
                                                                           temp.push(files[i])
                                                                           filePath.push(URL.createObjectURL(files[i]))
                                                                       }
                                                                   }
                                                                   let fImage = await resizeFile(temp[0]);
                                                                   // setFileUploadImage(temp);
                                                                   appContext.apiService.uploadListingImage(fImage as File, '0')
                                                                       .then((response:any) => {
                                                                           setListingProfile(response.listingProfile)
                                                                           if(response.success){
                                                                               present({
                                                                                   cssClass: 'ionic-alert',
                                                                                   header: 'แจ้งเตือน',
                                                                                   message: 'อัพโหลดภาพสำเร็จ',
                                                                                   buttons: ['Ok',],
                                                                               }).then()
                                                                           }
                                                                       });
                                                                   setMainImageFile(filePath[0]);
                                                                   console.log('temp', temp.length)
                                                               }
                                                           }}/>
                                                    <label htmlFor="file" style={{marginLeft:5}}> อัพโหลดรูปภาพ</label>
                                                </IonButton>
                                                {
                                                    mainImageFile != '' ? (
                                                        <IonButton size={"small"} color={'danger'} onClick={
                                                            (e) => {
                                                                let tempListingProfile = listingProfile;
                                                                tempListingProfile.images[0] = '';
                                                                appContext.apiService.updateListingProfile(tempListingProfile)
                                                                    .then((res)=>{
                                                                        if(res.success){
                                                                            present({
                                                                                cssClass: 'ionic-alert',
                                                                                header: 'แจ้งเตือน',
                                                                                message: 'ลบภาพเรียบร้อย',
                                                                                buttons: ['Ok',],
                                                                            }).then()
                                                                        }
                                                                    })
                                                                    .catch( e => console.error(e))
                                                                setMainImageFile('');
                                                            }
                                                        }>
                                                            <IonIcon icon={remove} slot="start"/>
                                                            <IonLabel> ลบ</IonLabel>
                                                        </IonButton>
                                                    ) : null
                                                }
                                            </div>
                                        ) : null
                                        }

                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonText style={{textAlign:'center',fontSize:14,display:'grid',marginTop:8}}>
                                        <IonText style={{fontSize:14}}><b>รูปภาพเพิ่มเติม</b> <IonText style={{color:'#7D7878',fontSize:12,marginLeft:8}}>1:1</IonText></IonText>
                                        <IonText style={{fontSize:12,color:'#C2C2C2'}}>( คุณสามารถเลือกระบุรูปภาพเพิ่มเติมทีหลังได้ )</IonText>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                { imageFile.map((path, index) => (
                                        <IonCol key={index} sizeMd={'3'} sizeXs={'6'} size="3">
                                            <div >
                                                <IonText style={{color:'grey', fontSize: 14}}>
                                                    รูปภาพ {index + 1}
                                                </IonText>
                                                <div  className={'cover-upload-image small-image-cover'} style={{
                                                    backgroundImage:`url(${imageFile[index]})`,
                                                }}>
                                                    {
                                                        (
                                                            <div>
                                                                {
                                                                    imageFile[index] == '' ? (
                                                                        <div>
                                                                            <IonRow>
                                                                                <IonCol size={"12"}>
                                                                                    <img src={TmpImage} style={{width: 55, marginTop: 5}}/><br/>
                                                                                </IonCol>
                                                                            </IonRow>
                                                                            <IonButton size={"small"} className={"kgo-btn2"}
                                                                                       style={{color: "#FFFFFF",marginBottom:8}}>
                                                                                <img src={clipSVG}/>
                                                                                <input id={'file-' + index} hidden type="file" multiple={false}
                                                                                       onChange={async (e) => {
                                                                                           if (e.target.files) {
                                                                                               console.log('e.target.files', e.target.files);
                                                                                               const files = e.target.files;
                                                                                               const temp = [];
                                                                                               const filePath = [];
                                                                                               for (const i in files) {
                                                                                                   if (!isNaN(parseInt(i))) {
                                                                                                       temp.push(files[i])
                                                                                                       filePath.push(URL.createObjectURL(files[i]))
                                                                                                   }
                                                                                               }
                                                                                               let fImage = await resizeFile(temp[0]);

                                                                                               let tempImageFile = imageFile;
                                                                                               console.log(tempImageFile);
                                                                                               console.log('fImage', fImage);

                                                                                               tempImageFile[index] = filePath[0];
                                                                                               appContext.apiService.uploadListingImage(fImage as File, (index + 1).toString())
                                                                                                   .then((response:any) => {
                                                                                                       setListingProfile(response.listingProfile)
                                                                                                       if(response.success){
                                                                                                           present({
                                                                                                               cssClass: 'ionic-alert',
                                                                                                               header: 'แจ้งเตือน',
                                                                                                               message: 'อัพโหลดภาพสำเร็จ',
                                                                                                               buttons: ['Ok',],
                                                                                                           }).then()
                                                                                                       }
                                                                                                   });

                                                                                               setImageFile([...tempImageFile]);
                                                                                               console.log('temp', temp.length)
                                                                                           }
                                                                                       }}/>
                                                                                <label htmlFor={'file-'+ index} style={{marginLeft:8,fontSize:10}}> อัพโหลดรูปภาพ</label>
                                                                            </IonButton>

                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    imageFile[index] != '' ? (
                                                                        <div style={{marginTop:50}}>
                                                                            <IonButton size={"small"} className={"kgo-btn2"}
                                                                                       style={{color: "#FFFFFF"}}>
                                                                                <img src={clipSVG}/>
                                                                                <input id={'file-' + index} hidden type="file" multiple={false}
                                                                                       onChange={async (e) => {
                                                                                           if (e.target.files) {
                                                                                               console.log('e.target.files', e.target.files);
                                                                                               const files = e.target.files;
                                                                                               const temp = [];
                                                                                               const filePath = [];
                                                                                               for (const i in files) {
                                                                                                   if (!isNaN(parseInt(i))) {
                                                                                                       temp.push(files[i])
                                                                                                       filePath.push(URL.createObjectURL(files[i]))
                                                                                                   }
                                                                                               }
                                                                                               let fImage = await resizeFile(temp[0]);
                                                                                               let tempImageFile = imageFile;
                                                                                               console.log(tempImageFile);
                                                                                               console.log('fImage', fImage);
                                                                                               tempImageFile[index] = filePath[0];
                                                                                               appContext.apiService.uploadListingImage(fImage as File, (index + 1).toString())
                                                                                                   .then((response:any) => {
                                                                                                       setListingProfile(response.listingProfile)
                                                                                                       if(response.success){
                                                                                                           present({
                                                                                                               cssClass: 'ionic-alert',
                                                                                                               header: 'แจ้งเตือน',
                                                                                                               message: 'อัพโหลดภาพสำเร็จ',
                                                                                                               buttons: ['Ok',],
                                                                                                           }).then()
                                                                                                       }
                                                                                                   });

                                                                                               setImageFile([...tempImageFile]);
                                                                                               console.log('temp', temp.length)
                                                                                           }
                                                                                       }}/>
                                                                                <label htmlFor={'file-'+ index} style={{marginLeft:8,fontSize:10}}> อัพโหลดรูปภาพ</label>
                                                                            </IonButton>
                                                                            <IonButton size={"small"} color={'danger'} onClick={
                                                                                (e) => {
                                                                                    let tempImageFile = imageFile;
                                                                                    tempImageFile[index] = '';
                                                                                    let tempListingProfile = listingProfile;
                                                                                    tempListingProfile.images[index + 1] = '';
                                                                                    appContext.apiService.updateListingProfile(tempListingProfile)
                                                                                        .then((res)=>{
                                                                                            if(res.success){
                                                                                                present({
                                                                                                    cssClass: 'ionic-alert',
                                                                                                    header: 'แจ้งเตือน',
                                                                                                    message: 'ลบภาพเรียร้อย',
                                                                                                    buttons: ['Ok',],
                                                                                                }).then()
                                                                                            }
                                                                                        })
                                                                                        .catch( e => console.error(e))
                                                                                    setImageFile([...tempImageFile]);
                                                                                }
                                                                            }>
                                                                                <IonIcon icon={remove} slot="start"/>
                                                                                <IonLabel> ลบ</IonLabel>
                                                                            </IonButton>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </IonCol>
                                    )
                                )}

                            </IonRow>
                        </IonGrid>
                    </div>
                    <div style={{ paddingLeft: 12, paddingRight:12}}>
                        <IonGrid>
                            <IonRow>
                                <IonCol size={'12'}>
                                    <h4>
                                        <b>เกี่ยวกับร้าน</b>
                                    </h4>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                    <IonRow>
                        <IonCol>
                            <IonCard  style={{marginTop:0,borderRadius:12}} >
                                <IonCardContent style={{padding:12}}>
                                    <IonRow>
                                        <IonCol size={'12'}>
                                            <IonText className={'title-input-shop'}>ข้อความคำแนะนำร้านค้า</IonText>
                                            <IonInput className={'input-shop'} onIonChange={e => setSubTitle(e.detail.value!)}
                                                      value={subTitle} placeholder={'ข้อความคำแนะนำร้านค้า'} type={'text'} />
                                        </IonCol>
                                        <IonCol size={'12'}>
                                            <IonText className={'title-input-shop'}>ข้อมูลเกี่ยวร้านค้า</IonText>
                                            <IonTextarea
                                                value={detail}
                                                className={'input-shop'}
                                                placeholder={'ข้อมูลเกี่ยวกับร้าน'}
                                                onIonChange={e => {
                                                    setDetail(e.detail.value!)
                                                }}
                                                rows={2}  />
                                        </IonCol>
                                        <IonCol size={'12'}>
                                            {/*<IonText className={'title-input-shop'}>Facebook ร้านค้า</IonText>*/}
                                            {/*<IonInput className={'input-shop'} onIonChange={e => setSubTitle(e.detail.value!)}*/}
                                            {/*          value={subTitle} placeholder={'ข้อความคำแนะนำร้านค้า'} type={'text'} />*/}
                                            <IonLabel className={'title-input-shop'}>Facebook Link</IonLabel>
                                            <IonInput type={"text"} value={facebook} placeholder="ระบุ Link Facebook" className={'input-shop'}
                                                      onIonChange={e => {
                                                          setFacebook(e.detail.value!);
                                                      }}/>
                                            <IonText style={{color:'#9d9d9d',fontSize:12}} >ตัวอย่าง  https://www.facebook.com/LandOfEdutainment</IonText>

                                        </IonCol>
                                        <IonCol size={'12'}>
                                            <IonText className={'title-input-shop'}>Line Link</IonText>
                                            <IonInput className={'input-shop'} onIonChange={e => setLineID(e.detail.value!)}
                                                      value={lineID} placeholder={'ระบุ Line Link'} type={'text'} />
                                            <IonText style={{color:'#9d9d9d',fontSize:10}} >ตัวอย่าง  https://line.me/R/ti/p/@920qmmzo</IonText>

                                        </IonCol>
                                        <IonCol size={'12'}>
                                            <IonText className={'title-input-shop'}>Google Map Link</IonText>
                                            <IonInput className={'input-shop'} onIonChange={e => setGoogleMap(e.detail.value!)}
                                                      value={googleMap} placeholder={'ระบุ Line Link'} type={'text'} />
                                            <IonText style={{color:'#9d9d9d',fontSize:10}} >ตัวอย่าง https://goo.gl/maps/MEdsdgKjbJSvGiCNiNA</IonText>

                                        </IonCol>
                                        <IonCol size={'12'}>
                                            <IonText className={'title-input-shop'}>ประเภทการชำระเงิน<IonText color={'danger'}>*</IonText></IonText>
                                            <IonSelect value={payType} className={'input-shop'} okText="ตกลง" cancelText="ปิด" placeholder="ประเภทการชำระเงิน" onIonChange={e => {
                                                setPayType(e.detail.value)
                                            }} interface="popover">
                                                <IonSelectOption value="bank">โอนผ่านธนาคาร</IonSelectOption>
                                                <IonSelectOption value="promptpay">พร้อมเพย์</IonSelectOption>
                                            </IonSelect>
                                        </IonCol>
                                        {payType && (
                                            <IonCol size={'12'}>
                                                <IonText className={'title-input-shop'}>{payType == 'bank' ? 'หมายเลขบัญชี' : 'หมายเลขพร้อมเพย์'}<IonText color={'danger'}>*</IonText></IonText>
                                                <IonInput className={'input-shop'} onIonChange={e => setPayAccount(e.detail.value!)}
                                                          value={payAccount}  type={'text'} />
                                            </IonCol>
                                        )}
                                        {payType && (
                                            <IonCol size={'12'}>
                                                <IonText className={'title-input-shop'}>ชื่อบัญชี<IonText color={'danger'}>*</IonText></IonText>
                                                <IonInput className={'input-shop'} onIonChange={e => setPayName(e.detail.value!)}
                                                          value={payName}  type={'text'} />
                                            </IonCol>
                                        )}
                                        {(payType == 'bank' && (
                                            <IonCol size={'12'}>
                                                <IonText className={'title-input-shop'}>ธนาคาร<IonText color={'danger'}>*</IonText></IonText>
                                                <IonInput className={'input-shop'} onIonChange={e => setPayBankName(e.detail.value!)}
                                                          value={payBankName}  type={'text'} />
                                            </IonCol>
                                        ))}
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className={"kgo-row"} style={{marginTop: 12, marginBottom: 52}}>
                        <IonCol size={"12"}>
                            <IonButton expand="block" className={"kgo-btn-store"}  onClick={(e) => {
                                updateListingProfile().catch( e => console.error(e));
                            }}> บันทึก
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </div>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Loading...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default ListingProfile;
