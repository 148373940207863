const API = process.env.REACT_APP_API_URL;
export const fetchBanner = async () => {
  console.log("API", API);
  try {
    const response = await fetch(`${API}banner-biz`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
