import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { arrowBackOutline } from "ionicons/icons";
import iconStoreRegister from "../assets/icon-register-store.svg";
import kgoLogoWhite from "../assets/kgo-white.svg";
import { profileModel } from "../models/Data";

const StoreRegisterMain: React.FC<RouteComponentProps> = ({ history }) => {
  const [present] = useIonAlert();
  const appContext = useContext(AppContext);
  const [registerProfile, setRegisterProfile] = useState<profileModel>(
    new profileModel({})
  );
  const [checkApproved, setCheckApprove] = useState(0);

  useEffect(() => {
    //get registerbusinesses
    appContext.apiService
      .getBizRegister()
      .then((response: any) => {
        console.log("response", response);
        if (response.success) {
          const nProfile = new profileModel(response.data);
          appContext.setRegisterStore(nProfile);
          setRegisterProfile(nProfile);

          if (checkApproved > 0) {
            let textStatus = "โปรดรอเจ้าหน้าที่ยืนยันข้อมูลร้านค้า";
            if (nProfile.status == "approved") {
              textStatus =
                "เจ้าหน้าที่ตรวจสอบข้อมูลแล้ว <div>เข้าใช้งานหน้าร้านค้าได้</div>";
              // setTimeout(() =>{
              history.push("/main");
              // }, 2000)
            }
            present({
              cssClass: "ionic-alert",
              header: "สถานะสมัครร้านค้า",
              message: textStatus,
              buttons: ["Ok"],
            }).catch();
          }
        } else {
          if (response.isBan) {
            present({
              cssClass: "ionic-alert",
              header: "Account นี้ถูกระงับการใช้งาน",
              buttons: [
                { text: "Ok", handler: (d) => appContext.apiService.signOut() },
              ],
            }).catch();
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [checkApproved]);

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          {/*<IonButtons slot="start">*/}
          {/*    <IonBackButton color={"light"} icon={arrowBackOutline} text={""} defaultHref='/main'/>*/}
          {/*</IonButtons>*/}
          <IonTitle>สมัครเป็นร้านค้า</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow className="ion-no-padding ion-text-center">
            <IonCol size={"12"}>
              <img
                src={iconStoreRegister}
                style={{ width: 363, marginTop: 100 }}
              />
            </IonCol>

            {registerProfile.status == "waitApprove" ? (
              <IonCol
                size={"12"}
                style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}
              >
                <IonText>
                  เจ้าหน้าที่กำลังตรวจสอบข้อมูลเอกสารของท่าน
                  <div>โดยใช้เวลาในการดำเนินการ 1-2 วัน</div>
                </IonText>
              </IonCol>
            ) : (
              <IonCol
                size={"12"}
                style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}
              >
                <IonText>
                  รับฟรี 10,000 KGO
                  <div>เพียงสมัครเป็นร้านค้ากับเรา</div>
                </IonText>
              </IonCol>
            )}
          </IonRow>
          {registerProfile.status == "waitApprove" ? (
            <IonRow>
              <IonCol size="6">
                <IonButton
                  expand={"block"}
                  className={"kgo-btn"}
                  routerLink="/register-form"
                >
                  {registerProfile.status == "waitApprove"
                    ? "แก้ไขข้อมูลร้านค้า"
                    : "สมัครร้านค้า"}
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  expand={"block"}
                  className={"kgo-btn"}
                  onClick={(e) => {
                    // window.location.reload()
                    const count = checkApproved + 1;
                    setCheckApprove(count);
                  }}
                >
                  ตรวจสอบสถานะร้านค้า
                </IonButton>
              </IonCol>
            </IonRow>
          ) : (
            <IonRow>
              <IonCol size="12">
                <IonButton
                  expand={"block"}
                  className={"kgo-btn"}
                  routerLink="/register-form"
                >
                  {registerProfile?.status == "reject"
                    ? "แก้ไขข้อมูลร้านค้า"
                    : "สมัครร้านค้า"}
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              {registerProfile?.status == "reject" ? (
                <IonCard>
                  <IonCardContent>
                    <IonText color={"danger"}>
                      <h2>คำขอแก้ไขถูกปฎิเสธเนื่องจาก</h2>
                    </IonText>
                    <IonText color={"danger"}>
                      <p>{appContext?.registerStore?.rejectDetail}</p>
                      <p className={"ion-text-center"}>
                        ท่านสามารถส่งแก้ไขคำร้องได้โดยกดแก้ไขข้อมูลร้านค้า
                      </p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              ) : null}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color={"danger"}
                onClick={(e) => {
                  appContext.apiService.signOut();
                }}
              >
                ออกจากระบบ
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default StoreRegisterMain;
