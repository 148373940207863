import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline, cart } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
// @ts-ignore
import nft from "../assets/nft.png";

// @ts-ignore
import bag from "../assets/bag.png";
import TransactionList from "../components/TransactionList";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import titleImg from "../assets/5409459.png";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import redemption from "../assets/5378862.png";
import Slot from "../components/SlotKgo/Slot";
import { fetchPrize, spinSlotAPI } from "../services/slot";
import { fetchIKGO } from "../services/ikgo/index";
import AlertModal from "../components/Modal/AlertModal";
const LuckyDraw: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const accessToken = localStorage.getItem("accessToken");
  const [showLoading, setShowLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ikgo, setIkgo] = useState();
  const [prizeList, setPrizeList] = useState();
  const [singleBtn, setSingleBtn] = useState(false);
  const [isWinPrize, setIsWinPrize] = useState(false);
  const [modalData, setModalData] = useState({
    title: null,
    description: null,
    confirmBtn: null,
  });
  const onCloseWonPrizeModal = () => {
    setIsWinPrize(false);
  };
  const handleConfirmPrizeModal = () => {
    setIsWinPrize(false);
    history.push("/myprize");
  };
  const getAllPrize = async () => {
    const data = await fetchPrize();
    setPrizeList(data?.prizes);
  };
  const getStoreProfile = () => {
    if (appContext.apiService) {
      appContext.getProfile();
    } else {
      console.error("apiService not init!");
    }
  };
  const spinAPI = async () => {
    const data = await spinSlotAPI();
    setTimeout(() => {
      if (data?.data?.prize) {
        setModalData({
          title: data?.data?.prize?.name,
          description: data?.data?.prize?.description,
          // @ts-ignore
          confirmBtn: "ตรวจสอบรางวัล",
        });
        setIsWinPrize(true);
      } else {
        setModalData({
          // @ts-ignore
          title: "การสุ่มรางวัลไม่สำเร็จ",
          // @ts-ignore
          description: `กรุณาติดต่อแอดมิน (Error code:${data})`,
          confirmBtn: null,
        });
        setSingleBtn(true);
        setIsWinPrize(true);
      }
    }, 2000);
  };
  const spinSlot = async () => {
    spinAPI();
  };

  const getIKGO = async () => {
    if (!accessToken) return;
    try {
      const res = await fetchIKGO(accessToken);
      setIkgo(res);
    } catch (error) {
      console.log("iKGO error to fetch", error);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    getAllPrize();
  }, []);
  useEffect(() => {
    if (accessToken) {
      getIKGO();
    }
  }, [accessToken]);
  useEffect(() => {
    getStoreProfile();
  }, [appContext.notify]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <AlertModal
        singleBtn={singleBtn}
        data={modalData}
        isOpen={isWinPrize}
        setIsOpen={onCloseWonPrizeModal}
        // @ts-ignore
        onClickHandle={handleConfirmPrizeModal}
      />
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>Luck Spin ลุ้นของรางวัลพิเศษ</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            height: "100%",
            marginBottom: 16,
          }}
          className="app-container"
        >
          {prizeList && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "orange",
              }}
            >
              {/* @ts-ignore */}
              <Slot spin={spinSlot} balance={ikgo} rewards={prizeList} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <div onClick={() => history.push("/exchangeikgo")}>
              คุณมี
              {isLoading ? (
                <span
                  style={{
                    fontSize: 14,
                    color: "#FFFFFF",
                    background: "linear-gradient(#FF6E07, #FFA319)",
                    borderRadius: 24,
                    padding: "3px 7px 3px 7px",
                    marginTop: 12,
                    marginLeft: 5,
                  }}
                >
                  ... กำลังโหลด
                </span>
              ) : (
                <span
                  style={{
                    fontSize: 14,
                    color: "#FFFFFF",
                    background: "linear-gradient(#FF6E07, #FFA319)",
                    borderRadius: 24,
                    padding: "3px 7px 3px 7px",
                    marginTop: 12,
                    marginLeft: 5,
                  }}
                >
                  {ikgo ? ikgo : "0"}
                  &nbsp; iKGO
                </span>
              )}
            </div>

            <div onClick={() => history.push("/myprize")}>รายการของฉัน</div>
          </div>
          <div style={{ width: "100%", paddingLeft: 16, paddingRight: 16 }}>
            <IonCard
              className={"kgo-card"}
              style={{
                filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                background: "#FFA412",
              }}
            >
              <IonCardContent
                style={{ color: "#fff", paddingTop: 0, paddingBottom: 0 }}
              >
                <IonRow
                  style={{ cursor: "pointer" }}
                  className={"ion-no-padding"}
                  onClick={() => history.push("/exchangeikgo")}
                >
                  <h2
                    style={{
                      fontSize: 24,
                      marginTop: 24,
                      marginBottom: 24,
                      fontWeight: 600,
                    }}
                  >
                    แลกเหรียญ iKGO เพิ่มเติม
                  </h2>
                </IonRow>
              </IonCardContent>
            </IonCard>
            <div style={{ fontSize: 12, color: "gray" }}>
              <h5>เงื่อนไขการรับของรางวัล:</h5>
              <ol>
                <li>ของรางวัลทุกชิ้นไม่สามารถแลกเป็นเงินสดได้ทุกกรณี</li>
                <li>
                  ลูกค้าจะต้องรับผิดชอบค่าใช้จ่ายในการจัดส่งสินค้าเองตามน้ำหนักของรางวัล
                </li>
                <li>กรอกข้อมูลการจัดส่งอย่างถูกต้อง</li>
                <li>แนบสลิปการจัดส่งในกรณีเลือกจัดส่งสินค้า</li>
                <li>
                  ลูกค้าสามารถมารับรางวัลด้วยตนเองที่บริษัทได้
                  แต่ต้องแจ้งล่วงหน้า 1-2 วันก่อนเข้ารับ
                  และมารับภายในเวลาทำการปกติ (วันจันทร์ถึงศุกร์ เวลา 9:00 น. –
                  17:00 น.)
                </li>
                <li>
                  กดรับของรางวัลภายใน 14 วัน: ลูกค้าต้องกดรับของรางวัลภายใน 14
                  วันหลังจากวันที่ได้รับแจ้งสิทธิ์ หากไม่กดรับภายในเวลาที่กำหนด
                  สิทธิ์จะหมดอายุและไม่สามารถรับรางวัลได้อีก
                </li>
                <li>
                  การจัดส่งของรางวัลเป็นไปตามที่บริษัทกำหนด:
                  บริษัทจะจัดส่งของรางวัลภายใน 5-7
                  วันทำการหลังจากได้รับข้อมูลและสลิปการจัดส่งที่ถูกต้อง
                </li>
                <li>
                  บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งล่วงหน้า:
                  บริษัทมีสิทธิ์ในการปรับเปลี่ยนเงื่อนไขต่างๆ
                  รวมถึงของรางวัลและวิธีการจัดส่งโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  ทั้งนี้การเปลี่ยนแปลงใดๆ
                  จะยึดตามข้อกำหนดที่ประกาศในระบบหรือช่องทางที่บริษัทกำหนด
                </li>
              </ol>
            </div>
          </div>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LuckyDraw;
