const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");

export const fetchIKGO = async (accessToken: any) => {
  console.log('accessToken', accessToken)
  try {
    const response = await fetch(`${API}user-ikgo/points`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Optional: Add any additional headers if needed
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateIKGO = async (payload: any) => {
  try {
    const response = await fetch(`${API}wallet/buy-ikgo`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const redeemGame = async (payload: any) => {
  // {code: "fdsgwg"}
  try {
    const response = await fetch(`${API}spinWheel/redeem`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    console.log("response", response);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
