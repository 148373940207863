import {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    useIonAlert,
    IonText, IonSelect, IonSelectOption, IonCol, IonRow
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../context/state";
import {profileModel} from "../models/Data";

interface Props {
    step: any;
    setStep: Function;
    required: boolean;
}

export default function StoreRegisterStep2(props: Props) {
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [provinceID, setProvinceID] = useState('');
    const [provinceName, setProvinceName] = useState('');
    const [amphurID, setAmphurID] = useState('');
    const [amphurName, setAmphurName] = useState('');
    const [tambonID, setTambonID] = useState('');
    const [tambonName, setTambonName] = useState('');

    const [latlngText, setLatlngText] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [storeProfile, setStoreProfile] = useState<profileModel>(new profileModel({}));
    const [dropdownProvince, setDropdownProvince] = useState([]);
    const [dropdownAmphur, setDropdownAmphur] = useState([]);
    const [dropdownTambon, setDropdownTambon] = useState([]);

    const [disAmphur, setDisAmphur] = useState(true);
    const [disTambon, setDisTambon] = useState(true);

    const [disableBtn, setDisableBtn] = useState(true)

    useEffect(() => {
        console.log('storeProfile', appContext.registerStore);
        if (appContext.registerStore) {
            setAddress(appContext.registerStore?.address ?? '');
            setZipcode(appContext.registerStore?.zipcode ?? '');
            setLatlngText(appContext.registerStore?.latlngText ?? '');
            setLat(appContext.registerStore?.lat ?? '');
            setLng(appContext.registerStore?.lng ?? '');
            setStoreProfile(appContext.registerStore);

            setProvinceID(appContext.registerStore.provinceId);
            setAmphurID(appContext.registerStore.amphurId);
            setTambonID(appContext.registerStore.districtId);
            setAmphurName(appContext.registerStore.districtName)
            setProvinceName(appContext.registerStore.provinceName)
            setTambonName(appContext.registerStore.districtName)
        }

        // setTimeout(() => {
            getProvince().then(res => {
                if (appContext.registerStore.provinceId && appContext.registerStore.amphurId && appContext.registerStore.districtId) {
                    getAmphur(appContext.registerStore.provinceId).then(res => {
                        setDisAmphur(false);
                        getTambon(appContext.registerStore.amphurId).then(res => {
                            setDisTambon(false);

                            setProvinceID(appContext.registerStore.provinceId);
                            setAmphurID(appContext.registerStore.amphurId);
                            setTambonID(appContext.registerStore.districtId);
                            setAmphurName(appContext.registerStore.districtName)
                            setProvinceName(appContext.registerStore.provinceName)
                            setTambonName(appContext.registerStore.districtName)
                        })
                    })
                }
            });
        // }, 300)
    }, [appContext.registerStore]);

    async function registerStore() {
        let store = storeProfile;
        store.address = address;
        store.zipcode = zipcode;

        store.provinceId = provinceID;
        store.provinceName = provinceName;
        store.amphurId = amphurID;
        store.amphurName = amphurName;
        store.districtName = tambonName;
        store.districtId = tambonID;

        store.lat = lat;
        store.lng = lng;

        appContext.setRegisterStore(store)
        props.setStep(2);
    }

    async function getProvince() {
        if (appContext?.apiService) {
            appContext.apiService.getProvince().then((response: any) => {
                // console.log('getProvince', response)
                setDropdownProvince(response);
            });
        }
    }

    async function getAmphur(data: string) {
        if (appContext?.apiService) {
            appContext.apiService.getAmphur(data).then((response: any) => {
                // console.log('getAmphur', response)
                setDropdownAmphur(response);
            });
        }
    }

    async function getTambon(data: string) {
        if (appContext?.apiService) {
            appContext.apiService.getTambon(data).then((response: any) => {
                // console.log('getTambon', response)
                setDropdownTambon(response);
            });
        }
    }

    async function getZipcode(data: string) {
        if (appContext?.apiService) {
            appContext.apiService.getZipcode(data).then((response: any) => {
                console.log('getZipcode', response)
                setZipcode(response[0]?.zipcode);
            });
        }
    }

    useEffect(() => {
        if ((address && provinceID && amphurID && tambonID && zipcode) || !props.required) {
            setDisableBtn(false);
        }
    }, [address, provinceID, amphurID, tambonID, zipcode]);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            registerStore().catch()
        }} id={"register-store-form"}>
            <IonList>
                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>ที่อยู่ <IonText
                        color={"danger"}>*</IonText></IonLabel>
                    <IonInput required type={"text"} value={address} placeholder="ระบุที่อยู่"
                              onIonChange={e => {
                                  setAddress(e.detail.value!);
                              }}/>
                </IonItem>

                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>จังหวัด <IonText
                        color={"danger"}>*</IonText></IonLabel>
                    <IonSelect value={provinceID} okText="ตกลง" cancelText="ปิด" placeholder={'กรุณาเลือกจังหวัด'}
                               compareWith={(e1: any, initValue: any) => e1 == initValue}
                               onIonChange={(e) => {
                                   if (e.detail.value != '') {
                                       const name = dropdownProvince.find((r: any) => r.provinceID == e.detail.value);
                                       setProvinceID(e.detail.value)
                                       // @ts-ignore
                                       setProvinceName((name) ? name.provinceName : '')
                                       getAmphur(e.detail.value).then();
                                       setDisAmphur(false);
                                   } else {
                                       setProvinceID('')
                                       setProvinceName('')
                                   }
                                   setAmphurID('');
                                   setAmphurName('');
                                   setTambonID('');
                                   setTambonName('');
                                   setZipcode('');
                               }} interface="popover">
                        {dropdownProvince.map((data: any, key) => (
                            <IonSelectOption key={key} value={data?.provinceID}> {data?.provinceName}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>อำเภอ <IonText
                        color={"danger"}>*</IonText></IonLabel>
                    <IonSelect value={amphurID} okText="ตกลง" cancelText="ปิด" disabled={disAmphur}
                               compareWith={(e1: any, initValue: any) => e1 == initValue}
                               placeholder={'กรุณาเลือกอำเภอ'} onIonChange={(e) => {
                        if (e.detail.value != '') {
                            const name = dropdownAmphur.find((r: any) => r.amphurID == e.detail.value);
                            // @ts-ignore
                            setAmphurName((name) ? name.amphurName : '')
                            setAmphurID(e.detail.value)
                            getTambon(e.detail.value).then();
                            setDisTambon(false);
                        } else {
                            setAmphurID('');
                            setAmphurName('');
                        }
                        setTambonID('')
                        setTambonName('')
                        setZipcode('');
                    }} interface="popover">
                        {dropdownAmphur.map((data: any, key) => (
                            <IonSelectOption key={key} value={data?.amphurID}> {data?.amphurName}</IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>ตำบล <IonText
                        color={"danger"}>*</IonText></IonLabel>
                    <IonSelect value={tambonID} okText="ตกลง" cancelText="ปิด" disabled={disTambon}
                               compareWith={(e1: any, initValue: any) => e1 == initValue}
                               placeholder={'กรุณาเลือกตำบล'} onIonChange={(e) => {
                        const name = dropdownTambon.find((r: any) => r.districtID == e.detail.value);
                        setTambonID(e.detail.value)
                        // @ts-ignore
                        setTambonName((name) ? name.districtName : '')
                        getZipcode(e.detail.value).then();
                        setZipcode('');
                    }} interface="popover">
                        {dropdownTambon.map((data: any, key) => (
                            <IonSelectOption key={key} value={data?.districtID}> {data?.districtName}</IonSelectOption>
                        ))}

                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>รหัสไปรษณีย์ <IonText
                        color={"danger"}>*</IonText></IonLabel>
                    <IonInput type={"text"} maxlength={10} readonly
                              value={zipcode} placeholder="กรุณาเลือกจังหวัด,อำเภอ,ตำบล" onIonChange={e => {
                        setZipcode(e.detail.value!);
                    }}/>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked" className={"label-form"}>ละติจูดและลองจิจูด </IonLabel>
                    <IonInput type={"text"}
                              value={latlngText} placeholder="ตย. 0.0123,0.3211" onIonChange={e => {
                        setLatlngText(e.detail.value!);
                    }}/>
                </IonItem>
                {/*<IonItem style={{display:'none'}}>*/}
                {/*    <IonLabel position="stacked" className={"label-form"}>ละติจูด(Latitude) </IonLabel>*/}
                {/*    <IonInput type={"number"}*/}
                {/*              value={lat} placeholder="ระบุละติจูด" onIonChange={e => {*/}
                {/*        setLat(e.detail.value!);*/}
                {/*    }}/>*/}
                {/*</IonItem>*/}
                {/*<IonItem>*/}
                {/*    <IonLabel position="stacked" className={"label-form"}>ลองจิจูด(Longitude) </IonLabel>*/}
                {/*    <IonInput type={"number"}*/}
                {/*              value={lng} placeholder="ระบุลองจิจูด" onIonChange={e => {*/}
                {/*        setLng(e.detail.value!);*/}
                {/*    }}/>*/}
                {/*</IonItem>*/}
            </IonList>
            <IonRow className={"kgo-row ion-text-center kgo-m"}>
                <IonCol>
                    <IonButton className={"kgo-btn"} expand={"block"} type={"submit"}
                               disabled={disableBtn}>ถัดไป</IonButton>
                </IonCol>
            </IonRow>
        </form>
    );
};

