import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../context/state";
import RewardCards from "../../components/Cards/RewardCards";
import { fetchProfile } from "../../services/user";
import { fetchMyPrizesHistory } from "../../services/prize";
import StatusModal from "../../components/Modal/StatusModal";

const MyPrizeHistory: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [bizId, setBizId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [prizes, setPrizes] = useState();
  const [confirmCancelClaim, setConfirmCancelClaim] = useState(false);
  const [selectedItems, setSelectedItems] = useState();

  const handleSelect = (data) => {
    setConfirmCancelClaim(!confirmCancelClaim);
    setSelectedItems(data);
  };
  const getShopId = async () => {
    const shopId = await fetchProfile();
    setBizId(shopId.id);
  };
  const getMyPrizes = async (shopId) => {
    const res = await fetchMyPrizesHistory(shopId);
    if (res?.statusCode > 299) {
      // history.push("/main");
      return alert(
        "เกิดข้อผิดพลาด กรุณาติดต่อ Admin (Error" +
          res?.statusCode +
          " : " +
          res?.message +
          ")"
      );
    }
    setPrizes(res?.data);
    setShowLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (bizId !== undefined) {
      getMyPrizes(bizId);
    }
  }, [bizId]);
  useEffect(() => {
    if (appContext) {
      // @ts-ignore
      setBizId(appContext?.profile.id);
    }
    getShopId();
  }, []);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      {/* @ts-ignore */}
      <StatusModal
        data={selectedItems}
        isOpen={confirmCancelClaim}
        setIsOpen={() => setConfirmCancelClaim(!confirmCancelClaim)}
      />
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/myprize"
            />
          </IonButtons>
          <IonTitle> ประวัติการแลกของรางวัล</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div style={{ marginTop: 8, paddingLeft: 12, paddingRight: 12 }}>
            <IonText style={{ fontWeight: 700, fontSize: 16 }}>
              ประวัติการแลกของรางวัล
            </IonText>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "fit",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {/* @ts-ignore */}
            {prizes?.length > 0 ? (
              // @ts-ignore
              prizes
                // @ts-ignore
                .map((prize) => (
                  // @ts-ignore
                  <RewardCards
                    meta={prize}
                    data={prize.shopPrizeDetails}
                    isClaimed
                    type={"prize"}
                    source={"history"}
                    isOpen={confirmCancelClaim}
                    // @ts-ignore
                    setIsOpen={handleSelect}
                  />
                ))
            ) : (
              <h1 className="ion-text-center" style={{ marginTop: 42 }}>
                {isLoading ? "กำลังโหลดข้อมูล" : "ไม่พบข้อมูล..."}
              </h1>
            )}
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPrizeHistory;
