import React, { ReactNode } from "react";
import { useHistory } from "react-router";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonIcon,
  IonCard,
  IonCardContent,
  IonFooter,
  IonText,
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonRouterLink,
  IonImg,
} from "@ionic/react";
// @ts-ignore
import kgoLogoWhite from "../../assets/kgo-white.svg";
// @ts-ignore
import storeWallet from "../../assets/store-wallet.svg";
// @ts-ignore
import storeVoucher from "../../assets/store-voucher-icon-active.svg";
// @ts-ignore
import dollar from "../../assets/coin_kgo1.svg";
// @ts-ignore
import Rectangle from "../../assets/Rectangle-78.png";
// @ts-ignore
import LogoKgo from "../../assets/kgo-nft.png";
import IconArrowBackOutline from "../../components/icons/BackIcon";
interface CustomGradientProps {
  style?: React.CSSProperties;
  headerChildren?: ReactNode;
  children?: ReactNode;
  full?: boolean;
  withFooter?: boolean;
}

const KgoHeader: React.FC<CustomGradientProps> = ({
  style,
  headerChildren,
  children,
  withFooter,
  full,
}) => {
  const history = useHistory();
  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)", // Adjust shadow properties as needed
        backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust alpha value as needed
        marginBottom: full ? 0 : 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "space-between",
      }}
    >
      <div
        style={{
          padding: 10,
          borderBottom: "1px solid lightgrey",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)", // Adjust shadow properties as needed
          backgroundColor: full ? "#FF6E07" : "#FFA319", // Adjust alpha value as needed
          ...style,
        }}
      >
        {headerChildren}
      </div>
      <div>{children}</div>
      {withFooter && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            backgroundColor: "#000",
            paddingRight: 16,
            paddingLeft: 16,
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            gap: 32,
            height: 72,
            width: "100%",
            zIndex: 99,
          }}
        >
          {/* */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => history.push("/Voucher")}
          >
            <img
              src={"https://cdn-icons-png.flaticon.com/512/3126/3126564.png"}
              style={{ width: 30, background: "white", borderRadius: 8 }}
            />
            <div style={{ fontSize: 12 }}>Pay Voucher</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => history.push("/redeem-voucher")}
          >
            <img src={storeVoucher} style={{ width: 30 }} />
            <div style={{ fontSize: 12 }}>Redeem Voucher</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => history.push("/Transaction")}
          >
            <img src={storeWallet} style={{ width: 30 }} />
            <div style={{ fontSize: 12 }}>Transaction</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KgoHeader;
