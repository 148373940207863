// icon:arrow-back-outline | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";

function IconArrowBackOutline() {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={48}
        d="M244 400L100 256l144-144M120 256h292"
      />
    </svg>
  );
}

export default IconArrowBackOutline;
