const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");
export const downloadTransactionHistory = async (name, startDate, endDate) => {
  try {
    const response = await fetch(
      `${API}wallet/transaction/history?name=${name ?? ""}&startDate=${
        startDate ?? ""
      }&endDate=${endDate ?? ""}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Optional: Add any additional headers if needed
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData || "Failed to fetch data");
    }

    // Create a Blob from the response
    const blob = await response.blob();

    // Create a link element to download the file
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `transaction_history${startDate}-${endDate}.xlsx`; // Specify the file name for download
    document.body.appendChild(a);
    a.click();

    // Clean up and remove the link element
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

// link.setAttribute(
//     "download",
//     `transaction_history${startDate}-${endDate}.xlsx`
//   ); // Specify the filename for download
