import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import AlertModal from "../components/Modal/AlertModal";
import { fetchIKGO, updateIKGO } from "../services/ikgo";
const ExchangeiKGO: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const accessToken = localStorage.getItem("accessToken");
  const [kgoBalance, setKgoBalance] = useState(0);
  const [iKGOBalance, setIKGOBalance] = useState(0);
  const [selectedItem, setSelectedItem] = useState();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const exchangeOptions = [
    {
      id: 1,
      price: 100,
      amount: 1,
      spin: 1,
    },
    {
      id: 2,
      price: 500,
      amount: 5,
      spin: 5,
    },
    {
      id: 3,
      price: 1000,
      amount: 10,
      spin: 10,
    },
    {
      id: 4,
      price: 5000,
      amount: 50,
      spin: 50,
    },
  ];

  const handleCloseModal = () => {
    setIsShowConfirmModal(false);
  };
  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsShowConfirmModal(true);
  };

  const handleConfirmExchange = async () => {
    // call api
    const payload = {
      amount: selectedItem?.price,
      serviceType: "buy-ikgo",
    };
    const res = await updateIKGO(payload);
    setIsShowConfirmModal(false);
    // @ts-ignore
    alert(
      `แลกเหรียญจำนวน​ ${selectedItem?.amount} iKGO สำเร็จ กรุณารอระบบทำรายการสักครู่`
    );
    console.log("res", res);
  };

  const getIKGO = async () => {
    try {
      const data = await fetchIKGO(accessToken);
      console.log("data", data);
      setIKGOBalance(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getIKGO();
  }, []);
  useEffect(() => {
    if (appContext?.kgoBalance) {
      setKgoBalance(appContext?.kgoBalance);
    }
  }, [appContext]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/luckydraw"
            />
          </IonButtons>
          <IonTitle>แลกเปลี่ยนเหรียญ iKGO </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"bg-secondary"}>
        <AlertModal
          data={{
            // @ts-ignore
            title: `ยืนยันที่จะแลก ${selectedItem?.amount}`,
            description: "หากกดยืนยันแล้วจะไม่สามารถย้อนคืนเหรียญ KGO ได้",
          }}
          isOpen={isShowConfirmModal}
          setIsOpen={handleCloseModal}
          onClickHandle={handleConfirmExchange}
        />
        <div className="app-container">
          <div
            style={{
              padding: "16px 16px 0 16px ",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 14,
                color: "#FFFFFF",
                background: "linear-gradient(#FF6E07, #FFA319)",
                borderRadius: 24,
                padding: 8,
              }}
            >
              <p style={{ margin: 0 }}>เหรียญ KGO: {kgoBalance}</p>
            </div>

            <p style={{ margin: 0 }}>เหรียญ iKGO: {iKGOBalance}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              padding: 16,
            }}
          >
            {/* cards */}
            {exchangeOptions.map((forex) => (
              <div
                style={{
                  display: "flex",
                  padding: 16,
                  width: "100%",
                  justifyContent: "space-between",
                  height: 72,
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 8,
                }}
              >
                <div>
                  <p style={{ margin: 0 }}>{forex.amount} iKGO</p>
                  <p style={{ margin: 0, color: "gray", fontSize: 14 }}>
                    สามารถสุ่มลุ้นรางวัลได้ {forex.spin} ครั้ง
                  </p>
                </div>
                <button
                  disabled={kgoBalance < forex.price}
                  style={{
                    backgroundColor:
                      kgoBalance < forex.price ? "gray" : "orange",
                    borderRadius: 8,
                    color: "white",
                  }}
                  onClick={() => handleSelect(forex)}
                >
                  {forex.price} KGO
                </button>
              </div>
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ExchangeiKGO;
