import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonGrid,
  IonProgressBar,
  useIonAlert,
  IonCol,
  IonText,
  IonRow,
  IonIcon,
  IonLabel,
  IonAlert,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { arrowForwardOutline } from "ionicons/icons";
import { loginWithPassword } from "../services/user";

const PasswordLogin: React.FC<RouteComponentProps> = ({ history }) => {
  const inputRef = useRef<any>(null);
  const [tel, setTel] = useState("");
  const [referral, setReferral] = useState("");
  const [showReferral, setShowReferral] = useState(false);
  const [password, setPassword] = useState();
  const [runningProcess, setRunningProcess] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const login = async () => {
    if (tel && password) {
      const payload = {
        phoneNumber: tel,
        password: password,
      };
      const data = await loginWithPassword(payload);
      console.log("data", data);
      if (data.statusCode) {
        return alert(
          data?.statusCode == 401
            ? "ผู้ใช้งานนี้ยังไม่ได้ตั้ง Password"
            : data?.message
        );
      } else {
        localStorage.setItem("accessToken", data);
        localStorage.setItem("phoneNumber", tel);
        history.push("/main");
      }
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={"app-container"}>
          <IonGrid>
            <IonRow className="ion-text-center">
              <IonCol size={"12"}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/KGO-Logo.png"}
                  style={{ maxHeight: 230, maxWidth: 94, marginTop: 80 }}
                />
              </IonCol>

              <IonCol
                size={"12"}
                style={{ marginTop: 27, fontSize: 18, lineHeight: "27px" }}
              >
                <IonText>
                  เชื่อม โลกเสมือน กับ โลกความจริง
                  <div>เข้าด้วยกันด้วยเหรียญ KGO</div>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center" style={{ marginTop: 28 }}>
              <IonCol sizeMd={"6"} offsetMd={"3"} sizeXs={"10"} offsetXs={"1"}>
                <IonLabel
                  position="stacked"
                  style={{ marginBottom: "10px", textAlign: "center" }}
                >
                  กรอกเบอร์โทรศัพท์ของคุณ{" "}
                </IonLabel>
                <IonInput
                  ref={(ref) => (inputRef.current = ref)}
                  type="tel"
                  placeholder="08xxxxxxxx"
                  style={{ fontSize: "1em" }}
                  className={"kgo-input-light"}
                  value={tel}
                  maxlength={10}
                  onIonChange={(e) => {
                    setTel(e.detail.value!);
                    // if (e.detail.value!.length < 10) {
                    //   setDisableBtn(true);
                    // } else {
                    //   setDisableBtn(false);
                    // }
                  }}
                />
                <IonLabel
                  position="stacked"
                  style={{ marginBottom: "10px", textAlign: "center" }}
                >
                  กรอกรหัสผ่านของคุณ{" "}
                </IonLabel>
                <IonInput
                  ref={(ref) => (inputRef.current = ref)}
                  type="password"
                  placeholder="กรอกรหัสผ่าน"
                  style={{ fontSize: "1em" }}
                  className={"kgo-input-light"}
                  value={password}
                  maxlength={50}
                  onIonChange={(e) => {
                    setPassword(e.detail.value!);
                    if (e.detail.value!.length < 3) {
                      setDisableBtn(true);
                    } else {
                      setDisableBtn(false);
                    }
                  }}
                />

                <IonText>
                  <p style={{ paddingBottom: 0, marginBottom: 0 }}>
                    ยังไม่ได้ตั้งรหัสผ่าน?
                  </p>{" "}
                </IonText>
                <IonText
                  style={{ fontSize: 14, cursor: "pointer" }}
                  color={"primary"}
                  onClick={() => history.push("/mobile-verify")}
                >
                  เข้าสู่ระบบด้วย OTP
                </IonText>
                <IonRow>
                  <IonCol style={{ padding: "25px 0" }}>
                    <IonButton
                      className={"kgo-btn"}
                      expand={"block"}
                      strong={true}
                      disabled={disableBtn}
                      onClick={login}
                    >
                      ถัดไป <IonIcon slot="end" icon={arrowForwardOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        </div>
      </IonContent>
      <IonAlert
        isOpen={showReferral}
        onDidDismiss={() => setShowReferral(false)}
        cssClass="my-custom-class"
        header={"ระบุผู้แนะนำ"}
        // header={'Prompt!'}
        inputs={[
          {
            name: "refCode",
            id: "refCode",
            type: "tel",
            placeholder: "เบอร์โทรผู้แนะนำ",
            value: referral,
            max: 10,
          },
        ]}
        buttons={[
          {
            text: "ปิด",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "ยืนยัน",
            handler: (data) => {
              console.log("Confirm Ok", data);
              if (data?.refCode) {
                setReferral(data.refCode);
                localStorage.setItem("referral", data.refCode);
              } else {
                setReferral("");
                localStorage.setItem("referral", "");
              }
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default PasswordLogin;
