import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonToolbar,
  IonProgressBar,
  useIonAlert,
  IonLoading,
  IonToggle,
  IonCardContent,
  IonCard,
  IonRow,
  IonCol,
  IonBackButton,
  IonDatetime,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import TmpImage from "../assets/bi_image-alt.png";
// @ts-ignore
import clipSVG from "../assets/clip.svg";
import { fetchProfile } from "../services/user";
import { fetchUserVoucherById } from "../services/voucher";

interface ParamTypes {
  voucherId: string;
  action: string;
}

const RedeemVoucherCreate: React.FC<RouteComponentProps> = ({ history }) => {
  const API = process.env.REACT_APP_API_URL;
  const accessToken = localStorage.getItem("accessToken");
  const { voucherId, action } = useParams<ParamTypes>();
  const appContext = useContext(AppContext);
  const [bizId, setBizId] = useState();
  const [id, setID] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("Voucher");
  const [amount, setAmount] = useState("");
  const [detail, setDetail] = useState("");
  const [limitUseCount, setLimitUseCount] = useState(0);
  const [status, setStatus] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [runningProcess, setRunningProcess] = useState(true);
  const [isSelectStart, setIsSelectStart] = useState(false);
  const [isSelectEnd, setIsSelectEnd] = useState(false);
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [fileUploadImage, setFileUploadImage] = useState<Array<File>>([]);
  const [imageFile, setImageFile] = useState("");
  const convertDate = (date: any) => {
    if (!date) return "--/--/--";
    if (date) {
      const d = new Date(date);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let dt = d.getDate();

      if (dt < 10) {
        // @ts-ignore
        dt = "0" + dt;
      }
      if (month < 10) {
        // @ts-ignore
        month = "0" + month;
      }
      return dt + "/" + month + "/" + year;
    }
  };
  const [present] = useIonAlert();

  const getShopId = async () => {
    const shopId = await fetchProfile();
    setBizId(shopId.id);
  };
  useEffect(() => {
    getShopId();
  }, []);
  useEffect(() => {
    if (voucherId) {
      getUserVoucherById(voucherId);
    }
  }, [voucherId]);
  const getUserVoucherById = async (voucherId) => {
    try {
      const data = await fetchUserVoucherById(voucherId);
      if (data) {
        setID(data?._id);
        setName(data?.vName);
        setAmount(data?.price);
        setType(data?.type);
        setDetail(data?.description);
        setLimitUseCount(data?.limitUseCount ? data?.limitUseCount : 0);
        setImageFile(data?.imgUrl);
        setStartAt(data?.startDate);
        setStatus(data?.isActive);
        setEndAt(data.expirationDate ? data.expirationDate : "5m");
      } else {
        appContext.getProfile();
      }
    } catch (error) {
      console.log("error", error);
    }
    setRunningProcess(false);
  };

  useEffect(() => {
    if (name && amount && type && startAt && fileUploadImage && endAt) {
      setDisableBtn(false);
    }
  }, [name, amount, type, startAt, fileUploadImage, endAt]);
  async function saveVoucherList(action = "create") {
    if (!bizId)
      return alert("Error cannot find businessId please contact admin");
    const formData = new FormData();
    // @ts-ignore
    formData.append("isDeliver", isDelivery);
    // @ts-ignore
    formData.append("isActive", status);
    formData.append("vName", name);
    formData.append("price", amount);
    // @ts-ignore
    formData.append("startDate", startAt);
    formData.append("type", type);
    formData.append("description", detail);
    // @ts-ignore
    formData.append("expirationDate", endAt);
    formData.append("file", fileUploadImage[0]);
    formData.append("shopId", bizId);
    if (action === "delete") {
      formData.append("deletedAt", new Date().toISOString());
    }

    let text = "";
    if (action === "create") {
      text = `ยืนยันสร้าง Voucher <strong>${name}</strong> <div>นี้ใช่หรือไม่?</div>`;
    } else if (action === "edit") {
      text = `ยืนยันแก้ไข Voucher <strong>${name}</strong> <div>ใช่หรือไม่?</div>`;
    } else if (action === "delete") {
      text = `ยืนยันลบ Voucher <strong>${name}</strong> <div>นี้ใช่หรือไม่?</div>`;
    }
    await present({
      header: "ยืนยัน",
      message: text,
      buttons: [
        "Cancel",
        {
          text: "Ok",
          handler: async (d) => {
            setShowLoading(true);
            await fetch(`${API}user-vouchers/${voucherId}`, {
              method: "PATCH",
              body: formData,
            })
              .then((response: any) => {
                if (response.success) {
                  present({
                    cssClass: "ionic-alert",
                    header: "แจ้งเตือน",
                    message: "บันทึกข้อมูล Voucher สำเร็จ",
                    buttons: ["Ok"],
                  });
                } else {
                  present({
                    cssClass: "ionic-alert",
                    header: "Alert",
                    message: response.error,
                    buttons: ["Ok"],
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                setShowLoading(false);
              });

            history.push("/redeem-voucher");
          },
        },
      ],
      onDidDismiss: (e) => {
        // alert(e)
        console.log(e, "did dismiss");
      },
    });
  }
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store ion-text-center"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/redeem-voucher"
            />
          </IonButtons>
          <IonText>
            {action == "edit" ? "แก้ไข Redeem Voucher" : "สร้าง Redeem Voucher"}
          </IonText>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow className={"kgo-row"} style={{ marginTop: 8 }}>
            <IonCol size={"12"}>
              <div className={"ion-text-center"}>
                <IonText style={{ color: "grey", fontSize: 14 }}>
                  รูปภาพ
                </IonText>
                <div
                  className={"cover-upload-image small-image-cover"}
                  style={{
                    margin: "auto",
                    backgroundImage: `url(${imageFile})`,
                  }}
                >
                  {
                    <div>
                      {imageFile == "" ? (
                        <div>
                          <IonRow>
                            <IonCol size={"12"}>
                              <img
                                src={TmpImage}
                                style={{ width: 55, marginTop: 18 }}
                              />
                              <br />
                            </IonCol>
                          </IonRow>
                          <IonButton
                            size={"small"}
                            className={"kgo-btn2"}
                            style={{ color: "#FFFFFF", marginBottom: 8 }}
                          >
                            <img src={clipSVG} />
                            <input
                              id={"file-upload"}
                              hidden
                              type="file"
                              multiple={false}
                              onChange={(e) => {
                                if (e.target.files) {
                                  console.log("e.target.files", e.target.files);
                                  const files = e.target.files;
                                  const temp = [];
                                  const filePath = [];
                                  for (const i in files) {
                                    if (files[i].size > 5e6) {
                                      // @ts-ignore
                                      e.target.value = null;
                                      present({
                                        cssClass: "ionic-alert",
                                        header: "แจ้งเตือน",
                                        message: "พบขนาดไฟล์ห้ามเกิน 5MB",
                                        buttons: ["Ok"],
                                      }).catch();
                                    } else if (!isNaN(parseInt(i))) {
                                      // @ts-ignore
                                      temp.push(files[i]);
                                      filePath.push(
                                        // @ts-ignore
                                        URL.createObjectURL(files[i])
                                      );
                                    }
                                  }
                                  setFileUploadImage(temp);
                                  setImageFile(filePath[0]);
                                }
                              }}
                            />
                            <label
                              htmlFor={"file-upload"}
                              style={{ marginLeft: 8, fontSize: 10 }}
                            >
                              {" "}
                              อัพโหลดรูปภาพ
                            </label>
                          </IonButton>
                        </div>
                      ) : null}
                      {imageFile != "" ? (
                        <div style={{ marginTop: 80 }}>
                          <IonButton
                            size={"small"}
                            className={"kgo-btn2"}
                            style={{ color: "#FFFFFF" }}
                          >
                            <img src={clipSVG} />
                            <input
                              id={"file-upload"}
                              hidden
                              type="file"
                              multiple={false}
                              onChange={(e) => {
                                if (e.target.files) {
                                  console.log("e.target.files", e.target.files);
                                  const files = e.target.files;
                                  const temp = [];
                                  const filePath = [];
                                  for (const i in files) {
                                    if (files[i].size > 5e6) {
                                      // @ts-ignore
                                      e.target.value = null;

                                      present({
                                        cssClass: "ionic-alert",
                                        header: "แจ้งเตือน",
                                        message: "พบขนาดไฟล์ห้ามเกิน 5MB",
                                        buttons: ["Ok"],
                                      }).catch();
                                    } else if (!isNaN(parseInt(i))) {
                                      // @ts-ignore
                                      temp.push(files[i]);
                                      filePath.push(
                                        // @ts-ignore
                                        URL.createObjectURL(files[i])
                                      );
                                    }
                                  }
                                  setFileUploadImage(temp);
                                  setImageFile(filePath[0]);
                                  console.log("temp", temp.length);
                                }
                              }}
                            />
                            <label
                              htmlFor={"file-upload"}
                              style={{ marginLeft: 8, fontSize: 10 }}
                            >
                              {" "}
                              อัพโหลดรูปภาพ
                            </label>
                          </IonButton>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </IonCol>

            <IonCol>
              <IonCard className={"kgo-card kgo-light"}>
                <IonCardContent style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <IonList>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        ชื่อ
                      </IonLabel>
                      <IonInput
                        type="text"
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ชื่อ Voucher.."
                        value={name}
                        onIonChange={(e) => {
                          setName(e.detail.value!);
                        }}
                      >
                        {" "}
                      </IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        รายละเอียด
                      </IonLabel>
                      <IonInput
                        value={detail}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;รายละเอียด"
                        onIonChange={(e) => setDetail(e.detail.value!)}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        จำนวน KGO ที่ใช้แลก
                      </IonLabel>
                      <IonInput
                        type="number"
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ขั้นต่ำ 0.01"
                        value={amount}
                        onIonChange={(e) => {
                          {
                            setAmount(e.detail.value!);
                          }
                        }}
                        id={"amount"}
                      >
                        {" "}
                      </IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        จำกัดสิทธิ{" "}
                        <IonText color={"warning"}>
                          (กรณีไม่จำกัดระบุเป็น 0)
                        </IonText>
                      </IonLabel>
                      <IonInput
                        type={"number"}
                        value={limitUseCount}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ไม่ระบุจะใช้ได้ไม่จำกัด"
                        onIonChange={(e) =>
                          setLimitUseCount(parseInt(e.detail.value!))
                        }
                      ></IonInput>
                    </IonItem>

                    <IonItem>
                      <IonRow
                        style={{
                          width: "100%",
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        <IonCol size={"6"}>
                          <IonLabel position="stacked" className="label-form">
                            สินค้าต้องจัดส่ง
                          </IonLabel>
                          <IonText style={{ margin: "auto" }}>
                            <IonToggle
                              style={{ verticalAlign: "middle" }}
                              color={"success"}
                              checked={isDelivery}
                              onIonChange={(e) =>
                                setIsDelivery(e.detail.checked)
                              }
                            />{" "}
                            <span style={{ fontSize: 12 }}>
                              {" "}
                              {isDelivery ? "ต้องจัดส่ง" : "ไม่ต้องจัดส่ง"}
                            </span>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                    <IonItem>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-betweens",
                          gap: 8,
                          width: "100%",
                          margin: "8px 0",
                        }}
                      >
                        {!isSelectEnd && (
                          <div
                            style={{
                              position: "relative",
                              // width:"100%"
                            }}
                          >
                            <p>
                              วันเริ่มต้นใช้ Voucher
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                fontSize: 12,
                                color: "black",
                              }}
                              onClick={() => setIsSelectStart(!isSelectStart)}
                            >
                              {convertDate(startAt) ?? "กรุณาเลือกวัน"}
                            </button>
                            {isSelectStart && (
                              <div>
                                <IonDatetime
                                  onIonChange={(value) => (
                                    // @ts-ignore
                                    setStartAt(value.detail.value),
                                    setIsSelectStart(false)
                                  )}
                                  presentation="date"
                                ></IonDatetime>
                              </div>
                            )}
                            <div
                              style={{
                                height: 0.5,
                                width: "100%",
                                backgroundColor: "#c8c7cc",
                              }}
                            ></div>
                          </div>
                        )}

                        {!isSelectStart && (
                          <div style={{ position: "relative" }}>
                            <p>
                              วันสิ้นสุดใช้ Voucher
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                fontSize: 12,
                                color: "black",
                              }}
                              onClick={() => setIsSelectEnd(!isSelectStart)}
                            >
                              {convertDate(endAt) ?? "กรุณาเลือกวัน"}
                            </button>
                            {isSelectEnd && (
                              <div>
                                <IonDatetime
                                  onIonChange={(value) => (
                                    // @ts-ignore
                                    setEndAt(value.detail.value),
                                    setIsSelectEnd(false)
                                  )}
                                  // presentation="date"
                                ></IonDatetime>
                              </div>
                            )}
                            <div
                              style={{
                                height: 0.5,
                                width: "100%",
                                backgroundColor: "#c8c7cc",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </IonItem>

                    <IonItem>
                      <IonRow
                        style={{
                          width: "100%",
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        <IonCol size={"6"}>
                          <IonLabel position="stacked" className="label-form">
                            สถานะ Voucher
                          </IonLabel>
                          <IonText style={{ margin: "auto" }}>
                            <IonToggle
                              style={{ verticalAlign: "middle" }}
                              color={"success"}
                              checked={status}
                              onIonChange={(e) => setStatus(e.detail.checked)}
                            />{" "}
                            เปิดใช้งาน
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"} style={{ marginTop: 5 }}>
            <IonCol size={"12"}>
              <IonButton
                expand="block"
                className={"kgo-btn-store"}
                onClick={(e) => {
                  saveVoucherList(action).catch();
                }}
                disabled={disableBtn}
              >
                บันทึก
              </IonButton>
            </IonCol>
            {action == "edit" && (
              <IonCol>
                <IonButton
                  expand="block"
                  color={"danger"}
                  onClick={(e) => {
                    saveVoucherList("delete").catch();
                  }}
                  disabled={disableBtn}
                >
                  ลบ
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </div>
        {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default RedeemVoucherCreate;
