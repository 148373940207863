import {
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";

const NotFound: React.FC<RouteComponentProps> = ({history}) => {


    return (
        <IonPage>
            <IonHeader>
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className='ion-no-padding'>
                        <IonCol size="12" className="ion-text-center ion-justify-content-center" style={{marginTop:100}}>
                            <h1>Not Found</h1>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
        </IonPage>
);
};

export default NotFound;
