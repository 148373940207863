import React from "react";
import "./Modal.css";
// @ts-ignore
const NotiModal = ({ show, onClose, notification }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>{notification.subject}</h3>
        <p>{notification.message}</p>
        <p>
          <strong>ส่งเมื่อ:</strong> {notification.sentAt}
        </p>
        <button className="close-btn" onClick={onClose}>
          ปิดหน้าต่าง
        </button>
      </div>
    </div>
  );
};

export default NotiModal;
