import {
  IonCol,
  IonRow,
  IonIcon,
  IonText,
  IonLabel,
  IonRouterLink,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DateTime } from "luxon";
import {
  arrowDownCircle,
  arrowUpCircle,
  checkmarkCircle,
} from "ionicons/icons";
import TransactionDetail from "./TransactionDetail";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
import { downloadTransactionHistory } from "../services/transaction/index";

interface Props {
  mode: string;
  walletAddress?: string;
  subWalletAddress?: string;
}

export default function TransactionList(props: Props) {
  const appContext = useContext(AppContext);

  const [dataTran, setDataTran] = useState({});
  const [isOpenTran, setIsOpenTran] = useState(false);
  const [transaction, setTransaction] = useState<any>([]);
  const [tempTransaction, setTempTransaction] = useState<any>([]);
  const [limitData, setLimitData] = useState(20);
  const [countAll, setCountAll] = useState(0);
  const [hideMoreData, setHideMoreData] = useState(true);
  const [hideLoading, setHideLoading] = useState(false);

  const [currentYear, setCurrentYear] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("all");

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // getMonth() returns month from 0-11, so we add 1
  const day = currentDate.getDate();

  const today = `${year}-${month}-${day}`;

  const getFullDate = (month: number, year: number): string => {
    if (month == 0) return `2022-01-01`;
    let day = 31; // Default to 31 days

    // Check for months with fewer than 31 days
    if (month === 4 || month === 6 || month === 9 || month === 11) {
      day = 30;
    } else if (month === 2) {
      // February: check for leap year
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        day = 29; // Leap year
      } else {
        day = 28; // Non-leap year
      }
    }

    return `${year}-${month}-1`;
  };
  const getTransaction = useCallback(() => {
    if (props?.walletAddress != null) {
      appContext.apiService
        .getTransaction(
          props.walletAddress,
          limitData,
          0,
          props.subWalletAddress
        )
        .then((response: any) => {
          setHideLoading(true);
          if (response?.success && response.data) {
            if (props.mode == "component") {
              setTransaction(response.data.slice(0, 10));
            } else {
              setTransaction(response.data);
              setTempTransaction(response.data);
            }
            setCountAll(response.count);
            if (response.count > limitData) {
              setHideMoreData(false);
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [appContext.profile]);

  useEffect(() => {
    if (appContext.address) {
      setHideLoading(false);
      getTransaction();
    } else {
      appContext.getProfile();
    }
  }, [appContext.profile, appContext.notify]);

  useEffect(() => {
    if (currentStatus != "all" || currentMonth != 0 || currentYear != 0) {
      const filterTran = tempTransaction.filter((e: any) => {
        let status = true,
          month = true,
          year = true;
        if (currentStatus != "all")
          status = e.transactionStatus == currentStatus;
        if (currentMonth != 0)
          month = new Date(e.createdAt).getMonth() == currentMonth - 1;
        if (currentYear != 0)
          year = new Date(e.createdAt).getFullYear() == currentYear;
        return status && month && year;
      });
      console.log("setFilter");
      setTransaction(filterTran);
    }
  }, [currentYear, currentMonth, currentStatus]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const convertTextTh = (text: string) => {
    switch (text) {
      case "Send":
        text = "ส่งให้";
        break;
      case "Receive":
        text = "รับจาก";
        break;
      case "Bonus receive":
        text = "รับโบนัส";
        break;
    }

    return text;
  };

  const convertStatusTh = (text: string) => {
    switch (text) {
      case "pending":
        return (
          <span
            style={{
              background: "#4A75CB",
              borderRadius: 24,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 10,
              width: "70px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#FFFFFF" }}>กำลังดำเนินการ</span>
          </span>
        );
      case "success":
        return (
          <span
            style={{
              background: "#45B865",
              borderRadius: 24,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 10,
              width: "70px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#FFFFFF" }}>เสร็จสิ้น</span>
          </span>
        );
      case "error": //#848484
        return (
          <span
            style={{
              background: "#848484",
              borderRadius: 24,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 10,
              width: "70px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#FFFFFF" }}>ไม่สำเร็จ</span>
          </span>
        );
      case "":
        return (
          <span
            style={{
              background: "#FD9E18",
              borderRadius: 24,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 10,
              width: "70px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#FFFFFF" }}>เสร็จสิ้น</span>
          </span>
        );
    }

    return text;
  };

  async function filter(type: string, text: string) {
    if (props.walletAddress != null) {
      await appContext.apiService
        .getTransaction(
          props.walletAddress,
          limitData,
          0,
          props.subWalletAddress
        )
        .then((response: any) => {
          if (response?.success && response.data && response.data.length > 0) {
            setTransaction([...response.data]);
            setTempTransaction([...response.data]);
            setCountAll(response.count);
            setHideMoreData(true);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
    setHideLoading(true);
    if (type == "year") {
      setCurrentYear(parseInt(text));
    }
    if (type == "month") {
      setCurrentMonth(parseInt(text));
    }
    if (type == "status") {
      setCurrentStatus(text);
    }
  }

  async function loadMore() {
    console.log("load");

    let tmpLimitData = limitData;
    console.log("re", countAll);
    let skip = transaction.length;

    if (props.walletAddress != null) {
      await appContext.apiService
        .getTransaction(
          props.walletAddress,
          limitData,
          skip,
          props.subWalletAddress
        )
        .then((response: any) => {
          // console.log('getTransaction');
          if (response?.success && response.data) {
            if (response.data.length > 0) {
              let newData: any[];
              newData = [];
              for (const val of transaction) {
                newData.push(val);
              }
              for (const val of response.data) {
                newData.push(val);
              }
              setTransaction(newData);
              setTempTransaction(newData);
              if (response.data.length < limitData) {
                setHideMoreData(true);
              }
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }

  const handleDownloadTransaction = async () => {
    const targetDate = getFullDate(currentMonth, currentYear);
    // call API
    const data = await downloadTransactionHistory("", targetDate, today);

    console.log("Download started", data);
  };

  return (
    <IonRow>
      {props.mode == "component" ? (
        <IonCol size="12">
          <IonRow style={{ display: "flex", alignItems: "center" }}>
            <IonText
              style={{ fontSize: 18, color: "#242424", fontWeight: 600 }}
            >
              {" "}
              รายการล่าสุด
            </IonText>

            <IonText style={{ fontSize: 12, marginLeft: "auto" }}>
              <IonRouterLink
                style={{ color: "#242424", fontWeight: 400 }}
                routerLink={
                  props.subWalletAddress && props.subWalletAddress != ""
                    ? "/transaction"
                    : "/exchange/transaction"
                }
              >
                ดูเพิ่มเติม
              </IonRouterLink>
            </IonText>
          </IonRow>
        </IonCol>
      ) : (
        <IonCol size={"12"}>
          <div className={"filterTran"}>
            <span className={"filterTranText"}> ปี </span>
            <span>
              <select
                value={currentYear}
                placeholder={"ปี"}
                onChange={(e) => {
                  setHideLoading(false);
                  filter("year", e.target.value).then((r) => {});
                }}
              >
                <option value={0}></option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
              </select>
            </span>
            <span className={"filterTranDad"}> - </span>
            <span className={"filterTranText"}> เดือน </span>
            <span>
              <select
                value={currentMonth}
                onChange={(e) => {
                  setHideLoading(false);
                  filter("month", e.target.value).then((r) => {});
                }}
              >
                <option value={0}></option>
                <option value={1}>มกราคม</option>
                <option value={2}>กุมภาพันธ์</option>
                <option value={3}>มีนาคม</option>
                <option value={4}>เมษายน</option>
                <option value={5}>พฤษภาคม</option>
                <option value={6}>มิถุนายน</option>
                <option value={7}>กรกฎาคม</option>
                <option value={8}>สิงหาคม</option>
                <option value={9}>กันยายน</option>
                <option value={10}>ตุลาคม</option>
                <option value={11}>พฤษจิกายน</option>
                <option value={12}>ธันวาคม</option>
              </select>
            </span>
            <span className={"filterTranDad"}> - </span>
            <span className={"filterTranText"}> สถานะ </span>
            <span>
              <select
                value={currentStatus}
                onChange={(e) => {
                  setHideLoading(false);
                  filter("status", e.target.value).then((r) => {});
                }}
              >
                <option value={"all"}>ทั้งหมด</option>
                <option value={"success"}>เสร็จสิ้น</option>
                <option value={"pending"}>กำลังดำเนินการ</option>
                <option value={"error"}>ไม่สำเร็จ</option>
              </select>
            </span>
            <button
              onClick={handleDownloadTransaction}
              style={{
                color: "rgba(255, 165, 0)",
                padding: 8,
                borderRadius: 8,
                marginTop: 4,
                backgroundColor: "#fff",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              ดาวน์โหลดประวัติ
            </button>
          </div>
        </IonCol>
      )}
      <IonCol size="12" hidden={hideLoading} className={"ion-text-center"}>
        <IonSpinner name="bubbles" />
      </IonCol>
      <IonCol size="12">
        {hideLoading && transaction.length == 0 ? (
          <h1 className="ion-text-center" style={{ marginTop: 42 }}>
            ไม่พบข้อมูล...
          </h1>
        ) : (
          transaction.map((r: any, i: number) => (
            <IonRow
              style={{
                filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                padding: 5,
              }}
              key={i}
              className={"kgo-row-tran"}
              onClick={(e: any) => {
                console.log("r", r);
                setDataTran(r);
                setIsOpenTran(true);
              }}
            >
              <IonCol size="2" className="ion-text-center">
                {r.amount ? (
                  r.status == "IN" ? (
                    <IonIcon
                      className={"kgo-icon-tran text-send-color"}
                      style={{ fontSize: 36 }}
                      icon={arrowDownCircle}
                    />
                  ) : (
                    <IonIcon
                      style={{ fontSize: 36 }}
                      className={"kgo-icon-tran text-receive-color"}
                      icon={arrowUpCircle}
                    />
                  )
                ) : (
                  <IonIcon className={"kgo-icon-tran"} icon={checkmarkCircle} />
                )}
              </IonCol>
              <IonCol size="5">
                <IonText
                  className={"kgo-text-tran"}
                  style={{ display: "grid", marginTop: 10 }}
                >
                  <IonText style={{ fontSize: 13, fontWeight: 600 }}>
                    {(r.name = convertTextTh(r.name))}{" "}
                    {r.profile
                      ? r.status == "IN"
                        ? r.profile.fromName
                        : r.profile.toName
                      : r.targetAddress &&
                        r.targetAddress.substr(0, 3) +
                          "..." +
                          r.targetAddress.substr(-3)}
                  </IonText>
                  <IonText style={{ fontSize: 12, color: "#848484" }}>
                    {DateTime.fromMillis(r.createdAt)
                      .setLocale("th")
                      .toFormat("d MMM yy T")}{" "}
                    น.
                  </IonText>
                </IonText>
              </IonCol>
              <IonCol size="5" style={{ paddingRight: 19, textAlign: "end" }}>
                {convertStatusTh(r?.transactionStatus)}

                <div className={"kgo-text-tran"}>
                  {r.amount ? (
                    r.status == "IN" ? (
                      <IonText
                        style={{
                          color: "#00CA38",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        <img src={dollar} className={"coin-dollar"} />
                        {"+" +
                          " " +
                          numberWithCommas(parseFloat(r.amount).toString())}
                      </IonText>
                    ) : (
                      <IonText
                        style={{
                          color: "#FF2121",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        <img src={dollar} className={"coin-dollar"} />
                        {"-" +
                          " " +
                          numberWithCommas(parseFloat(r.amount).toString())}
                      </IonText>
                    )
                  ) : (
                    "-"
                  )}
                  <IonText
                    style={{ marginLeft: 3, color: "#848484", fontSize: 10 }}
                  >
                    KGO
                  </IonText>
                </div>
              </IonCol>
            </IonRow>
          ))
        )}
      </IonCol>
      {props.mode == "page" ? (
        <IonCol size="12" className={"ion-text-center"}>
          <IonButton
            hidden={hideMoreData}
            color={"medium"}
            onClick={(e: any) => {
              loadMore().then();
            }}
          >
            Load More..
          </IonButton>
        </IonCol>
      ) : null}
      <IonModal
        isOpen={isOpenTran}
        initialBreakpoint={0.6}
        onDidDismiss={() => {
          setIsOpenTran(false);
        }}
      >
        <TransactionDetail
          closeModal={setIsOpenTran}
          isOpen={isOpenTran}
          data={dataTran}
        />
      </IonModal>
    </IonRow>
  );
}
