// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDcQb16Rpm1vhK8ExTmpKpqJd9tXcbKchY",
    authDomain: "kgo-wallet.firebaseapp.com",
    projectId: "kgo-wallet",
    storageBucket: "kgo-wallet.appspot.com",
    messagingSenderId: "684505941929",
    appId: "1:684505941929:web:0f4cb1907a78579feca6b8",
    measurementId: "G-Z0H9YFY5T9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default getFirestore();