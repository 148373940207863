import React from "react";
import "./ReviewCard.css";
// @ts-ignore

const ReviewCard = ({ review }) => {
  const { reviewer, text, date, score } = review;

  // @ts-ignore
  const formatDateThai = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthsThai = [
      "มค",
      "กพ",
      "มีค",
      "เมย",
      "พค",
      "มิย",
      "กค",
      "สค",
      "กย",
      "ตค",
      "พย",
      "ธค",
    ];
    const month = monthsThai[date.getMonth()];
    const year = date.getFullYear() + 543; // Convert to Buddhist year

    return `${day} ${month} ${year}`;
  };

  const formattedDate = formatDateThai(date);
  const emojis = [
    { emoji: "😏", text: "ก็ดีอยู่", score: 2 },
    { emoji: "😊", text: "ไม่แย่นะ", score: 3 },
    { emoji: "🥰", text: "ดีมากเลย", score: 4 },
    { emoji: "🤩", text: "มันว้าวสุด", score: 5 },
  ];
  const renderScoreByEmoji = (score: number) => {
    const result = emojis.find((item) => item.score === score);
    return result ? `${result.emoji} ${result.text}` : " 🤔 แย่ๆ";
  };
  return (
    <div className="review-card">
      <div className="review-header">
        <div>
          <img
            src={reviewer.image}
            alt={reviewer.name}
            className="reviewer-image"
          />
          <h3>{reviewer.name}</h3>
        </div>
        <span className="review-score">
          ความพอใจ: {renderScoreByEmoji(score)}
        </span>
      </div>

      <p className="review-text">{text}</p>
      <span className="review-date">{formattedDate}</span>
    </div>
  );
};

export default ReviewCard;
