import {
    IonButton,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow, IonText
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";

const Main: React.FC<RouteComponentProps> = ({history}) => {
    const useQueryParams = () => {
        const result: Record<string, string> = {};
        new URLSearchParams(window.location.search).forEach((value, key) => {
            result[key] = value;
        });
        return result;
    }

    const { referral } = useQueryParams();
    useEffect(() => {
        async function redirectPage() {
            await setTimeout(() => {
                if(localStorage.getItem('accessToken')){
                    history.push('/main');
                } else {
                    // history.push('/term-service');
                }
            }, 1000);
        }
        redirectPage().then(r => {});
        if(referral){
            console.log('referral', referral)
            localStorage.setItem('referral', referral);
        }
    },[]);

    return (
        <IonPage>
            <IonContent>
                <div className="app-container">
                    <IonRow className='ion-no-padding ion-text-center'>
                        <IonCol size={"12"}>
                            <img src={process.env.PUBLIC_URL + '/assets/icon/KGO-Logo.png'} style={{maxHeight:400,marginTop:80}} />
                        </IonCol>

                        <IonCol size={"12"} style={{marginTop:25, fontSize:18, lineHeight:"27px"}}>
                            <IonText>
                                เชื่อม โลกเสมือน กับ โลกความจริง
                                <div>
                                    เข้าด้วยกันด้วยเหรียญ KGO
                                </div>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonButton expand={"block"} className={"kgo-btn"}  routerLink="/term-service" >เริ่มต้นใช้งาน</IonButton>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
        </IonPage>
);
};

export default Main;
