import React, { useState } from "react";
import "./NotificationCard.css";
import NotiModal from "../Modal/NotiModal";
import { markNotificationAsRead } from "../../services/notification";

const NotificationCard = ({ data, onClose }) => {
  const { image, isRead, message, sentAt, subject, username, _id } = data;
  const [showModal, setShowModal] = useState(false);

  const formatDateThai = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthsThai = [
      "มค",
      "กพ",
      "มีค",
      "เมย",
      "พค",
      "มิย",
      "กค",
      "สค",
      "กย",
      "ตค",
      "พย",
      "ธค",
    ];
    const month = monthsThai[date.getMonth()];
    const year = date.getFullYear() + 543; // Convert to Buddhist year

    return `${day} ${month} ${year}`;
  };
  const formattedDate = formatDateThai(sentAt);

  const handleCardClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = async () => {
    setShowModal(false);

    try {
      // Make an API call to mark the notification as read
      await markNotificationAsRead(_id);

      // Trigger onClose callback to update the UI
      onClose();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <>
      <div
        className={`notification-card ${isRead ? "read" : "unread"}`}
        onClick={handleCardClick}
      >
        {image && (
          <img src={image} alt="Notification" className="notification-image" />
        )}
        <div className="notification-content">
          <div className="notification-header">
            <h3>{subject}</h3>
          </div>
          <p>{message.replace("{username}", username)}</p>
          <span className="notification-date">{formattedDate}</span>
        </div>
      </div>

      <NotiModal
        show={showModal}
        onClose={handleCloseModal}
        notification={{ subject, message, sentAt: formattedDate }}
      />
    </>
  );
};

export default NotificationCard;
