import React, { useContext, useEffect, useState } from "react";
import KgoHeader from "../components/Header/kgo-header";
import { IonCard, IonCardContent, IonCol, IonRow, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { AppContext } from "../context/state";
// @ts-ignore
import kgoLogoWhite from "../assets/kgo-white.svg";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import Banner from "../components/Banner/Banner";
// @ts-ignore
import redemption from "../assets/5378862.png";
// @ts-ignore
import titleImg from "../assets/5409459.png";
import NewPopUp from "../components/UpdatePopUp/NewPopUp";
import TransactionList from "../components/TransactionList";
export const HomePage = () => {
  const checkUpdateFeature = localStorage.getItem("UpdateNote");
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [KGOBalance, setKGOBalance] = useState();
  const appContext = useContext(AppContext);
  const history = useHistory();
  const getStoreProfile = () => {
    if (appContext.apiService) {
      appContext.getProfile();
    } else {
      console.error("apiService not init!");
    }
  };
  useEffect(() => {
    if (appContext.kgoBalance) {
      // @ts-ignore
      setKGOBalance(appContext.kgoBalance);
      setIsLoading(false);
    }
  }, [appContext.kgoBalance]);
  useEffect(() => {
    getStoreProfile();
  }, []);
  useEffect(() => {
    if (!checkUpdateFeature) {
      setIsShowUpdate(true);
    }
  }, [checkUpdateFeature]);
  const handlePress = () => {
    history.push("LuckyDraw");
  };

  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="layout-screen" style={{ height: "100%" }}>
      {isShowUpdate && (
        <NewPopUp setIsShowUpdate={() => setIsShowUpdate(false)} />
      )}
      <KgoHeader
        headerChildren={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            KGO Business
            <div>
              <button
                slot="end"
                onClick={(e: any) => {
                  history.push("/notification");
                }}
                style={{
                  marginRight: 10,
                  fontSize: 20,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                }}
              >
                <svg
                  fill="#fff"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 459.334 459.334"
                  // xml:space="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <g>
                          <path d="M175.216,404.514c-0.001,0.12-0.009,0.239-0.009,0.359c0,30.078,24.383,54.461,54.461,54.461 s54.461-24.383,54.461-54.461c0-0.12-0.008-0.239-0.009-0.359H175.216z"></path>
                          <path d="M403.549,336.438l-49.015-72.002c0-22.041,0-75.898,0-89.83c0-60.581-43.144-111.079-100.381-122.459V24.485 C254.152,10.963,243.19,0,229.667,0s-24.485,10.963-24.485,24.485v27.663c-57.237,11.381-100.381,61.879-100.381,122.459 c0,23.716,0,76.084,0,89.83l-49.015,72.002c-5.163,7.584-5.709,17.401-1.419,25.511c4.29,8.11,12.712,13.182,21.887,13.182 H383.08c9.175,0,17.597-5.073,21.887-13.182C409.258,353.839,408.711,344.022,403.549,336.438z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
              <button
                slot="end"
                onClick={(e: any) => {
                  history.push("/profile");
                }}
                style={{
                  marginRight: 10,
                  fontSize: 20,
                  cursor: "pointer",
                  backgroundColor: "transparent",
                }}
              >
                <svg
                  viewBox="0 0 1024 1024"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" />
                </svg>
              </button>
            </div>
          </div>
        }
        withFooter
      />
      <div
        className="make-it-half"
        style={{ height: "100%", padding: 16, overflow: "scroll" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <IonCard className={"kgo-card kgo-card-img"}>
            <IonCardContent>
              <IonRow style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={kgoLogoWhite}
                  style={{ width: 16.8, marginRight: 5 }}
                />
                <IonText style={{ fontSize: 14, color: "#FFFFFF" }}>
                  {" "}
                  {appContext.profile.businessName
                    ? appContext.profile.businessName
                    : appContext.profile.businessPhoneNumber}
                </IonText>

                <IonText style={{ fontSize: 12, marginLeft: "auto" }}>
                  <div
                    style={{
                      background: "rgba(255, 255, 255, 0.42)",
                      borderRadius: 24,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div style={{ color: "#414143" }}>
                      {appContext.address
                        ? appContext.address.substr(0, 4) +
                          "..." +
                          appContext.address.substr(-4)
                        : ""}
                    </div>
                  </div>
                </IonText>
              </IonRow>
              <IonRow
                className="ion-text-center"
                style={{ margin: "18px 0 20px" }}
              >
                <IonCol size="12">
                  <IonText
                    color={"light"}
                    style={{ fontSize: 14, marginRight: 8 }}
                  >
                    KGO ของร้าน{" "}
                  </IonText>
                  <IonText
                    color={"light"}
                    style={{ fontSize: 36, fontWeight: 600 }}
                  >
                    {isLoading
                      ? "...กำลังโหลด"
                      : KGOBalance
                      ? // @ts-ignore
                        numberWithCommas(KGOBalance.toFixed(2))
                      : "0"}{" "}
                    <IonText
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        verticalAlign: "middle",
                      }}
                      color={"light"}
                    >
                      <img
                        src={dollar}
                        style={{ width: 24, verticalAlign: "sub" }}
                      />
                    </IonText>
                  </IonText>
                </IonCol>
                {/* ซ่อน KGO สะสม */}
                {/* <IonCol size="12" style={{ borderTop: "1px solid lightgray" }}>
                  <IonText
                    color={"light"}
                    style={{ fontSize: 14, marginRight: 8 }}
                  >
                    KGO สะสม
                  </IonText>
                  <IonText
                    color={"light"}
                    style={{ fontSize: 24, fontWeight: 600 }}
                  >
                    {appContext.kgoReceiveBalance
                      ? numberWithCommas(
                          appContext.kgoReceiveBalance.toFixed(2)
                        )
                      : "0"}
                    <IonText
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        verticalAlign: "middle",
                      }}
                      color={"light"}
                    >
                      <img src={dollar} className={"coin-dollar"} />
                    </IonText>
                  </IonText>
                </IonCol> */}
              </IonRow>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 8,
                  color: "white",
                  zIndex: 99,
                }}
              >
                <button
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => history.push("/faq")}
                >
                  คำถามที่พบบ่อย?
                </button>
              </div> */}
            </IonCardContent>
          </IonCard>
          <Banner />
          <div
            style={{
              color: "orange",
              fontSize: "16",
              fontWeight: "bold",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 32,
              marginTop: 16,
            }}
          >
            <div
              onClick={handlePress}
              style={{
                borderRadius: 8,
                backgroundColor: "orange",
                width: 320,
                height: 160,
                cursor: "pointer",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 8,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Lucky Draw
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    ลุ้นรับของรางวัลสุดพิเศษ!
                  </span>
                </div>
                <div style={{ padding: 0 }}>
                  {/* Replace with your SVG component */}
                  <img
                    src={redemption}
                    style={{ height: "auto", width: 120 }}
                  />
                </div>
              </div>
            </div>

            <div
              onClick={() => history.push("/exchange")}
              style={{
                borderRadius: 8,
                backgroundColor: "#FFA412",
                width: 320,
                height: 160,
                cursor: "pointer",
                color: "white",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 8,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    ซื้อ Ads โฆษณา
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    ด้วย KGO Token
                  </span>
                </div>
                <div style={{ padding: 8 }}>
                  {/* Replace with your SVG component */}
                  <img src={titleImg} style={{ height: "auto", width: 120 }} />
                </div>
              </div>
            </div>
          </div>
          <IonRow
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {appContext.address ? (
              <IonCol>
                <IonCard className={"kgo-card kgo-card-tran-store"}>
                  <IonCardContent>
                    {appContext.address && (
                      <TransactionList
                        walletAddress={appContext.address}
                        subWalletAddress={appContext.pointAddress}
                        mode={"component"}
                      />
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            ) : (
              <div style={{ marginTop: 24 }}>...กำลังโหลดรายการล่าสุด</div>
            )}
          </IonRow>
        </div>
      </div>
    </div>
  );
};
