const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");

export const fetchUserVoucher = async () => {
  try {
    const response = await fetch(`${API}/user-vouchers`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchUserVoucherById = async (voucherId: any) => {
  try {
    const response = await fetch(`${API}user-vouchers/${voucherId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchUserVoucherByShopId = async (shopId: any) => {
  try {
    const response = await fetch(`${API}user-vouchers/shop/${shopId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const createUserVoucher = async (payload: any) => {
  try {
    const response = await fetch(`${API}/user-vouchers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Assuming JSON data is sent
        // Add any additional headers if required
      },
      // Include data in the request body if necessary
      body: JSON.stringify({
        payload,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchShopRewards = async () => {
  try {
    const response = await fetch(`${API}shop-reward`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchShopRewardsHistory = async (shopId: any) => {
  try {
    const response = await fetch(
      `${API}shop-reward/logs/history/shop/${shopId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const redeemRewardVoucher = async (shopRewardId: any, shopId: any) => {
  console.log("shopRewardId", shopRewardId);
  try {
    const response = await fetch(`${API}shop-reward/redeem`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Assuming JSON data is sent
        Authorization: `Bearer ${accessToken}`,
      },
      // Include data in the request body if necessary
      body: JSON.stringify(shopRewardId),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchMyRewards = async (shopId: string) => {
  try {
    const response = await fetch(`${API}shop-reward/logs/shop/${shopId}`);
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const patchRewardItemById = async (
  prizeId: any,
  payload: any,
  shortForm: boolean
) => {
  try {
    const response = await fetch(`${API}shop-reward/items/${prizeId}`, {
      method: "PATCH", // Specify PATCH method
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload, // Include the data to be updated
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
