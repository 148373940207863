import React, { useRef, useState } from "react";
// @ts-ignore
import slot from "../../assets/slot/slot-image.png";
const Slot = ({
  rewards,
  spin,
  balance,
}: {
  rewards: any;
  spin: () => void;
  balance: number;
}) => {
  const [fruit1, setFruit1] = useState("🍒");
  const [fruit2, setFruit2] = useState("🍒");
  const [fruit3, setFruit3] = useState("🍒");
  const [rolling, setRolling] = useState(false);
  let slotRef = [useRef(null), useRef(null), useRef(null)];
  const fruits = [...rewards, ...rewards, ...rewards, ...rewards, ...rewards];

  const handleRoll = () => {
    roll();
    spin();
  };

  // to trigger roolling and maintain state
  const roll = () => {
    setRolling(true);

    // looping through all 3 slots to start rolling
    let randomOption = Math.floor(Math.random() * fruits.length);

    slotRef.forEach((slot, i) => {
      // this will trigger rolling effect
      const selected = triggerSlotRotation(slot.current, randomOption, 9 + i);
      if (i + 1 == 1) setFruit1(selected);
      else if (i + 1 == 2) setFruit2(selected);
      else {
        setFruit3(selected);
        setTimeout(() => {
          setRolling(false);
        }, 3000);
      }
    });
  };

  // @ts-ignore
  const triggerSlotRotation = (ref, label, speed) => {
    // @ts-ignore
    function setTop(top) {
      ref.style.top = `${top}px`;
    }
    setTop(0);

    console.log(speed, "speed");
    const options = ref.children;
    // let randomOption = Math.floor(Math.random() * fruits.length);
    let randomOption = label;

    let initialIndex = 0;
    const chosenOption = options[randomOption];
    const finalPosition = -chosenOption.offsetTop + 2;

    // Adjust randomOption to start from index 0
    randomOption = (randomOption + initialIndex) % fruits.length;

    let currentPosition = 0;
    let timer = setInterval(() => {
      setTop(currentPosition);
      currentPosition -= 5; // Move up 5 pixels each interval (adjust as needed for speed)
      if (currentPosition <= finalPosition) {
        clearInterval(timer);
        setTop(finalPosition);
      }
    }, speed);

    return fruits[randomOption];
  };

  return (
    <div
      id="slot-container"
      style={{ position: "relative", width: 440, height: 440 }}
      className="SlotMachine"
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 226,
            right: 136,
            zIndex: 0,
            height: 48,
            overflow: "hidden",
          }}
        >
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[0]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      <img
                        style={{ objectFit: "cover", width: 40 }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[1]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      <img
                        style={{ objectFit: "cover", width: 40 }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[2]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      {/* {console.log("friut", fruit)} */}
                      <img
                        style={{ objectFit: "cover", width: 40 }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>

      <button
        style={{
          position: "absolute",
          zIndex: 99,
          bottom: 72,
          left: 126,
          backgroundColor: balance < 1 ? "gray" : "orange",
          fontSize: 16,
          fontWeight: "bold",
          color: "white",
          padding: "10px 32px",
          borderRadius: 8,
        }}
        //   @ts-ignore
        onClick={handleRoll}
        disabled={rolling || balance < 1}
      >
        {rolling ? "กำลังสุ่มรางวัล..." : "สุ่มรางวัล 1 iKGO"}
      </button>
      <img src={slot} style={{ zIndex: 99 }} />
    </div>
  );
};

export default Slot;
