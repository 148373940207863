import React from "react";
import "./Pagination.css";
// @ts-ignore
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // @ts-ignore
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return; // Prevent invalid pages
    onPageChange(page);
  };

  return (
    <div className="pagination">
      <button
        style={{ color: "black" }}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          className={currentPage === index + 1 ? "active" : ""}
        >
          {index + 1}
        </button>
      ))}
      <button
        style={{ color: "black" }}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
