const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");

export const fetchPrize = async () => {
  try {
    const response = await fetch(`${API}shop-prize`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export const spinSlotAPI = async () => {
  try {
    const response = await fetch(`${API}shop-spinWheel/spin`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return { data: data, response: response };
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
