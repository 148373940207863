import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline, cart } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
// @ts-ignore
import nft from "../assets/nft.png";
// @ts-ignore
import bag from "../assets/bag.png";
import TransactionList from "../components/TransactionList";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import titleImg from "../assets/5409459.png";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import redemption from "../assets/5378862.png";
import RewardCards from "../components/Cards/RewardCards";
import AlertModal from "../components/Modal/AlertModal";
// @ts-ignore
import {
  fetchShopRewards,
  redeemRewardVoucher,
} from "../services/voucher/index";
const Exchange: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [shopId, setShopId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reward, setReward] = useState();
  const [selectVoucher, setSelectVoucher] = useState();
  const [modalData, setModalData] = useState();
  const getRewards = async () => {
    const res = await fetchShopRewards();
    setReward(res);
  };

  const selectVoucherHandle = (data: any) => {
    setIsModalOpen(true);
    setSelectVoucher(data);
    setModalData(data);
  };
  const handleModalVoucher = async () => {
    const data = await redeemRewardVoucher({
      shopRewardId: selectVoucher?.rewardId,
      shopId: shopId,
    });
    if (data) {
      history.push("/MyRewards");
    } else {
      alert(data);
    }
    setIsModalOpen(false);
  };

  const getStoreProfile = () => {
    console.log("getStoreProfile");
    if (appContext.apiService) {
      console.log(appContext.profile.businessId);
      setShopId(appContext.profile.businessId);
    } else {
      console.error("apiService not init!");
    }
  };

  useEffect(() => {
    getRewards();
  }, []);
  useEffect(() => {
    getStoreProfile();
  }, [appContext.notify]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/"
            />
          </IonButtons>
          <IonTitle>แลกของรางวัล</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <AlertModal
            data={modalData}
            isOpen={isModalOpen}
            setIsOpen={() => setIsModalOpen(false)}
            // @ts-ignore
            onClickHandle={handleModalVoucher}
          />
          <div style={{ marginTop: 4, paddingLeft: 12, paddingRight: 12 }}>
            <IonRow className={"ion-text-start"}>
              <IonCol size={"12"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    คุณมี KGO
                    <span
                      style={{
                        fontSize: 14,
                        color: "#FFFFFF",
                        background: "linear-gradient(#FF6E07, #FFA319)",
                        borderRadius: 24,
                        padding: "3px 7px 3px 7px",
                        marginTop: 12,
                        marginLeft: 5,
                      }}
                    >
                      <img src={dollar} className={"coin-dollar"} />
                      {appContext.kgoBalance
                        ? numberWithCommas(appContext.kgoBalance.toFixed(2))
                        : "0"}
                      &nbsp; KGO
                    </span>
                  </div>
                  <div onClick={() => history.push("/myrewards")}>
                    รายการของฉัน
                  </div>
                </div>
                <h5
                  style={{
                    paddingTop: 0,
                    marginTop: 25,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  นำเหรียญ KGO ของคุณมาแลกของรางวัล
                </h5>
              </IonCol>
            </IonRow>
          </div>
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <p>Rewards ที่ใช้เหรียญ KGO สะสมแลกได้ทั้งหมด</p>
            {/* @ts-ignore */}
            {reward?.map((voucher) => (
              <RewardCards
                data={voucher}
                kgo={appContext.kgoBalance}
                // @ts-ignore
                setSelectVoucher={selectVoucherHandle}
                setIsOpen={() => setIsModalOpen(true)}
              />
            ))}
          </div>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Exchange;
