import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonProgressBar,
  useIonAlert,
  IonLoading,
  IonText,
  IonCardContent,
  IonCard,
  IonButtons,
  IonIcon,
  IonCol,
  IonRow,
  IonLabel,
  IonBackButton,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DateTime } from "luxon";
import {
  arrowBackOutline,
  arrowDownCircle,
  arrowUp,
  arrowUpCircle,
  downloadOutline,
  refreshCircle,
  shareSocialOutline,
} from "ionicons/icons";
import dollar from "../assets/dollar.png";
import { RouteComponentProps, useParams } from "react-router";
import { VoucherModel } from "../models/Data";
import { toPng } from "html-to-image";

var QrCode = require("qrcode.react");

interface ParamTypes {
  voucherId: string;
}

const VoucherGenerate: React.FC<RouteComponentProps> = ({ history }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { voucherId } = useParams<ParamTypes>();
  const appContext = useContext(AppContext);
  const [code, setCode] = useState("");
  const [expireIn, setExpireIn] = useState(0);

  const [runningProcess, setRunningProcess] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataVoucher, setDataVoucher] = useState(new VoucherModel({}));
  const [showBtnShare, setShowBtnShare] = useState(true);

  const [present] = useIonAlert();
  useEffect(() => {
    if (voucherId) {
      const data = appContext.vouchers.find((e) => e.id == parseInt(voucherId));
      console.log("vouchers", data);
      if (data) {
        data.useCount = data.useCount > 0 ? data.useCount : 0;
        setDataVoucher(data);
        if (data.isPublic) {
          setCode(
            JSON.stringify({
              type: "voucher",
              data: {
                businessId: appContext.profile.businessId,
                voucherTemplateId: data.id,
                type: data.type,
                name: data.name,
                amount: data.amount,
                isPublic: true,
                businessName: appContext.profile?.businessName,
              },
            })
          );
        } else {
          generateVoucher(data).catch(console.error);
        }
      } else {
        // appContext.getProfile();
      }
      share().catch();
    }
  }, [refresh, appContext.vouchers]);

  useEffect(() => {
    if (code) {
      const dataCode = JSON.parse(code);
      if (appContext.notify[0].voucher === dataCode.data.code) {
        history.push("/main");
      }
    }
  }, [appContext.notify]);

  async function generateVoucher(_dataVoucher: VoucherModel) {
    if (_dataVoucher.status == "inActive" || _dataVoucher.isPublic) {
      return;
    }
    if (showLoading == true) {
      return;
    }
    setShowLoading(true);
    const data = {
      voucherTemplateId: _dataVoucher.id,
      name: _dataVoucher.name,
      type: _dataVoucher.type,
      amount: _dataVoucher.amount,
      businessName: appContext.profile?.businessName,
      timeExpire: _dataVoucher.timeExpire,
    };
    console.log("data", data);
    await appContext.apiService
      .generateVoucher(data)
      .then((response: any) => {
        if (response.success) {
          const data = response.data;
          setCode(
            JSON.stringify({
              type: "voucher",
              data: {
                code: data.code,
                expireIn: data.expireIn,
                type: data.type,
                name: data.name,
                amount: data.amount,
                businessName: appContext.profile?.businessName,
              },
            })
          );
          setExpireIn(response.data.expireIn);
        } else {
          present({
            cssClass: "ionic-alert",
            header: "Alert",
            message: response.error,
            buttons: ["Ok"],
          });
          // props.closeModal(false);
        }
        setShowLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function downloadQR() {
    const dataUrl = await getDAtaUrl();
    if (dataUrl) {
      console.log("dataUrl", dataUrl);
      const link = document.createElement("a");
      link.download = "qr-voucher-" + dataVoucher.id + ".png";
      link.href = dataUrl;
      link.click();
      document.body.removeChild(link);
    }
  }

  async function generateImage() {
    const sourceCanvas = document.getElementById(
      "qr-code-voucher"
    ) as HTMLCanvasElement;
    const canvas = document.getElementById(
      "qr-code-voucher-share"
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      await ctx.clearRect(0, 0, canvas.width, canvas.height);
      await ctx.drawImage(sourceCanvas, 0, 0, 276, 276);
    }
  }

  const getDAtaUrl = useCallback(async () => {
    await generateImage().catch(console.error);
    if (ref.current) {
      await generateImage().catch(console.error);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await toPng(ref.current, { cacheBust: true });
      await new Promise((resolve) => setTimeout(resolve, 500));
      return toPng(ref.current, { cacheBust: true });
    }
  }, [ref]);

  async function share(active = false) {
    if (navigator.share) {
      setShowBtnShare(false);
      if (active) {
        const dataUrl = await getDAtaUrl();
        if (!dataUrl) return;
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
          new File([blob], "voucher-token.png", {
            type: blob.type,
            lastModified: new Date().getTime(),
          }),
        ];
        const shareData = {
          files: filesArray,
          // title: 'QrCode ' + (dataVoucher.type == 'send' ? 'ร้านส่งให้' : 'ลูกค้าใช้'),
          text:
            "Voucher " +
            (dataVoucher.type == "send" ? "ร้านส่งให้" : "ลูกค้าใช้") +
            " จากร้าน " +
            appContext.profile?.businessName,
          // url: 'https://app.kgotoken.com/',
        };
        await navigator.share(shareData);
      }
    } else {
      setShowBtnShare(true);
    }
  }

  function numberWithCommas(x: any) {
    if (x) {
      x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  }

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store ion-text-center"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonText>รายละเอียด Voucher </IonText>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard className={"kgo-card"} id={"div-qrcode"}>
                <IonCardContent
                  className={"ion-text-center"}
                  style={{ border: "1px solid black", borderRadius: 12 }}
                >
                  <IonRow style={{ color: "#FFFFFF" }}>
                    <IonCol size={"12"}>
                      <IonText
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "black",
                        }}
                      >
                        {appContext.profile?.businessName}
                      </IonText>
                    </IonCol>
                    <IonCol size={"12"} style={{ marginTop: 10 }}>
                      {dataVoucher.status == "active" &&
                      ((dataVoucher.limitUseCount != 0 &&
                        dataVoucher.useCount < dataVoucher.limitUseCount) ||
                        dataVoucher.useCount == null ||
                        dataVoucher.limitUseCount == 0) ? (
                        <QrCode
                          id={"qr-code-voucher"}
                          size={276}
                          value={code}
                          renderAs={"png"}
                          bgColor={"#ffffff"}
                          includeMargin={true}
                          imageSettings={{
                            src:
                              process.env.PUBLIC_URL +
                              "/assets/icon/KGO-Logo.png",
                            x: null,
                            y: null,
                            height: 70,
                            // width: 56,
                            excavate: true,
                          }}
                        />
                      ) : dataVoucher.status == "inActive" ? (
                        <IonText>
                          Voucher นี้ใช้สถานะปิดใช้งาน
                          <div>หากต้องการใช้งานอีกครั้งโปรดแก้ไข Voucher</div>
                        </IonText>
                      ) : (
                        <IonText>
                          Voucher นี้ใช้งานครบตามจำนวนที่จำกัดแล้ว
                          <div>หากต้องการใช้เพิ่มโปรดแก้ไข Voucher</div>
                        </IonText>
                      )}
                    </IonCol>
                    <IonCol size={"12"} style={{ marginTop: 5 }}>
                      {expireIn > 0 && (
                        <IonText>
                          <IonText
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            หมดเวลา{" "}
                            {DateTime.fromMillis(expireIn)
                              .setLocale("th")
                              .toFormat("dd LLL yyyy   TT")}{" "}
                            น.
                          </IonText>
                          <IonIcon
                            onClick={() => setRefresh(!refresh)}
                            style={{
                              fontSize: 25,
                              verticalAlign: "middle",
                              color: "#808080",
                            }}
                            icon={refreshCircle}
                          />
                        </IonText>
                      )}
                      <IonButton
                        size={"small"}
                        className={"kgo-btn kgo-light"}
                        style={{ color: "#222222", verticalAlign: "middle" }}
                        onClick={(e: any) => {
                          downloadQR().catch(console.error);
                        }}
                      >
                        <IonIcon icon={downloadOutline} /> บันทึก
                      </IonButton>
                      <IonButton
                        hidden={showBtnShare}
                        size={"small"}
                        className={"kgo-btn kgo-light"}
                        style={{ color: "#222222", verticalAlign: "middle" }}
                        onClick={(e: any) => {
                          share(true).catch(console.error);
                        }}
                      >
                        <IonIcon icon={shareSocialOutline} /> Share
                      </IonButton>
                    </IonCol>
                    <IonCol size={"12"}>
                      <IonCard className={"kgo-card kgo-light"}>
                        <IonCardContent style={{ color: "#222222" }}>
                          <IonRow>
                            <IonCol
                              size={"12"}
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              {dataVoucher.name}
                            </IonCol>
                            <IonCol
                              size={"12"}
                              style={{
                                margin: "10px 0",
                                borderTop: "1px solid #FF8A19",
                              }}
                            ></IonCol>
                          </IonRow>
                          <IonRow style={{ textAlign: "left" }}>
                            <IonCol
                              size={"12"}
                              style={{ fontSize: 14, fontWeight: 500 }}
                            >
                              รายละเอียด : {dataVoucher.detail}
                            </IonCol>
                            <IonCol size={"12"}>
                              <IonRow style={{ fontSize: 14, fontWeight: 600 }}>
                                <IonCol size={"12"}>
                                  <div style={{ display: "flex" }}>
                                    <div style={{ color: "#414143" }}>
                                      {dataVoucher.type == "send" ? (
                                        <IonIcon
                                          style={{
                                            color: "#00CA38",
                                            verticalAlign: "bottom",
                                            fontSize: 22,
                                          }}
                                          icon={arrowDownCircle}
                                        />
                                      ) : (
                                        <IonIcon
                                          style={{
                                            color: "#FF2121",
                                            verticalAlign: "bottom",
                                            fontSize: 22,
                                          }}
                                          icon={arrowUpCircle}
                                        />
                                      )}{" "}
                                      {dataVoucher.type == "send"
                                        ? "รับ"
                                        : "ใช้"}
                                    </div>
                                    <img
                                      src={dollar}
                                      style={{
                                        height: 20,
                                        marginLeft: 10,
                                        marginRight: 10,
                                      }}
                                    />{" "}
                                    <IonText style={{ fontSize: 16 }}>
                                      {numberWithCommas(dataVoucher.amount)}
                                      <IonText
                                        style={{
                                          fontSize: 16,
                                          color: "#848484",
                                        }}
                                      >
                                        {" "}
                                        KGO
                                      </IonText>
                                    </IonText>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size={"12"} style={{ marginTop: 10 }}>
                      <IonRow>
                        <IonCol offset={"4"} size={"4"}>
                          <div
                            style={{
                              background: "#FFFFFF",
                              borderRadius: 24,
                              fontSize: 12,
                            }}
                          >
                            <div style={{ color: "#414143" }}>
                              {dataVoucher.limitUseCount == 0 ? (
                                <IonLabel>ไม่จำกัดสิทธิ</IonLabel>
                              ) : (
                                <IonLabel>
                                  จำนวนใช้สิทธิ {dataVoucher.useCount ?? 0}/
                                  {dataVoucher.limitUseCount}
                                </IonLabel>
                              )}
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow
            className={"kgo-row"}
            style={{ position: "absolute", top: 0, width: 512, zIndex: -1 }}
          >
            <IonCol size={"8"}>
              <div id={"qrdiv"} ref={ref}>
                <IonCard className={"kgo-card"}>
                  <IonCardContent className={"ion-text-center"}>
                    <IonRow style={{ color: "#FFFFFF" }}>
                      <IonCol size={"12"}>
                        <IonText
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            color: "black",
                          }}
                        >
                          {appContext.profile?.businessName}
                        </IonText>
                      </IonCol>
                      <IonCol size={"12"} style={{ marginTop: 10 }}>
                        {dataVoucher.status == "active" &&
                        ((dataVoucher.limitUseCount != 0 &&
                          dataVoucher.useCount < dataVoucher.limitUseCount) ||
                          dataVoucher.useCount == null ||
                          dataVoucher.limitUseCount == 0) ? (
                          <canvas
                            height={276}
                            width={276}
                            id={"qr-code-voucher-share"}
                          />
                        ) : dataVoucher.status == "inActive" ? (
                          <IonText>
                            Voucher นี้ใช้สถานะปิดใช้งาน
                            <div>หากต้องการใช้งานอีกครั้งโปรดแก้ไข Voucher</div>
                          </IonText>
                        ) : (
                          <IonText>
                            Voucher นี้ใช้งานครบตามจำนวนที่จำกัดแล้ว
                            <div>หากต้องการใช้เพิ่มโปรดแก้ไข Voucher</div>
                          </IonText>
                        )}
                      </IonCol>
                      <IonCol size={"12"} style={{ marginTop: 5 }}>
                        {expireIn > 0 && (
                          <IonText
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            หมดเวลา{" "}
                            {DateTime.fromMillis(expireIn)
                              .setLocale("th")
                              .toFormat("TT")}
                          </IonText>
                        )}
                      </IonCol>
                      <IonCol size={"12"}>
                        <IonCard className={"kgo-card kgo-light"}>
                          <IonCardContent style={{ color: "#222222" }}>
                            <IonRow>
                              <IonCol
                                size={"12"}
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                {dataVoucher.name}
                              </IonCol>
                              <IonCol
                                size={"12"}
                                style={{
                                  margin: "10px 0",
                                  borderTop: "1px solid #FF8A19",
                                }}
                              ></IonCol>
                            </IonRow>
                            <IonRow style={{ textAlign: "left" }}>
                              <IonCol
                                size={"12"}
                                style={{ fontSize: 14, fontWeight: 500 }}
                              >
                                รายละเอียด : {dataVoucher.detail}
                              </IonCol>
                              <IonCol size={"12"}>
                                <IonRow
                                  style={{ fontSize: 14, fontWeight: 600 }}
                                >
                                  <IonCol size={"12"}>
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <div
                                        style={{
                                          flexGrow: 6,
                                          color: "#414143",
                                        }}
                                      >
                                        {dataVoucher.type == "send" ? (
                                          <IonIcon
                                            style={{
                                              color: "#00CA38",
                                              verticalAlign: "bottom",
                                              fontSize: 22,
                                            }}
                                            icon={arrowDownCircle}
                                          />
                                        ) : (
                                          <IonIcon
                                            style={{
                                              color: "#FF2121",
                                              verticalAlign: "bottom",
                                              fontSize: 22,
                                            }}
                                            icon={arrowUpCircle}
                                          />
                                        )}{" "}
                                        {dataVoucher.type == "send"
                                          ? "รับ"
                                          : "ใช้"}
                                      </div>

                                      <div style={{ flexGrow: 6 }}>
                                        <img
                                          src={dollar}
                                          style={{
                                            height: 20,
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                        />
                                        <IonText>
                                          {numberWithCommas(dataVoucher.amount)}
                                          <IonText
                                            style={{
                                              fontSize: 16,
                                              color: "#848484",
                                            }}
                                          >
                                            {" "}
                                            KGO
                                          </IonText>
                                        </IonText>
                                      </div>
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                      <IonCol
                        size={"12"}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <IonRow>
                          <IonCol offset={"4"} size={"4"}>
                            <div
                              style={{
                                background: "#FFFFFF",
                                borderRadius: 24,
                                fontSize: 12,
                              }}
                            >
                              <div style={{ color: "#414143" }}>
                                {dataVoucher.limitUseCount == 0 ? (
                                  <IonLabel>ไม่จำกัดสิทธิ</IonLabel>
                                ) : (
                                  <IonLabel>
                                    จำนวนใช้สิทธิ {dataVoucher.limitUseCount}
                                  </IonLabel>
                                )}
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"} style={{ marginTop: 20 }}>
            <IonCol>
              <IonButton
                expand="block"
                className={"kgo-btn-store"}
                routerLink={"/voucher/edit/" + dataVoucher.id}
                disabled={disableBtn}
              >
                แก้ไข
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
        {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default VoucherGenerate;
