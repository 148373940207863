import {createContext} from "react";
import {ApiService} from "../services/api-service";
import {InventoryModel, Notify, profileModel, VoucherModel} from "../models/Data";

export interface AppContextInterface {
    apiService: ApiService;
    otpRefData: object | null;
    setOtpRefData: Function;
    phoneNumber: string;
    setPhoneNumber: Function;
    address : string;
    setAddress: Function;
    userStatus:  string;
    setUserStatus: Function;
    profile:  profileModel;
    setProfile: Function;
    registerStore:  profileModel;
    setRegisterStore: Function;
    notify: Array<Notify>;
    getProfile: Function;
    kgoBalance: Number;
    kgoReceiveBalance: Number;
    vouchers: Array<VoucherModel>;
    inventory: {nftVouchers: Array<any>, nftClaimVouchers: Array<any>};
    pointAddress: string;
}

// @ts-ignore
export const AppContext = createContext<AppContextInterface>();
