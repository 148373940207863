import { Switch } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  setupIonicReact,
  useIonAlert,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/style.css";
import TermService from "./pages/TermService";
import OtpVerify from "./pages/OtpVerify";
import React, { useEffect, useState } from "react";
import MobileVerify from "./pages/MobileVerify";
import { AppContext } from "./context/state";
import { ApiService } from "./services/api-service";
import Logo from "./pages/Logo";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import NotFound from "./pages/NotFound";
import { InventoryModel, Notify, profileModel } from "./models/Data";
import Transaction from "./pages/Transaction";
import Store from "./pages/Store";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import db from "./services/firebase";
import { mailOpenOutline } from "ionicons/icons";
import PinCode from "./pages/PinCode";
import StoreRegisterMain from "./pages/StoreRegisterMain";
import StoreRegister from "./pages/StoreRegister";
import VoucherMain from "./pages/VoucherMain";
import Profile from "./pages/Profile";
import { DateTime } from "luxon";
import VoucherCreate from "./pages/VoucherCreate";
import VoucherGenerate from "./pages/VoucherGenerate";
import KgoInfo from "./pages/KgoInfo";
import ViewProfile from "./pages/ViewProfile";
import ListingProfile from "./pages/ListingProfile";
import Exchange from "./pages/Exchange";
import Inventory from "./pages/Inventory";
import Redemption from "./pages/Redemption";
import TransactionEx from "./pages/TransactionEx";
import RedeemVoucher from "./pages/RedeemVoucher";
import RedeemVoucherCreate from "./pages/RedeemVoucherCreate";
import FAQ from "./pages/FAQ";
import LuckyDraw from "./pages/LuckyDraw";
import MyRewards from "./pages/MyRewards";
import Notification from "./pages/Notification";
import { HomePage } from "../src/pages/Home";
import RewardSingle from "./pages/rewards/[id]";
import MyPrize from "./pages/MyPrize";
import PrizeById from "./pages/prizes/[id]";
import VoucherById from "./pages/vouchers/[id]";
import MyPrizeHistory from "./pages/myprize/history";
import MyRewardHistory from "./pages/myreward/history";
import ShopReview from "./pages/ShopReview";
import ExchangeiKGO from "./pages/ExchangeIkgo";
import PasswordCreate from "./pages/PasswordCreate";
import PasswordLogin from "./pages/PasswordLogin";

setupIonicReact();

const App = () => {
  const [apiService, setApiService] = useState<ApiService>();
  const [otpRefData, setOtpRefData] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pointAddress, setPointAddress] = useState("");
  const [profile, setProfile] = useState<profileModel>(new profileModel({}));
  const [userStatus, setUserStatus] = useState<string>("");
  const [notify, setNotify] = useState<Array<Notify>>([]);
  const [showNotify, setShowNotify] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [registerStore, setRegisterStore] = useState<profileModel>(
    new profileModel({})
  );
  const [kgoBalance, setKgoBalance] = useState(0);
  const [kgoReceiveBalance, setKgoReceiveBalance] = useState(0);
  const [vouchers, setVouchers] = useState([]);
  const [inventory, setInventory] = useState<InventoryModel>(
    new InventoryModel({})
  );
  const [present] = useIonAlert();

  useEffect(() => {
    const init = async () => {
      if (apiService == null) {
        const tempApiService = new ApiService();
        setApiService(tempApiService);
      }
    };
    init()
      .then((r) => {})
      .catch((e) => {
        console.error(e);
        console.error("init error");
      });
  }, []);

  useEffect(() => {
    let checkNewAddress = "";
    if (address) {
      // console.log('onSnapshot firestore')
      const q = query(
        collection(db, "message"),
        where("address", "==", address),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const dataNotify: any[] = [];
        querySnapshot.forEach((doc) => {
          dataNotify.push(doc.data());
        });
        if (dataNotify.length > 0 && address === checkNewAddress) {
          const typeText =
            dataNotify[0].type == "receive" ? "รับเหรียญ" : "ส่งเหรียญ";
          const dateText = DateTime.fromMillis(dataNotify[0].timestamp)
            .setLocale("th")
            .toFormat("d MMM yy T");
          const text = `<div><strong>${typeText}</strong></div> <div>${dateText}</div> <div>${dataNotify[0].text}</div>`;
          setNotifyMessage(text);
          setShowNotify(true);
        }
        checkNewAddress = address;
        setNotify(dataNotify);
      });
    }
  }, [address]);

  const requireLogin = (to: any, from: any, next: any) => {
    if (to.meta.auth) {
      let accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        next();
      }
      next.redirect("/mobile-verify");
    } else {
      next();
    }
  };

  const getProfile = async () => {
    if (apiService)
      return apiService
        .getBalance()
        .then((response: any) => {
          if (response?.success) {
            setAddress(response.walletAddress);
            setPointAddress(response.pointWalletAddress);
            const nUser = new profileModel(response.profile);
            setProfile(nUser);
            setUserStatus(response.status);
            setVouchers(response.vouchers.reverse());
            const tempInventory = inventory;
            if (response.nftVouchers && response.nftVouchers.length > 0) {
              tempInventory.nftVouchers = [...response.nftVouchers];
            }
            if (
              response.nftClaimVouchers &&
              response.nftClaimVouchers.length > 0
            ) {
              tempInventory.nftClaimVouchers = [...response.nftClaimVouchers];
            }
            setInventory({ ...tempInventory });

            if (response.balance.length > 0) {
              for (const val of response.balance) {
                if (val.symbol == "KGO") {
                  setKgoBalance(parseFloat(val.balance));
                }
              }
            }
            if (response.pointBalance) {
              for (const val of response.pointBalance) {
                if (val.symbol == "KGO") {
                  setKgoReceiveBalance(parseFloat(val.balance));
                }
              }
            }
            return true;
          } else {
            present({
              cssClass: "ionic-alert",
              header: "Alert",
              message: "ไม่สามารถใช้งานได้ กรุณาติดต่อ Admin #1",
              buttons: [
                {
                  text: "Ok",
                  handler: (d) => {
                    localStorage.clear();
                    window.location.href = "/";
                  },
                },
              ],
            });
            return false;
          }
        })
        .catch((e) => {
          console.log("error text from cache", e.message);
          present({
            cssClass: "ionic-alert",
            header: "Alert",
            message: "ไม่สามารถใช้งานได้ กรุณาติดต่อ Admin #2",
            buttons: [
              {
                text: "Ok",
                handler: (d) => {
                  localStorage.clear();
                  window.location.href = "/";
                },
              },
            ],
          });
          return false;
        });
  };

  return (
    <AppContext.Provider
      value={{
        // @ts-ignore
        apiService,
        otpRefData,
        setOtpRefData,
        phoneNumber,
        setPhoneNumber,
        userStatus,
        setUserStatus,
        profile,
        setProfile,
        notify,
        setAddress,
        address,
        getProfile,
        registerStore,
        setRegisterStore,
        kgoBalance,
        kgoReceiveBalance,
        vouchers,
        inventory,
        pointAddress,
      }}
    >
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <GuardProvider guards={[requireLogin]}>
              <Switch>
                <GuardedRoute path="/" exact component={Logo} />
                <GuardedRoute path="/main" exact component={HomePage} />
                <GuardedRoute
                  path="/term-service"
                  exact
                  component={TermService}
                />
                <GuardedRoute
                  path="/mobile-verify"
                  exact
                  component={MobileVerify}
                />
                <GuardedRoute path="/otp-verify" exact component={OtpVerify} />
                <GuardedRoute path="/pin" exact component={PinCode} />

                <GuardedRoute
                  path="/home"
                  exact
                  component={Store}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/setpassword"
                  exact
                  component={PasswordCreate}
                />
                <GuardedRoute path="/login" exact component={PasswordLogin} />
                <GuardedRoute
                  path="/voucher"
                  exact
                  component={VoucherMain}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/profile"
                  exact
                  component={Profile}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/listing-profile"
                  exact
                  component={ListingProfile}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/transaction"
                  exact
                  component={Transaction}
                  meta={{ auth: true }}
                />

                <GuardedRoute
                  path="/register"
                  exact
                  component={StoreRegisterMain}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/register-view"
                  exact
                  component={ViewProfile}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/register-form"
                  exact
                  component={StoreRegister}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/voucher/gen/:voucherId?"
                  exact
                  component={VoucherGenerate}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/voucher/:action/:voucherId?"
                  exact
                  component={VoucherCreate}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/redeem-voucher/:action/:voucherId?"
                  exact
                  component={RedeemVoucherCreate}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/info"
                  exact
                  component={KgoInfo}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchange"
                  exact
                  component={Exchange}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchange/transaction"
                  exact
                  component={TransactionEx}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchange/inventory"
                  exact
                  component={Inventory}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchange/redemption"
                  exact
                  component={Redemption}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/redeem-voucher"
                  exact
                  component={RedeemVoucher}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/LuckyDraw"
                  exact
                  component={LuckyDraw}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/myrewards"
                  exact
                  component={MyRewards}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/myprize"
                  exact
                  component={MyPrize}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/myprize/history"
                  exact
                  component={MyPrizeHistory}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/myreward/history"
                  exact
                  component={MyRewardHistory}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/rewards/:rewardsId?"
                  exact
                  component={RewardSingle}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/prizes/:prizeId?"
                  exact
                  component={PrizeById}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/vouchers/:voucherId?"
                  exact
                  component={VoucherById}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/notification"
                  exact
                  component={Notification}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/faq"
                  exact
                  component={FAQ}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/reviewshop"
                  exact
                  component={ShopReview}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchangeikgo"
                  exact
                  component={ExchangeiKGO}
                  meta={{ auth: true }}
                />
                <GuardedRoute path="*" exact component={NotFound} />
              </Switch>
            </GuardProvider>
          </IonRouterOutlet>
        </IonReactRouter>
        <IonToast
          isOpen={showNotify}
          onDidDismiss={() => setShowNotify(false)}
          message={notifyMessage}
          icon={mailOpenOutline}
          position="top"
          duration={4000}
          color={"warning"}
        />
      </IonApp>
    </AppContext.Provider>
  );
};

export default App;
