const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");
export const fetchNotification = async (type) => {
  try {
    const response = await fetch(
      `${API}notifications?page=1&limit=9999&type=${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await fetch(`${API}notifications/${notificationId}/read`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to mark notification as read");
    }

    return await response.json();
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error; // Re-throw the error to be handled by the calling code
  }
};
