import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../context/state";
import RewardCards from "../../components/Cards/RewardCards";
import { fetchProfile } from "../../services/user";
import {
  fetchMyRewards,
  fetchShopRewards,
  fetchShopRewardsHistory,
} from "../../services/voucher/index";
import StatusModal from "../../components/Modal/StatusModal";
import { redeemVoucherData } from "../../utils/MockData";

const MyRewardHistory: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [bizId, setBizId] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [rewards, setRewards] = useState();
  const [confirmCancelClaim, setConfirmCancelClaim] = useState(false);
  const [selectedItems, setSelectedItems] = useState();

  const handleSelect = (data) => {
    setConfirmCancelClaim(!confirmCancelClaim);
    setSelectedItems(data);
  };
  const getShopId = async () => {
    const shopId = await fetchProfile();
    setBizId(shopId.id);
  };
  const getMyRewards = async (shopId) => {
    const res = await fetchShopRewardsHistory(shopId);
    if (res?.statusCode > 299) {
      // history.push("/main");
      return alert(
        "เกิดข้อผิดพลาด กรุณาติดต่อ Admin (Error" +
          res?.statusCode +
          " : " +
          res?.message +
          ")"
      );
    }
    setRewards(res.data);
    setShowLoading(false);
  };

  useEffect(() => {
    if (bizId !== undefined) {
      getMyRewards(bizId);
    }
  }, [bizId]);
  useEffect(() => {
    if (appContext) {
      // @ts-ignore
      setBizId(appContext?.profile.id);
    }
    getShopId();
  }, []);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <IonPage>
      {/* @ts-ignore */}
      <StatusModal
        data={selectedItems}
        isOpen={confirmCancelClaim}
        setIsOpen={() => setConfirmCancelClaim(!confirmCancelClaim)}
      />
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/myrewards"
            />
          </IonButtons>
          <IonTitle>รายการ Rewards ของฉัน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div style={{ marginTop: 8, paddingLeft: 12, paddingRight: 12 }}>
            <IonRow style={{ paddingTop: 0, marginTop: 10 }}>
              <IonCol size={"12"}>
                <IonText style={{ fontWeight: 700, fontSize: 16 }}>
                  เลือกของรางวัลเพื่อรับสิทธิ์ที่แลกมาแล้ว
                </IonText>
              </IonCol>
            </IonRow>
          </div>
          <div
            style={{
              overflowY: "scroll",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {/* @ts-ignore */}
            {rewards?.length > 0 ? (
              // @ts-ignore
              rewards?.map((reward) => (
                // @ts-ignore
                <RewardCards
                  meta={reward}
                  data={reward?.shopRewardDetails}
                  isClaimed
                  source="history"
                  isOpen={confirmCancelClaim}
                  // @ts-ignore
                  setIsOpen={handleSelect}
                />
              ))
            ) : (
              <h1 className="ion-text-center" style={{ marginTop: 42 }}>
                {showLoading ? "...กำลังโหลด" : "ไม่พบข้อมูล..."}
              </h1>
            )}
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyRewardHistory;
