import React from "react";

const StatusModal = ({
  data,
  isOpen,
  singleBtn,
  setIsOpen,
  onClickHandle,
}: {
  data: any;
  isOpen: boolean;
  singleBtn?: boolean;
  setIsOpen: () => void;
  onClickHandle: () => {};
}) => {
  const statusRender = (status: string) => {
    const item = status?.toLowerCase();
    switch (item) {
      case "pending":
        return "รอการชำระเงิน";
      case "delivered":
        return "กำลังจัดส่ง";
      case "shipped":
        return "จัดส่งเรียบร้อย";
      case "rejected":
        return "การชำระเงินล้มเหลว";
      case "pickup":
        return "มารับของที่สำนักงาน";
      case "uploadedslip":
        return "ส่งหลักฐานชำระเงินแล้ว";
      default:
        return "อยู่ระหว่างการจัดส่ง";
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        width: "100%",
        bottom: 0,
        height: "100%",
        top: 0,
        left: 0,
        display: isOpen ? "block" : "none",
      }}
    >
      <div
        style={{
          height: "auto",
          width: "80%",
          padding: 32,
          borderRadius: 16,
          backgroundColor: "white",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          top: 320,
          left: 44,
        }}
      >
        <p style={{ alignSelf: "center", color: "black" }}>สถานะการจัดส่ง</p>
        <p style={{ alignSelf: "center", color: "black" }}>
          {data?.prizeDetails?.name}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
          }}
        >
          <p style={{ marginTop: 8, fontSize: 14, color: "gray" }}>
            สถานะ: {statusRender(data?.deliveryStatus)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 16,
            gap: 32,
          }}
        >
          <button
            style={{
              padding: "16px 8px",
              width: 180,
              borderRadius: 8,
              backgroundColor: "#FD9E18",
            }}
            onClick={setIsOpen}
          >
            <p style={{ margin: 0, fontSize: 14, color: "white" }}>
              {"ปิดหน้าต่าง"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
