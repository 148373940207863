const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");
export const fetchUserVoucher = async () => {
  try {
    const response = await fetch(`${API}/user-vouchers`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchShopRewards = async () => {
  try {
    const response = await fetch(`${API}shop-reward`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const fetchMyPrizes = async (shopId: string) => {
  try {
    const response = await fetch(`${API}v2/shop-prize/items/user/${shopId}`);
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const fetchMyPrizesHistory = async (shopId: string) => {
  try {
    const response = await fetch(
      `${API}shop-prize/items/history/user/${shopId}`
    );
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const fetchPrizeById = async (id: string) => {
  try {
    const response = await fetch(`${API}shop-prize/${id}`);
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const patchPrizeItemById = async (
  prizeId: any,
  payload: any,
  shortForm: boolean
) => {
  try {
    const response = await fetch(`${API}shop-prize/items/${prizeId}`, {
      method: "PATCH", // Specify PATCH method
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload, // Include the data to be updated
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const patchRewardItemById = async (
  prizeId: any,
  payload: any,
  shortForm?: boolean
) => {
  try {
    const response = await fetch(`${API}shop-reward/items/${prizeId}`, {
      method: "PATCH", // Specify PATCH method
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload, // Include the data to be updated
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
