export const formatDateThai = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthsThai = [
    "มค",
    "กพ",
    "มีค",
    "เมย",
    "พค",
    "มิย",
    "กค",
    "สค",
    "กย",
    "ตค",
    "พย",
    "ธค",
  ];
  const month = monthsThai[date.getMonth()];
  const year = date.getFullYear() + 543; // Convert to Buddhist year

  return `${day} ${month} ${year}`;
};
