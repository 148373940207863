const API = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem("accessToken");
export const fetchReviewShop = async (shopId) => {
  try {
    const response = await fetch(
      `${API}reviews/shop/${shopId}?page=1&limit=9999`,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
