import {
  IonButton,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonToolbar,
  IonProgressBar,
  useIonAlert,
  IonIcon,
  IonLoading,
  IonToggle,
  IonCardContent,
  IonCard,
  IonRow,
  IonCol,
  IonBackButton,
  IonModal,
  IonDatetime,
  IonDatetimeButton,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import { VoucherModel } from "../models/Data";
import { arrowBackOutline, cloudUploadOutline, remove } from "ionicons/icons";
import { RouteComponentProps, useParams } from "react-router";
// @ts-ignore
import TmpImage from "../assets/bi_image-alt.png";
// @ts-ignore
import clipSVG from "../assets/clip.svg";

interface ParamTypes {
  voucherId: string;
  action: string;
}

const VoucherCreate: React.FC<RouteComponentProps> = ({ history }) => {
  const { voucherId, action } = useParams<ParamTypes>();
  const appContext = useContext(AppContext);
  const [id, setID] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [detail, setDetail] = useState("");
  const [limitUseCount, setLimitUseCount] = useState(0);
  const [useCount, setUseCount] = useState(0);
  const [status, setStatus] = useState("active");
  const [isPublic, setIsPublic] = useState(false);
  const [timeExpire, setTimeExpire] = useState("5m");

  const [runningProcess, setRunningProcess] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [fileUploadImage, setFileUploadImage] = useState<Array<File>>([]);
  const [imageFile, setImageFile] = useState("");
  const [datePicker, setDatePicker] = useState<string>();

  const [present] = useIonAlert();
  useEffect(() => {
    if (voucherId) {
      const data = appContext.vouchers.find((e) => e.id == parseInt(voucherId));
      console.log("vouchers", data);
      if (data) {
        setID(data.id);
        setName(data.name);
        setAmount(data.amount);
        setType(data.type);
        setDetail(data.detail);
        setLimitUseCount(data.limitUseCount ? data.limitUseCount : 0);
        setUseCount(data.useCount);
        setIsPublic(data?.isPublic ? data?.isPublic : false);
        setImageFile(data.image);
        setTimeExpire(data.timeExpire ? data.timeExpire : "5m");
      } else {
        appContext.getProfile();
      }
    }
  }, [appContext.vouchers]);

  useEffect(() => {
    if (name && amount && type) {
      setDisableBtn(false);
    }
  }, [name, amount, type]);

  async function saveVoucherList(action = "create") {
    if (parseFloat(amount) <= 0 || amount == "") {
      present({
        cssClass: "ionic-alert",
        header: "จำนวนไม่ถูกต้อง",
        message: "โปรดระบุจำนวนมากกว่า 0",
        buttons: ["Ok"],
      });
      return;
    }
    const voucher = new VoucherModel({
      id,
      name,
      type,
      amount,
      detail,
      status,
      limitUseCount,
      useCount,
      isDeleted: false,
      isPublic,
      timeExpire,
    });
    let text = "";
    if (action == "create") {
      text = `ยืนยันสร้าง Voucher <strong> ${name} </strong> <div>นี้ใช่หรือไม่?</div>`;
    } else if (action == "edit") {
      text = `ยืนยันแก้ไข Voucher <strong> ${name} </strong> <div>ใช่หรือไม่?</div>`;
    } else if (action == "delete") {
      voucher.isDeleted = true;
      text = `ยืนยันลบ Voucher <strong> ${name} </strong> <div>นี้ใช่หรือไม่?</div>`;
    }
    await present({
      header: "ยืนยัน",
      message: text,
      buttons: [
        "Cancel",
        {
          text: "Ok",
          handler: async (d) => {
            setShowLoading(true);
            await appContext.apiService
              .voucher(voucher, fileUploadImage[0], action)
              .then((response: any) => {
                if (response.success) {
                  present({
                    cssClass: "ionic-alert",
                    header: "แจ้งเตือน",
                    message: "บันทึกข้อมูล Voucher สำเร็จ",
                    buttons: ["Ok"],
                  });
                } else {
                  present({
                    cssClass: "ionic-alert",
                    header: "Alert",
                    message: response.error,
                    buttons: ["Ok"],
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                setShowLoading(false);
              });

            history.push("/main");
          },
        },
      ],
      onDidDismiss: (e) => {
        console.log("did dismiss");
      },
    });
  }
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store ion-text-center"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonText>
            {action == "edit" ? "แก้ไข Pay Voucher" : "สร้าง Pay Voucher"}
          </IonText>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow className={"kgo-row"} style={{ marginTop: 8 }}>
            <IonCol size={"12"}>
              <div className={"ion-text-center"}>
                <IonText style={{ color: "grey", fontSize: 14 }}>
                  รูปภาพ
                </IonText>
                <div
                  className={"cover-upload-image small-image-cover"}
                  style={{
                    margin: "auto",
                    backgroundImage: `url(${imageFile})`,
                  }}
                >
                  {
                    <div>
                      {imageFile == "" ? (
                        <div>
                          <IonRow>
                            <IonCol size={"12"}>
                              <img
                                src={TmpImage}
                                style={{ width: 55, marginTop: 18 }}
                              />
                              <br />
                            </IonCol>
                          </IonRow>
                          <IonButton
                            size={"small"}
                            className={"kgo-btn2"}
                            style={{ color: "#FFFFFF", marginBottom: 8 }}
                          >
                            <img src={clipSVG} />
                            <input
                              id={"file-upload"}
                              hidden
                              type="file"
                              multiple={false}
                              onChange={(e) => {
                                if (e.target.files) {
                                  console.log("e.target.files", e.target.files);
                                  const files = e.target.files;
                                  const temp = [];
                                  const filePath = [];
                                  for (const i in files) {
                                    if (files[i].size > 5e6) {
                                      // @ts-ignore
                                      e.target.value = null;

                                      present({
                                        cssClass: "ionic-alert",
                                        header: "แจ้งเตือน",
                                        message: "พบขนาดไฟล์ห้ามเกิน 5MB",
                                        buttons: ["Ok"],
                                      }).catch();
                                    } else if (!isNaN(parseInt(i))) {
                                      temp.push(files[i]);
                                      filePath.push(
                                        URL.createObjectURL(files[i])
                                      );
                                    }
                                  }

                                  setFileUploadImage(temp);
                                  setImageFile(filePath[0]);
                                  console.log("temp", temp);
                                }
                              }}
                            />
                            <label
                              htmlFor={"file-upload"}
                              style={{ marginLeft: 8, fontSize: 10 }}
                            >
                              {" "}
                              อัพโหลดรูปภาพ
                            </label>
                          </IonButton>
                        </div>
                      ) : null}
                      {imageFile != "" ? (
                        <div style={{ marginTop: 80 }}>
                          <IonButton
                            size={"small"}
                            className={"kgo-btn2"}
                            style={{ color: "#FFFFFF" }}
                          >
                            <img src={clipSVG} />
                            <input
                              id={"file-upload"}
                              hidden
                              type="file"
                              multiple={false}
                              onChange={(e) => {
                                if (e.target.files) {
                                  console.log("e.target.files", e.target.files);
                                  const files = e.target.files;
                                  const temp = [];
                                  const filePath = [];
                                  for (const i in files) {
                                    if (files[i].size > 5e6) {
                                      // @ts-ignore
                                      e.target.value = null;

                                      present({
                                        cssClass: "ionic-alert",
                                        header: "แจ้งเตือน",
                                        message: "พบขนาดไฟล์ห้ามเกิน 5MB",
                                        buttons: ["Ok"],
                                      }).catch();
                                    } else if (!isNaN(parseInt(i))) {
                                      temp.push(files[i]);
                                      filePath.push(
                                        URL.createObjectURL(files[i])
                                      );
                                    }
                                  }

                                  setFileUploadImage(temp);
                                  setImageFile(filePath[0]);
                                  console.log("temp", temp.length);
                                }
                              }}
                            />
                            <label
                              htmlFor={"file-upload"}
                              style={{ marginLeft: 8, fontSize: 10 }}
                            >
                              {" "}
                              อัพโหลดรูปภาพ
                            </label>
                          </IonButton>
                        </div>
                      ) : null}
                    </div>
                  }
                </div>
              </div>
            </IonCol>

            <IonCol>
              <IonCard className={"kgo-card kgo-light"}>
                <IonCardContent style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <IonList>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        ชื่อ
                      </IonLabel>
                      <IonInput
                        type="text"
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ชื่อ Voucher.."
                        value={name}
                        onIonChange={(e) => {
                          setName(e.detail.value!);
                        }}
                      >
                        {" "}
                      </IonInput>
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        ประเภท
                      </IonLabel>
                      <IonSelect
                        value={type}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ประเภทการจัดการ"
                        interface="popover"
                        onIonChange={(e) => {
                          setType(e.detail.value);
                        }}
                      >
                        <IonSelectOption value="send">
                          ส่งเหรียญให้ลูกค้า
                        </IonSelectOption>
                        <IonSelectOption value="receive">
                          รับเหรียญจากลูกค้า
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        รายละเอียด
                      </IonLabel>
                      <IonInput
                        value={detail}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;รายละเอียด"
                        onIonChange={(e) => setDetail(e.detail.value!)}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        จำนวน
                      </IonLabel>
                      <IonInput
                        type="number"
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ขั้นต่ำ 0.01"
                        value={amount}
                        onIonChange={(e) => {
                          {
                            setAmount(e.detail.value!);
                          }
                        }}
                        id={"amount"}
                      >
                        {" "}
                      </IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        จำกัดสิทธิ{" "}
                        <IonText color={"warning"}>
                          (กรณีไม่จำกัดระบุเป็น 0)
                        </IonText>
                      </IonLabel>
                      <IonInput
                        type={"number"}
                        value={limitUseCount}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ไม่ระบุจะใช้ได้ไม่จำกัด"
                        onIonChange={(e) =>
                          setLimitUseCount(parseInt(e.detail.value!))
                        }
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked" className="label-form">
                        ระยะเวลา
                      </IonLabel>
                      <IonSelect
                        value={timeExpire}
                        style={{ fontSize: 16 }}
                        placeholder="&nbsp;&nbsp;ระยะเวลา"
                        interface="popover"
                        onIonChange={(e) => {
                          setTimeExpire(e.detail.value);
                        }}
                      >
                        <IonSelectOption value="5m">5 นาที</IonSelectOption>
                        <IonSelectOption value="1d">1 วัน</IonSelectOption>
                        <IonSelectOption value="7d">7 วัน</IonSelectOption>
                        <IonSelectOption value="30d">30 วัน</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonRow style={{ width: "100%" }}>
                        <IonCol size={"6"}>
                          <IonLabel position="stacked" className="label-form">
                            สถานะ Voucher
                          </IonLabel>
                          <IonText style={{ margin: "auto" }}>
                            <IonToggle
                              style={{ verticalAlign: "middle" }}
                              color={"success"}
                              checked={status == "active" ? true : false}
                              onIonChange={(e) =>
                                setStatus(
                                  e.detail.checked ? "active" : "inActive"
                                )
                              }
                            />{" "}
                            เปิดใช้งาน
                          </IonText>
                        </IonCol>
                        <IonCol size={"6"}>
                          <IonLabel position="stacked" className="label-form">
                            Public Voucher
                          </IonLabel>
                          <IonText style={{ margin: "auto" }}>
                            <IonToggle
                              style={{ verticalAlign: "middle" }}
                              color={"success"}
                              checked={isPublic}
                              onIonChange={(e) => setIsPublic(e.detail.checked)}
                            />{" "}
                            เปิดใช้งาน
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"} style={{ marginTop: 5 }}>
            <IonCol size={"12"}>
              <IonButton
                expand="block"
                className={"kgo-btn-store"}
                onClick={(e) => {
                  saveVoucherList(action).catch();
                }}
                disabled={disableBtn}
              >
                บันทึก
              </IonButton>
            </IonCol>
            {action == "edit" && (
              <IonCol>
                <IonButton
                  expand="block"
                  color={"danger"}
                  onClick={(e) => {
                    saveVoucherList("delete").catch();
                  }}
                  disabled={disableBtn}
                >
                  ลบ
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </div>
        {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default VoucherCreate;
