import React, { useState } from "react";
// @ts-ignore
import storeWallet from "../../assets/store-wallet.svg";
// @ts-ignore
import storeVoucher from "../../assets/store-voucher-icon-active.svg";
const NewPopUp = ({ setIsShowUpdate }: { setIsShowUpdate: () => void }) => {
  const [step, setStep] = useState(1);
  const handleAdd = (step) => {
    if (step == 1) {
      setStep(2);
    } else if (step == 2) {
      return;
    }
  };
  const handleDeduct = (step) => {
    if (step == 2) {
      setStep(1);
    } else {
      return;
    }
  };
  const closeFunction = () => {
    localStorage.setItem("UpdateNote", JSON.stringify(true));
    setIsShowUpdate();
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        backgroundColor: "rgba(255,255,255,0.9)",
        zIndex: 999,
      }}
    >
      <div
        style={{
          color: "black",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 18,
          height: "100%",
          position: "relative",
        }}
      ></div>
      <div
        style={{
          width: "50%",
          transition: "all ease-in-out 500ms ",
          height: 128,
          backgroundColor: "black",
          color: "white",
          position: "absolute",
          bottom: 80,
          left: step === 1 ? 16 : 96,
          padding: 16,
          borderRadius: 16,
          fontSize: 14,
        }}
      >
        <button
          style={{
            width: 80,
            height: 32,
            borderRadius: 16,
            backgroundColor: "white",
            color: "black",
            position: "absolute",
            transition: "all ease-in-out 500ms",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
            bottom: 140,
            left: step == 1 ? 60 : 0,
          }}
          onClick={() => (step == 1 ? handleAdd(step) : handleDeduct(step))}
        >
          {step == 1 ? "ต่อไป" : "กลับ"}
        </button>
        <button
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: "white",
            color: "black",
            position: "absolute",
            top: -10,
            right: -10,
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
          }}
          onClick={closeFunction}
        >
          ปิด
        </button>
        {step == 1 ? (
          <>
            <p style={{ margin: 0, fontSize: 18 }}>Pay Voucher</p>
            <span> คือ Voucher สำหรับให้ลูกค้าสแกนจ่ายกับร้านค้า</span>
            <span>เหมือนเดิมแบบที่เคยเป็นมา!</span>
          </>
        ) : (
          <>
            <p style={{ margin: 0, fontSize: 18 }}>Redeem Voucher</p>
            <span> คือ Voucher ที่ลูกค้าสามารถแลกล่วงหน้า</span>
            <span>โดยไม่จำเป็นต้องสแกนจ่ายให้ร้านค้า!</span>
          </>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          backgroundColor: "#000",
          paddingRight: 16,
          paddingLeft: 16,
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: 32,
          height: 72,
          width: "100%",
          zIndex: 99,
        }}
      >
        {/* */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          //   onClick={() => history.push("/Voucher")}
        >
          <img
            src={"https://cdn-icons-png.flaticon.com/512/3126/3126564.png"}
            style={{ width: 30, background: "white", borderRadius: 8 }}
          />
          <div style={{ fontSize: 12 }}>Pay Voucher</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          //   onClick={() => history.push("/redeem-voucher")}
        >
          <img src={storeVoucher} style={{ width: 30 }} />
          <div style={{ fontSize: 12 }}>Redeem Voucher</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          //   onClick={() => history.push("/Transaction")}
        >
          <img src={storeWallet} style={{ width: 30 }} />
          <div style={{ fontSize: 12 }}>Transaction</div>
        </div>
      </div>
    </div>
  );
};

export default NewPopUp;
