import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { arrowBack, arrowBackOutline, downloadOutline } from "ionicons/icons";
import { Step, Stepper } from "react-form-stepper";
import StoreRegisterStep1 from "../components/StoreRegisterStep1";
import StoreRegisterStep2 from "../components/StoreRegisterStep2";
import StoreRegisterStep3 from "../components/StoreRegisterStep3";
import { profileModel } from "../models/Data";
import { AppContext } from "../context/state";

const StoreRegister: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    appContext.apiService
      .getBizRegister()
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          const nProfile = new profileModel(response);
          appContext.setRegisterStore(nProfile);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            {activeStep === 0 ? (
              <IonBackButton
                style={{ marginLeft: 5 }}
                color={"light"}
                icon={arrowBackOutline}
                text={""}
                defaultHref="/main"
              />
            ) : activeStep === 1 ? (
              <IonIcon
                icon={arrowBack}
                size="large"
                onClick={(e) => {
                  setActiveStep(0);
                }}
              />
            ) : activeStep === 2 ? (
              <IonIcon
                icon={arrowBack}
                size="large"
                onClick={(e) => {
                  setActiveStep(1);
                }}
              />
            ) : null}
          </IonButtons>
          <IonTitle>สมัครเป็นร้านค้า</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow>
            <IonCol>
              <Stepper
                className={"stepper"}
                steps={[
                  { label: "ข้อมูลเบื้องต้น" },
                  { label: "ที่อยู่ร้าน" },
                  { label: "ภาพร้านและเอกสาร" },
                ]}
                activeStep={activeStep}
              ></Stepper>
            </IonCol>
          </IonRow>

          {activeStep === 0 ? (
            <StoreRegisterStep1
              step={activeStep}
              setStep={setActiveStep}
              required={true}
            />
          ) : activeStep === 1 ? (
            <StoreRegisterStep2
              step={activeStep}
              setStep={setActiveStep}
              required={true}
            />
          ) : activeStep === 2 ? (
            <StoreRegisterStep3
              step={activeStep}
              setStep={setActiveStep}
              required={true}
            />
          ) : null}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StoreRegister;
