import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import ReviewCard from "../components/Cards/ReviewCard";
import Pagination from "../components/Pagination/Pagination";
import { fetchReviewShop } from "../services/review";
import { AppContext } from "../context/state";

const ShopReview: React.FC = () => {
  const appContext = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(true);
  const [shopId, setShopId] = useState();
  const [reviewData, setReviewData] = useState();
  const pageSize = 6; // Number of reviews per page

  const totalPages = Math.ceil(reviewData?.length / pageSize);

  const getReviews = async (shopId) => {
    try {
      const data = await fetchReviewShop(shopId);
      setReviewData(data?.data);
      setShowLoading(false);
    } catch (error) {
      alert(error + " กรุณาติดต่อ Admin");
      setShowLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedReviews = reviewData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getStoreProfile = () => {
    if (appContext.apiService) {
      appContext.getProfile();
      setShopId(appContext?.profile?.businessId);
    } else {
      console.error("apiService not init!");
    }
  };

  useEffect(() => {
    getStoreProfile();
  }, []);

  useEffect(() => {
    if (shopId) {
      getReviews(shopId);
    }
  }, [shopId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/profile"
            />
          </IonButtons>
          <IonTitle>ความเห็นจากลูกค้า</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              // overflowY: "scroll",
              // height: "calc(100vh)",
              padding: 4,
              display: "flex",
              flexDirection: "column",
              // gap: 8,
            }}
          >
            {reviewData?.length > 0 ? (
              <>
                {paginatedReviews?.map((review, index) => (
                  <ReviewCard key={index} review={review} />
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: 32,
                }}
              >
                {"ยังไม่มีความเห็นจากลูกค้า"}
              </div>
            )}
          </div>
          {reviewData?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Loading..."}
        duration={5000}
      />
    </IonPage>
  );
};

export default ShopReview;
