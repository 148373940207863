import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonText,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import { nftModel } from "../models/Data";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../assets/key.png";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../assets/dollar.png";
import RewardCards from "../components/Cards/RewardCards";
import { redeemVoucherData } from "../utils/MockData";
import { fetchProfile } from "../services/user";
import { fetchMyRewards } from "../services/voucher/index";

const MyRewards: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [bizId, setBizId] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [rewards, setRewards] = useState();
  const getShopId = async () => {
    const shopId = await fetchProfile();
    setBizId(shopId.id);
  };
  const getMyRewards = async (shopId) => {
    const res = await fetchMyRewards(shopId);
    if (res?.statusCode > 299) {
      // history.push("/main");
      return alert(
        "เกิดข้อผิดพลาด กรุณาติดต่อ Admin (Error" +
          res?.statusCode +
          " : " +
          res?.message +
          ")"
      );
    }
    setRewards(res.data);
    setShowLoading(false);
  };

  useEffect(() => {
    if (bizId !== undefined) {
      getMyRewards(bizId);
    }
  }, [bizId]);
  useEffect(() => {
    if (appContext) {
      // @ts-ignore
      setBizId(appContext?.profile.id);
    }
    getShopId();
  }, []);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/exchange"
            />
          </IonButtons>
          <IonTitle>รายการ Rewards ของฉัน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              marginTop: 8,
              paddingLeft: 12,
              paddingRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IonText style={{ fontWeight: 700, fontSize: 16 }}>
              เลือกของรางวัลเพื่อรับสิทธิ์ที่แลกมาแล้ว
            </IonText>
            <button
              style={{ backgroundColor: "transparent", color: "gray" }}
              onClick={() => history.push("/myreward/history")}
            >
              ประวัติการแลกรับของรางวัล
            </button>
          </div>
          <div
            style={{
              overflowY: "scroll",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {rewards?.length > 0 ? (
              rewards?.map((reward) => (
                // @ts-ignore
                <RewardCards
                  meta={reward}
                  data={reward.shopRewardDetails}
                  type={"rewards"}
                  isClaimed
                  itemId={reward._id}
                />
                // reward ที่เคลมแล้ว จะถูก redirect ไปที่หน้า กรอกข้อมูลการติดต่อในการ์ดนี้
              ))
            ) : (
              <h1 className="ion-text-center" style={{ marginTop: 42 }}>
                {showLoading ? "...กำลังโหลด" : "ไม่พบข้อมูล..."}
              </h1>
            )}
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyRewards;
