import { useParams } from "react-router-dom";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
  IonItem,
  IonList,
  IonInput,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../context/state";
// @ts-ignore
import kgoNFT from "../../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../../assets/key.png";
// @ts-ignore
import dollar from "../../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../../assets/dollar.png";
import FullForm from "../../components/Delivery/FullForm";
import { fetchPrizeById, patchPrizeItemById } from "../../services/prize";
// import { fetchPrizeById, patchPrizeItemById } from "../../services/voucher";

const VoucherById: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const param = useParams();
  const [showLoading, setShowLoading] = useState(false);
  const [prize, setPrize] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [modeOfDeliver, setModeOfDeliver] = useState();
  // @ts-ignore
  const prizeId = param?.voucherId;
  //   information states
  const [phoneNumber, setPhoneNumber] = useState();
  const [timeContact, setTimeContact] = useState();

  const handleSelectMode = (mode: any) => {
    setModeOfDeliver(mode);
  };
  const getPrizeById = async () => {
    const prize = await fetchPrizeById(prizeId);
    setPrize(prize);
  };
  const setIsEditHandle = (value: boolean) => {
    setIsEdit(value);
  };
  const updatePrizeStatus = async (payload: any, shortForm?: boolean) => {
    const res = await patchPrizeItemById(prizeId, payload, shortForm);
    if (res?._id) {
      alert(
        "ได้รับข้อมูลของท่านเรียบร้อย กรุณารอ Admin ติดต่อกลับเพื่อแนะนำเวลารับของรางวัล"
      );
      history.push("/myprize");
    } else {
      alert(
        "error! มีบางอย่างผิดพลาด กรุณาติดต่อ admin (Error Status:" +
          res?.statusCode +
          ")"
      );
      // history.push("/myvoucher");
    }
  };
  const handleSubmitPickUp = async (e: any) => {
    // e.preventDefault();
    const shortForm = true;
    const formData = new FormData();

    // Append each key-value pair from payload to the FormData object
    // formData.append("phoneNumber", phoneNumber);
    // formData.append("timeForContact", timeContact);
    formData.append("isUsed", "true");

    await updatePrizeStatus(formData, shortForm);
  };

  useEffect(() => {
    // @ts-ignore
    if (prizeId) {
      getPrizeById();
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    if (phoneNumber?.length === 10 && timeContact) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [phoneNumber, timeContact]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/myreward"
            />
          </IonButtons>
          <IonTitle>ยืนยันการรับ</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div style={{ marginTop: 8, paddingLeft: 12, paddingRight: 12 }}>
            <IonRow style={{ padding: 0, marginTop: 10 }}>
              <IonCol size={"12"}>
                <IonText style={{ fontWeight: 700, fontSize: 16 }}>
                  {/* @ts-ignore */}
                  รหัสเพื่อรับรางวัล {prize?.vName}
                </IonText>
              </IonCol>
            </IonRow>
            {/* for deliver */}
            <div>รหัสสำหรับการรับรางวัลของคุณ</div>
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <div
                style={{
                  backgroundColor: "orange",
                  padding: 24,
                  borderRadius: 8,
                  color: "white",
                }}
              >
                xxxx-xxxx-xxxx-xxx
              </div>
              <span style={{ color: "gray" }}>
                รหัสนี้จะหมดอายุภายใน 15 นาทีหลังจากทำการเปิด กรุณาใช้ Code
                ภายในเวลาที่กำหนด
              </span>
              <span style={{ color: "black" }}>
                กดยืนยันเมื่อใช้รหัสรับรางวัลสำเร็จ
              </span>
              <button
                onClick={handleSubmitPickUp}
                style={{
                  backgroundColor: "orange",
                  padding: 8,
                  borderRadius: 8,
                  color: "white",
                }}
              >
                ยืนยันการใช้รหัส
              </button>
            </div>
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VoucherById;
