import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { formatDateThai } from "../../utils";

const RewardCards = ({
  itemId,
  meta,
  data,
  kgo,
  isClaimed,
  source,
  type,
  setIsOpen,
  setSelectVoucher,
}: {
  itemId: string;
  source?: string;
  data: any;
  meta?: any;
  isClaimed?: boolean;
  kgo?: any;
  type?: string;
  setIsOpen: () => void;
  setSelectVoucher: (data: any) => {};
}) => {
  const history = useHistory();

  const handleConfirmBtn = useCallback(() => {
    if (!isClaimed) {
      setSelectVoucher(
        kgo >= data?.price
          ? {
              title: data?.name,
              description: data?.description,
              isCheckbox: data?.isDeliver,
              isNotEnough: false,
              rewardId: data?._id,
            }
          : {
              title: "KGO ไม่เพียงพอ",
              description: "ไม่สามารถแลกรางวัลนี้ได้เนื่องจาก KGO ไม่เพียงพอ",
              isCheckbox: false,
              isNotEnough: true,
            }
      );
    } else {
      history.push(`/${type === "prizes" ? "prizes" : "rewards"}/${itemId}`);
    }
  }, [isClaimed, kgo, data, history, itemId, type, setSelectVoucher]);

  const buttonLabel = useMemo(() => {
    return isClaimed ? "รับสิทธิ์" : `${data?.price} KGO`;
  }, [isClaimed, data?.price]);

  const isButtonDisabled = useMemo(() => {
    return kgo < data?.price;
  }, [kgo, data?.price]);

  return (
    <div
      style={{
        borderWidth: 2,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderStyle: "solid",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={data?.imgUrl}
        style={{
          width: "100%",
          height: 146,
          objectFit: "cover",
          borderTopRightRadius: 16,
          borderTopLeftRadius: 16,
        }}
        alt="voucher preview"
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 8,
          backgroundColor: "rgba(255, 145, 0,0.9)",
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16,
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: 16,
                color: "white",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              {data?.name}
            </p>
            {source === "history" && data?.isDeliver && (
              <button
                disabled={meta?.timeForContact}
                // @ts-ignore
                onClick={() => setIsOpen(meta)}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {meta?.timeForContact
                  ? "รับเองที่สำนักงาน"
                  : "ตรวจสอบสถานะจัดส่ง"}
              </button>
            )}
          </div>
          <p
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.5)",
              margin: 0,
            }}
          >
            {data?.description}
          </p>
          <p
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.25)",
              marginTop: 4,
            }}
          >
            {`ใช้ได้ตั้งแต่วันที่ ${formatDateThai(data?.startDate)}`}
          </p>
        </div>
        {source !== "history" && (
          <button
            onClick={handleConfirmBtn}
            disabled={isButtonDisabled}
            style={{
              backgroundColor: isButtonDisabled ? "gray" : "#FF6E07",
              padding: 8,
              borderRadius: 8,
              height: 36,
              color: "white",
              border: "none",
              cursor: isButtonDisabled ? "not-allowed" : "pointer",
            }}
          >
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default React.memo(RewardCards);
