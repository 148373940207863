import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import NotificationCard from "../components/Cards/Notification";
import Pagination from "../components/Pagination/Pagination";
import { fetchNotification } from "../services/notification";
import Dropdown from "../components/dropdown/Dropdown";

const Notification: React.FC<RouteComponentProps> = ({ history }) => {
  const [notificationData, setNotificationData] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Number of items per page
  const getNotification = async (value) => {
    const data = await fetchNotification(value);
    setNotificationData(data);
    setShowLoading(false);
  };
  const handleSelect = (value: string) => {
    setShowLoading(true);
    getNotification(value);
  };
  useEffect(() => {
    getNotification("");
  }, []);
  // @ts-ignore
  const totalPages = Math.ceil(notificationData?.length / pageSize);
  // @ts-ignore
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // @ts-ignore
  const paginatedNotifications = notificationData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const options = [
    { label: "ทั้งหมด", value: "" },
    { label: "ประกาศ", value: "broadcast" },
    { label: "รายการธุรกรรม", value: "transaction" },
  ];
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/"
            />
          </IonButtons>
          <IonTitle>การแจ้งเตือน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
          duration={5000}
        />
        <div className="app-container">
          <div style={{ display: "flex", justifyContent: "center", margin: 8 }}>
            <Dropdown options={options} onSelect={handleSelect} />
          </div>
          <div
            style={{
              // overflowY: "scroll",
              height: "calc(100vh - 200px)",
              padding: 4,
              display: "flex",
              flexDirection: "column",
              // gap: 8,
            }}
          >
            {paginatedNotifications?.length > 0 ? (
              // @ts-ignore
              paginatedNotifications?.map((noti) => (
                // @ts-ignore
                <NotificationCard
                  key={noti.id}
                  data={noti}
                  onClose={() => getNotification()}
                />
              ))
            ) : (
              <h1 className="ion-text-center" style={{ marginTop: 42 }}>
                ไม่พบข้อมูล...
              </h1>
            )}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notification;
