
export class UserProfile {
    public address: string;
    public balance: Array<token>;
    public phoneNumber: '';
    public QrCodeUrl: '';
    public profile: profileModel;
    public type: '';
    public statusStore: string;
    public voucher: Array<VoucherModel>

    constructor(info: any) {
        this.address = info.address;
        this.balance = info.balance;
        this.phoneNumber = info.phoneNumber;
        this.QrCodeUrl = info.QrCodeUrl;
        this.profile = info.profile;
        this.type = info.type;
        this.statusStore = info.statusStore;
        this.voucher = info.voucher;
    }
}

export class profileModel {
    public profileImage: string;
    // public firstName: string;
    // public lastName: string;
    // public birthdate: string;
    // public twitter: string;
    // public facebook: string;
    // public homepage: string

    //store
    public businessId: string;
    public businessName: string;
    public businessType: string;
    public businessOtherType: string;
    public ownerName: string;
    public businessPhoneNumber: string;
    public businessEmail: string;

    public address: string;
    public zipcode: string;
    public provinceId: string;
    public provinceName: string;
    public amphurId: string;
    public amphurName: string;
    public districtId: string
    public districtName: string;
    public latlngText: string;
    public lat: string;
    public lng: string;
    public referral: string;

    public fileUpload: { fileImage: string, fileDocument: string };
    public status: string;
    public rejectDetail: string;
    public updatedAt: number;
    public createdAt: number;
    constructor(info: any) {
        this.profileImage = info.profileImage;
        // this.firstName = info.firstName;
        // this.lastName = info.lastName;
        // this.birthdate = info.birthdate;
        // this.twitter = info.twitter;
        // this.facebook = info.facebook;
        // this.homepage = info.homepage;

        //store
        this.businessId = info.businessId;
        this.businessName = info.businessName;
        this.businessType = info.businessType;
        this.businessOtherType = info.businessOtherType;
        this.ownerName = info.ownerName;
        this.businessPhoneNumber = info.businessPhoneNumber;
        this.businessEmail = info.businessEmail;

        this.address = info.address;
        this.zipcode = info.zipcode;
        this.provinceId = info.provinceId;
        this.provinceName = info.provinceName;
        this.amphurId = info.amphurId;
        this.amphurName = info.amphurName;
        this.districtId = info.districtId;
        this.districtName = info.districtName;
        this.latlngText = info.latlngText;
        this.referral = info.referral;
        this.lat = info.lat;
        this.lng = info.lng;

        this.fileUpload = info.fileUpload;
        this.status = info.status;
        this.rejectDetail = info.rejectDetail;
        this.updatedAt = info.updatedAt;
        this.createdAt = info.createdAt;
    }
}

export class token {
    public symbol: string;
    public balance: string;

    constructor(info: any) {
        this.symbol = info.symbol;
        this.balance = info.balance;
    }
}


export class userOtp {
    public expireIn: string;
    public otp: string;
    public phoneNumber: string;
    public ref: string;
    public status: string;

    constructor(info: any) {
        this.expireIn = info.expireIn;
        this.otp = info.otp;
        this.phoneNumber = info.phoneNumber;
        this.ref = info.ref;
        this.status = info.status;
    }
}

export class Notify{
    public action: string;
    public address: string
    public status: string
    public text: string
    public timestamp: number
    public type: string
    public voucher: string

    constructor(info: any) {
        this.action = info.action;
        this.address = info.address;
        this.status = info.status;
        this.text = info.text;
        this.timestamp = info.timestamp;
        this.type = info.type;
        this.voucher = info.voucher;
    }
}

export class VoucherModel {
    public id: number;
    public name: string;
    public type: string;
    public amount: string;
    public detail: string;
    public status: string;
    public limitUseCount: number;
    public useCount: number;
    public isDeleted: boolean;
    public isPublic: boolean;
    public image: any;
    public timeExpire: string;
    constructor(info: any) {
        this.id = info.id;
        this.name = info.name;
        this.type = info.type;
        this.amount = info.amount;
        this.detail = info.detail
        this.status = info.status;
        this.limitUseCount = info.limitUseCount;
        this.useCount = info.useCount;
        this.isDeleted = info.isDeleted;
        this.isPublic = info.isPublic;
        this.image = info.image;
        this.timeExpire = info.timeExpire;
    }
}

export class InventoryModel {
    public nftVouchers: Array<any>;
    public nftClaimVouchers: Array<any>;

    constructor(info: any) {
        this.nftVouchers = info.nftVouchers;
        this.nftClaimVouchers = info.nftClaimVouchers;
    }
    // public nftId: number;
    // public nftName: string;
    // public createdAt: number;
    // public contractAddress: string;
    // public transactionHash: string;
    // public status: string;
    //
    // constructor(info: any) {
    //     this.nftId = info.nftId;
    //     this.nftName = info.nftName;
    //     this.createdAt = info.createdAt;
    //     this.contractAddress = info.contractAddress;
    //     this.transactionHash = info.transactionHash;
    //     this.status = info.status;
    // }
}

export const nftModel = [
  null,
    {nftName: "พวงกุญแจ 2,000 KGO", img: 'key'},
    {nftName: "500 KGO แลก 25 บาท", img:'dollar'},
    null,
    null,
]
