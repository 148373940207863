import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonCardContent,
  IonCard,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { RouteComponentProps, useParams } from "react-router";
import TransactionList from "../components/TransactionList";
import { arrowBackOutline } from "ionicons/icons";
import { AppContext } from "../context/state";

interface ParamTypes {
  address: string;
}

const TransactionEx: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/exchange"
            />
          </IonButtons>
          <IonTitle>ประวัติการรับเหรียญจากลูกค้า</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonCard className={"kgo-card kgo-card-tran kgo-card-tran-page"}>
            <IonCardContent>
              <TransactionList
                walletAddress={appContext.pointAddress}
                mode={"page"}
              />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TransactionEx;
