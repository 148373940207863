import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonIcon,
  IonLoading,
  useIonAlert,
  IonProgressBar,
  IonCard,
  IonCardContent,
  IonGrid,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { arrowBackOutline, syncCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { userOtp } from "../models/Data";

export default function OtpVerify() {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const [runningProcess, setRunningProcess] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpRef, setOtpRef] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [countdown, setCountdown] = useState("(30)");
  const [timer, setTimer] = useState<any>();
  const [present] = useIonAlert();

  useEffect(() => {
    if (appContext?.otpRefData) {
      console.log("ref", appContext?.otpRefData);
      let ref = new userOtp(appContext?.otpRefData);
      setOtpRef(appContext?.otpRefData);
      // setOtp(ref.otp);
      // checkOTP(ref.otp).catch(console.error);
    }

    startTimer();
  }, [appContext?.otpRefData]);

  function startTimer() {
    let seconds = 30;
    const tempTimer = setInterval(() => {
      seconds = seconds - 1;
      setCountdown(`( ${seconds} )`);
      if (seconds == 0) {
        clearInterval(tempTimer);
        setCountdown("");
        setDisableBtn(false);
      }
    }, 1000);
  }

  async function sendOtpAgain() {
    console.log("sendOTP");
    if (appContext.apiService) {
      setRunningProcess(true);
      setDisableBtn(true);
      await appContext.apiService
        .getOtp(appContext?.phoneNumber)
        .then((response) => {
          console.log("response", response);
          if (response.success) {
            appContext.setOtpRefData(response?.data);
            startTimer();
          } else {
            present({
              cssClass: "ionic-alert",
              header: "Alert",
              message: response.message,
              buttons: ["Ok"],
            });
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setRunningProcess(false);
          setDisableBtn(false);
        });
    } else {
      console.log("false");
    }
  }

  async function checkOTP(otp: string) {
    setShowLoading(true);
    if (appContext?.phoneNumber == "") {
      history.push("/mobile-verify");
    }
    const data = {
      phoneNumber: appContext.phoneNumber,
      otp: otp,
      ref: otpRef.ref,
    };
    await appContext.apiService
      .checkOtp(data)
      .then((response: any) => {
        console.log("response", response);
        if (response?.success) {
          localStorage.setItem("accessToken", response?.data.accessToken);
          localStorage.setItem("phoneNumber", appContext.phoneNumber);
          if (localStorage.getItem("accessToken")) {
            history.push("/setpassword");
          }
        } else {
          present({
            cssClass: "ionic-alert",
            header: "Alert",
            message: response.message,
            buttons: ["Ok"],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setShowLoading(false);
  }

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/mobile-verify"
            />
          </IonButtons>
          <IonTitle>กรอกรหัส OTP</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonGrid style={{ marginTop: 24 }}>
            <IonRow className={"ion-text-center"}>
              <IonCol size="12" style={{ fontSize: 18 }}>
                รหัส OTP ที่เราส่งทาง SMS
                <div>ไปยังหมายเลขโทรศัพท์ที่ลงทะเบียนของคุณ</div>
                <div>{appContext.phoneNumber}</div>
              </IonCol>
            </IonRow>

            <IonRow className={"ion-text-center"}>
              <IonCol size="12" style={{ fontSize: 18 }}>
                <form className={"ion-text-center"}>
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <OtpInput
                      shouldAutoFocus
                      inputStyle={{
                        fontSize: 32,
                        minWidth: 50,
                        borderRadius: 8,
                        color: "white",
                      }}
                      value={otp}
                      onChange={(e: any) => {
                        setOtp(e);
                        if (e.length == 6) {
                          checkOTP(e).catch((e) => console.error(e));
                        }
                      }}
                      numInputs={6}
                      isInputNum={true}
                      separator={<span style={{ marginRight: 10 }}></span>}
                    />
                  </div>
                </form>
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonCol offset={"3"}>
                    OTP ref: {otpRef?.ref}
                    <p>{otpRef?.otp}</p>
                  </IonCol>
                  <IonCol size={"3"}>
                    <IonButton
                      fill={"outline"}
                      type="button"
                      size={"small"}
                      color={"dark"}
                      disabled={isDisabled || otp.trim() === ""}
                      onClick={(e: any) => {
                        setOtp("");
                      }}
                    >
                      Clear
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonRow>
            <IonCol size="12" class="ion-text-center">
              ยังไม่ได้รับรหัสยืนยันตัวตน?
            </IonCol>
            <IonCol size="12" class="ion-text-center">
              <IonButton
                fill={"clear"}
                color={"dark"}
                style={{ fontWeight: 600 }}
                size={"small"}
                onClick={(e) => {
                  sendOtpAgain().catch();
                }}
                disabled={disableBtn}
              >
                ส่งอีกครั้ง {countdown}
              </IonButton>
              {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
            </IonCol>
          </IonRow>
        </div>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
}
