import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import { nftModel } from "../models/Data";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../assets/key.png";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../assets/dollar.png";
import RewardCards from "../components/Cards/RewardCards";
import { redeemVoucherData } from "../utils/MockData";
import { fetchProfile } from "../services/user";
import { fetchMyRewards } from "../services/voucher/index";
import { fetchMyPrizes } from "../services/prize";

const MyPrize: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [present] = useIonAlert();
  const [bizId, setBizId] = useState();
  const [showLoading, setShowLoading] = useState(true);
  const [prizes, setPrizes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const getShopId = async () => {
    const shopId = await fetchProfile();
    setBizId(shopId.id);
  };
  const getMyPrizes = async (shopId) => {
    setIsLoading(true);
    const res = await fetchMyPrizes(shopId);
    if (res?.statusCode > 299) {
      // history.push("/main");
      return alert(
        "เกิดข้อผิดพลาด กรุณาติดต่อ Admin (Error" +
          res?.statusCode +
          " : " +
          res?.message +
          ")"
      );
    }
    setIsLoading(false);
    setPrizes(res?.data);
    setShowLoading(false);
  };

  useEffect(() => {
    if (bizId !== undefined) {
      getMyPrizes(bizId);
    }
  }, [bizId]);
  useEffect(() => {
    if (appContext) {
      // @ts-ignore
      setBizId(appContext?.profile.id);
    }
    getShopId();
  }, []);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar-store"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/LuckyDraw"
            />
          </IonButtons>
          <IonTitle>รายการของรางวัลของฉัน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              marginTop: 8,
              paddingLeft: 12,
              paddingRight: 12,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IonText style={{ fontWeight: 700, fontSize: 16 }}>
              เลือกรับของรางวัลที่คุณได้รับมาแล้ว
            </IonText>
            <button
              style={{ backgroundColor: "transparent", color: "gray" }}
              onClick={() => history.push("/myprize/history")}
            >
              ประวัติการแลกของรางวัล
            </button>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "fit",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <p style={{ margin: 0, color: "gray" }}>
              ยืนยันการรับสิทธิ์ภายใน 14 วัน นับจากวันที่ได้รับรางวัล
            </p>
            {/* @ts-ignore */}
            {prizes?.filter((e) => !e.isUsed).length > 0 ? (
              // @ts-ignore
              prizes
                // @ts-ignore
                .map((prize) => (
                  // @ts-ignore
                  <RewardCards
                    meta={prize}
                    itemId={prize._id}
                    data={prize.shopPrizeDetails}
                    isClaimed
                    type={"prizes"}
                  />
                ))
            ) : (
              <h1 className="ion-text-center" style={{ marginTop: 42 }}>
                {isLoading ? "กำลังโหลดข้อมูล" : "ไม่พบข้อมูล..."}
              </h1>
            )}
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPrize;
